import { useRef } from 'react';
import styled from 'styled-components';
import Modal from '../modal';
import ModalLayout from '../modal/ModalLayout';
import Button from '../../atoms/button';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  h3 {
    color: var(--primary);
    font-size: 15rem;
  }

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }
`;

const ConfirmDeleteModal = ({ openModal, setOpenModal, refreshData }) => {
  const ModalLayoutRef = useRef();

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>{t('Confirmar_eliminacion')}</h2>
              <p>{t('Confirmar_eliminacion_verificacion')}</p>
            </div>
            <div className="body">
              <form>
                <Button
                  text={t('Eliminar_cuenta')}
                  color="#fff"
                  backgroundColor="var(--primary)"
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default ConfirmDeleteModal;
