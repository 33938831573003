import { useRef, useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import Camp from '../../assets/images/camp.jpg';
import styled from 'styled-components';
import { returnTime } from '../../utils/formatDate';
import UserChallenges from './userChallenges';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import CreateChallengeModal from './createChallengeModal';

const Wrapper = styled.div`
  width: 100%;
  padding: 5px;

  .hide {
    display: none !important;
  }

  .event-box {
    border-radius: 5px;
    overflow: hidden;
  }

  .event-header {
    color: #293641;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 5fr 3fr 3fr;
    border-radius: 5px;

    .header-item {
      font-weight: 500;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        font-weight: 300;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  .event-info {
    background-color: var(--secondaryBackground);
    padding: 10px;

    p {
      font-weight: 500;
    }

    .challengesUsers {
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 50%;
        padding: 20px;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      a {
        text-decoration: underline;
        font-weight: 300;
        color: var(--secondaryText);
      }
    }
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 20px;
    }
  }
}
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--secondaryBackground);
  color: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    padding: 10px;
    background-color: #39cb6b;
  }
`;

const LiveChallengesItem = ({ eventData, refreshData }) => {
  const [openCreateChallengeModal, setOpenCreateChallengeModal] =
    useState(false);
  const ChallengerUsers = useRef();
  const date = new Date(eventData.start_datetime);
  const context = useContext(AppContext);

  const UnhideChallengers = () => {
    ChallengerUsers.current.classList.toggle('hide');
  };

  return (
    <WrapperMobile>
      <div
        className="challengeData"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(25, 25, 25, 0.52), rgba(25, 25, 25, 0.73)), url(${Camp})`,
          borderRadius: '5px',
          backgroundSize: 'cover',
          backgoundPosition: 'center',
          backgroundPositionY: 'center',
        }}
      >
        <div className="league">
          <Icon>
            <img src={eventData?.sport.image} alt="" />
          </Icon>
          <p style={{ fontWeight: '700' }}>{eventData?.league.name}</p>
          <Icon>
            <img src={eventData?.league.image} alt="" />
          </Icon>
        </div>
        <TeamsWrapper>
          <div className="team">
            <p>{eventData?.team1.name} </p>
            <Icon>
              <img src={eventData?.team1.image} alt="" />
            </Icon>
          </div>
          <p>vs</p>
          <div className="team">
            <Icon>
              <img src={eventData?.team2.image} alt="" />
            </Icon>
            <p>{eventData?.team2.name} </p>
          </div>
        </TeamsWrapper>
        <p>
          {returnTime(eventData.start_datetime, context.store.timezone, {
            dateStyle: 'medium',
          })}
          {' • '}
          <span style={{ color: '#aaa' }}>
            {returnTime(eventData.start_datetime, context.store.timezone, {
              timeStyle: 'short',
            })}
          </span>
        </p>
      </div>
    </WrapperMobile>
  );
};

export default LiveChallengesItem;
