import Main from '../layouts/main';

const Blog = () => {
  return (
    <Main>
      <div>Blog</div>
    </Main>
  );
};

export default Blog;
