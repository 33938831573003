import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Row = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 100px 1fr 150px;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  margin: 40px 0;
  padding: 10px 0;

  & > div {
    display: flex;
    place-items: center;
    justify-content: center;
  }

  .date {
    p {
      text-align: center;

      span {
        color: var(--secondaryText);
      }
    }
  }

  &:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    fit-content: cover;
  }
`;

const ResultsItems = styled.div`
  text-align: center;

  .anim {
    animation: beat 0.25s infinite alternate;
    transform-origin: center;
  }

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }

  @keyframes beat {
    to {
      transform: scale(0.7);
    }
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
`;

const WrapperMobile = styled.div`
  background-color: var(--primaryBackground);
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #bfbfbf;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const ChallengeItem = ({ challengeData, refreshData }) => {
  const { t } = useTranslation();
  const date = new Date(challengeData?.event.start_datetime);
  const myId = localStorage.getItem('user');
  const data = JSON.parse(myId);
  const id = data.data.id;

  return (
    <>
      <Row>
        <div>
          <Icon>
            <img src={challengeData?.event.sport.image} alt="" />
          </Icon>
        </div>
        <div>
          <ResultsItems>
            <p>{challengeData?.event.league.name} </p>
            <TeamsWrapper>
              <div className="team">
                <p>{challengeData?.event.team1.name} </p>
                <Icon>
                  <img
                    src={challengeData?.event.team1.image}
                    alt=""
                    /* className={
                    challengeData?.creator.id == id
                      ? challengeData.creator_team.id ===
                        challengeData?.event.team1.id
                        ? 'anim'
                        : null
                      : null
                  } */
                  />
                </Icon>
              </div>
              <p>vs</p>
              <div className="team">
                <Icon>
                  <img src={challengeData?.event.team2.image} alt="" />
                </Icon>
                <p>{challengeData?.event.team2.name} </p>
              </div>
            </TeamsWrapper>
          </ResultsItems>
        </div>
        <div className="date">
          <p>
            {date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
            <br />
            <span>{date.toLocaleString('en-US').split(',')[1]}</span>
          </p>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              textAlign: 'left',
            }}
          >
            <div>
              <Icon>
                <img
                  src={
                    challengeData?.creator.id === id
                      ? challengeData?.rival_team.image
                      : challengeData?.creator_team.image
                  }
                  alt=""
                />
              </Icon>
            </div>
            <div>
              {/* <p>
                Retador:{' '}
                <span style={{ color: 'var(--secondaryText)' }}>
                  {challengeData?.creator.id === id
                    ? challengeData?.rival.username
                    : challengeData?.creator.username}
                </span>{' '}
              </p> */}
              <p style={{ color: 'var(--accent)' }}>
                {challengeData?.challenge_cap} GCC
              </p>
            </div>
          </div>
        </div>
      </Row>
      <WrapperMobile>
        <div className="challengeData">
          <div className="league">
            <Icon>
              <img src={challengeData?.event.sport.image} alt="" />
            </Icon>
            <p>{challengeData?.event.league.name}</p>
            <Icon>
              <img src={challengeData?.event.league.image} alt="" />
            </Icon>
          </div>
          <TeamsWrapper>
            <div className="team">
              <p>{challengeData?.event.team1.name} </p>
              <Icon>
                <img src={challengeData?.event.team1.image} alt="" />
              </Icon>
            </div>
            <p>vs</p>
            <div className="team">
              <Icon>
                <img src={challengeData?.event.team2.image} alt="" />
              </Icon>
              <p>{challengeData?.event.team2.name} </p>
            </div>
          </TeamsWrapper>
          <p>
            {date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
            {' • '}
            <span style={{ color: 'var(--secondaryText)' }}>
              {date.toLocaleString('en-US').split(',')[1]}
            </span>
          </p>
          <div>
            <p style={{ color: 'var(--secondaryText)' }}>
              <span style={{ color: 'var(--primaryText)' }}>
                {challengeData?.creator.username}
              </span>{' '}
              {t('Apuesta')}{' '}
              <span style={{ color: 'var(--accent)' }}>
                {challengeData?.challenge_cap} GCC
              </span>{' '}
              {t('A_que_gana')} {challengeData?.creator_team.name}
              {/* Retador:{' '}
              <span style={{ color: 'var(--secondaryText)' }}>
                {challengeData?.creator.username}
              </span>{' '} */}
            </p>
            {/* <p style={{ color: 'var(--accent)' }}>
              {challengeData?.challenge_cap} GCC
            </p> */}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            // setOpenAcceptChallengeModal(true);
          }}
        >
          {t('Cancelar_reto')}
        </button>
      </WrapperMobile>
    </>
  );
};

export default ChallengeItem;
