import FootballBalon from '../../assets/images/football-balon.png';
import BaseballBall from '../../assets/images/baseball-ball.png';
import BasquetBalon from '../../assets/images/basquet-balon.png';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  img {
    margin: 5px;
    width: 15px;
    height: 15px;
    animation: ${rotate} 1s linear infinite;
  }
`;

const Loader = () => {
  return (
    <Wrapper>
      <img src={BaseballBall} alt="" />
      <img src={FootballBalon} alt="" />
      <img src={BasquetBalon} alt="" />
    </Wrapper>
  );
};

export default Loader;
