import styled from 'styled-components';
import Button from '../../components/atoms/button';
import AcceptChallengeModal from '../dashboard/acceptChallengeModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
padding: 10px;
    background-color: var(--primaryBackground);
    border-radius: 5px;
  font-size: 14rem;
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

  .bet {
    color: var(--accent);
    font-weight: 700;
  }

  .iconBox {
    display: flex;
    align-items: baseline;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const IconTeam = styled(Icon)`
  position: relative;
  left: -20px;
  width: 30px;
  height: 30px;
`;

const UserChallenges = ({ challengeData }) => {
  const { t } = useTranslation();
  const [openAcceptChallengeModal, setOpenAcceptChallengeModal] =
    useState(false);
  const myId = localStorage.getItem('user');
  const data = JSON.parse(myId);
  const id = data.data.id;

  return (
    <Wrapper>
      <div className="iconBox">
        <Icon>
          <img src={challengeData?.creator_team.image} alt="" />
        </Icon>
        <IconTeam>
          <img src={challengeData?.creator.avatar} alt="" />
        </IconTeam>
      </div>
      <div>{challengeData?.creator.username}</div>
      <div className="bet">{challengeData?.challenge_cap} GCC</div>
      <div>
        {challengeData.creator.id === id ? (
          <p style={{ fontWeight: '700' }}>Esperando retador</p>
        ) : (
          <Button
            text={t('Aceptar_el_reto_boton')}
            color="var(--secondaryBackground)"
            backgroundColor="var(--button)"
            onClick={(e) => {
              e.preventDefault();
              setOpenAcceptChallengeModal(true);
            }}
          />
        )}
      </div>
      {openAcceptChallengeModal && (
        <AcceptChallengeModal
          openModal={openAcceptChallengeModal}
          setOpenModal={setOpenAcceptChallengeModal}
          eventData={challengeData}
          // refreshData={refreshData}
        />
      )}
    </Wrapper>
  );
};

export default UserChallenges;
