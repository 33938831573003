import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ResultsItem from './resultsItem';
import axiosInstance from '../../../utils/axios';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  h3,
  p {
    color: #fff;
    font-weight: 300;
  }

  a,
  span {
    color: var(--secondaryText);
    font-size: 14rem;
  }
`;

const Results = () => {
  const { t } = useTranslation();

  const [eventsList, setEventsList] = useState([]);

  const getEventsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=finished&?page=1&per_page=4&ordering=-start_datetime`
      );
      setEventsList(data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getEventsList();
  }, []);

  return (
    <Wrapper>
      <h3>
        {t('Resultados')} <a href="/">{t('Ver_mas')}</a>
      </h3>
      {eventsList.map((event) => {
        return <ResultsItem eventData={event} key={event.id} />;
      })}
    </Wrapper>
  );
};

export default Results;
