import { useState } from 'react';
import styled from 'styled-components';
import ActionButton from '../../components/atoms/actionButtons';
import axiosInstance from '../../utils/axios';
import BlockIcon from '@mui/icons-material/Block';
import ShareIcon from '@mui/icons-material/Share';
import { toast } from 'react-toastify';
import DeclineChallengeModal from './declineChallengeModal';
import ShareModal from './shareModal';
import { useTranslation } from 'react-i18next';

const Row = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 100px 1fr 150px;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  margin: 40px 0;
  padding: 10px 0;

  & > div {
    display: flex;
    place-items: center;
    justify-content: center;
  }

  .date {
    p {
      text-align: center;

      span {
        color: var(--secondaryText);
      }
    }
  }

  &:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ResultsItems = styled.div`
  text-align: center;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
`;

const WrapperMobile = styled.div`
  background-color: var(--primaryBackground);
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #bfbfbf;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const ChallengeItem = ({ challengeData, refreshData }) => {
  const { t } = useTranslation();
  const date = new Date(challengeData?.event.start_datetime);
  const [openDeclineChallengeModal, setOpenDeclineChallengeModal] =
    useState(false);
  const [shareModal, setShareModal] = useState(false);

  return (
    <>
      <Row>
        <div>
          <Icon>
            <img src={challengeData?.event.sport.image} alt="" />
          </Icon>
        </div>
        <div>
          <ResultsItems>
            <p>{challengeData?.event.league.name} </p>
            <TeamsWrapper>
              <div className="team">
                <p>{challengeData?.event.team1.name} </p>
                <Icon>
                  <img src={challengeData?.event.team1.image} alt="" />
                </Icon>
              </div>
              <p>vs</p>
              <div className="team">
                <Icon>
                  <img src={challengeData?.event.team2.image} alt="" />
                </Icon>
                <p>{challengeData?.event.team2.name} </p>
              </div>
            </TeamsWrapper>
          </ResultsItems>
        </div>
        <div className="date">
          <p>
            {date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
            <br />
            <span>{date.toLocaleString('en-US').split(',')[1]}</span>
          </p>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              textAlign: 'left',
            }}
          >
            <div>
              <Icon>
                <img src={challengeData.creator_team.image} alt="" />
              </Icon>
            </div>
            <div>
              <p style={{ color: 'var(--accent)' }}>
                {challengeData?.challenge_cap} GCC
              </p>
            </div>
          </div>
        </div>
        <div>
          <ActionButtonWrapper>
            <ActionButton
              text={
                <ShareIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#07bc0c"
              onClick={(e) => {
                e.preventDefault();
                setShareModal(true);
              }}
            />
            <ActionButton
              text={
                <BlockIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="var(--warning)"
              onClick={(e) => {
                e.preventDefault();
                setOpenDeclineChallengeModal(true);
              }}
            />
          </ActionButtonWrapper>
        </div>
      </Row>
      <WrapperMobile>
        <div className="challengeData">
          <div className="league">
            <Icon>
              <img src={challengeData?.event.sport.image} alt="" />
            </Icon>
            <p>{challengeData?.event.league.name}</p>
            <Icon>
              <img src={challengeData?.event.league.image} alt="" />
            </Icon>
          </div>
          <TeamsWrapper>
            <div className="team">
              <p>{challengeData?.event.team1.name} </p>
              <Icon>
                <img src={challengeData?.event.team1.image} alt="" />
              </Icon>
            </div>
            <p>vs</p>
            <div className="team">
              <Icon>
                <img src={challengeData?.event.team2.image} alt="" />
              </Icon>
              <p>{challengeData?.event.team2.name} </p>
            </div>
          </TeamsWrapper>
          <p>
            {date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
            {' • '}
            <span style={{ color: 'var(--secondaryText)' }}>
              {date.toLocaleString('en-US').split(',')[1]}
            </span>
          </p>
          <div>
            <p style={{ color: 'var(--secondaryText)' }}>
              {t('Has_apostado')}{' '}
              <span style={{ color: 'var(--accent)' }}>
                {challengeData?.challenge_cap} GCC
              </span>{' '}
              {t('A_que_gana')} {challengeData?.creator_team.name}
              {/* Retador:{' '}
              <span style={{ color: 'var(--secondaryText)' }}>
                {challengeData?.creator.username}
              </span>{' '} */}
            </p>
            {/* <p style={{ color: 'var(--accent)' }}>
              {challengeData?.challenge_cap} GCC
            </p> */}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setOpenDeclineChallengeModal(true);
          }}
        >
          Cancelar reto
        </button>
      </WrapperMobile>
      {openDeclineChallengeModal && (
        <DeclineChallengeModal
          openModal={openDeclineChallengeModal}
          setOpenModal={setOpenDeclineChallengeModal}
          eventData={challengeData}
          refreshData={refreshData}
        />
      )}
      {shareModal && (
        <ShareModal
          openModal={shareModal}
          setOpenModal={setShareModal}
          eventData={challengeData}
        />
      )}
    </>
  );
};

export default ChallengeItem;
