/* Equeleto para mostrar mientras se cargan datos de retos */

import SkeletonLoading from './skeletonLoading';
import styled from 'styled-components';

const ResumeContainer = styled.div`
  background-color: #e3e3e3;
  filter: opacity(50%);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const ChallengeSkeleton = () => {
  return (
    <ResumeContainer>
      <SkeletonLoading width="40px" height="40px" borderRadius="50%" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SkeletonLoading width="40px" height="10px" />
        <SkeletonLoading width="40px" height="40px" borderRadius="50%" />
        <SkeletonLoading width="10px" height="10px" />
        <SkeletonLoading width="40px" height="40px" borderRadius="50%" />
        <SkeletonLoading width="40px" height="10px" />
      </div>
      <div>
        <SkeletonLoading width="80px" height="10px" />
        <SkeletonLoading width="60px" height="10px" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SkeletonLoading width="40px" height="40px" borderRadius="50%" />
        <div>
          <SkeletonLoading width="80px" height="10px" />
          <SkeletonLoading width="60px" height="10px" />
        </div>
      </div>
      <SkeletonLoading width="100px" height="26px" />
    </ResumeContainer>
  );
};

export default ChallengeSkeleton;
