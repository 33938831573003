import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import SportPreferencesComponent from '../../components/templates/account/sportPreferences';

const SportPreferences = (props) => {
  return (
    <Main>
      <AccountMenu>
        <SportPreferencesComponent />
      </AccountMenu>
    </Main>
  );
};

export default SportPreferences;
