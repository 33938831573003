import styled from 'styled-components';
import axiosInstance from '../../utils/axios';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Results from '../../components/templates/results';
import Main from '../../layouts/main';
import NewsItem from '../../components/templates/news';
import Camp from '../../assets/images/camp.jpg';
import { returnTime } from '../../utils/formatDate';
import { AppContext } from '../../contexts/AppContext';
import Button from '../../components/atoms/button';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 1fr 300px;
  height: 100%;
  .main {
    width: 100%;
    padding: 20px;

    a {
      font-size: 12rem;
      color: var(--secondaryText);
    }

    .challengesBox {
      background-color: var(--secondaryBackground);
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }

  .info {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 20px;
    }
  }
}
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--secondaryBackground);
  color: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    padding: 10px;
    background-color: #39cb6b;
  }
`;

const MyChallenges = () => {
  const { t } = useTranslation();
  const data = localStorage.getItem('user');
  const parseData = JSON.parse(data);
  const { challengeId } = useParams();
  const [challengeData, setChallengeData] = useState({
    id: '',
    creator: {
      username: '',
      avatar: '',
      first_name: '',
      last_name: '',
      id: '',
    },
    rival: '',
    event: {
      id: '',
      creator: '',
      modifier: '',
      sport: {
        id: '',
        name: '',
        image: '',
      },
      league: {
        id: '',
        name: '',
        image: '',
      },
      team1: {
        id: '',
        name: '',
        image: '',
      },
      team2: {
        id: '',
        name: '',
        image: '',
      },
      winner_team: '',
      created: '',
      modified: '',
      name: '',
      description: '',
      start_datetime: '',
      end_bet_datetime: '',
      status: '',
      score_team1: '',
      score_team2: '',
    },
    creator_team: {
      id: '',
      name: '',
      image: '',
    },
    rival_team: {
      id: '',
      name: '',
      image: '',
    },
    status: '',
    created: '',
    challenge_cap: '',
    winner: '',
  });
  const context = useContext(AppContext);

  const getChallengeData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/${challengeId}/`
      );
      setChallengeData(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const acceptChallenge = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}challenges/${challengeId}/accept_challenge/`
      );
      toast.success(t('Reto_aceptado'));
      getChallengeData();
    } catch (error) {
      toast.error(error.response.data.detail);
    }
  };

  useEffect(() => {
    getChallengeData();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <WrapperMobile>
            <div
              className="challengeData"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(25, 25, 25, 0.52), rgba(25, 25, 25, 0.73)), url(${Camp})`,
                borderRadius: '5px',
                backgroundSize: 'cover',
                backgoundPosition: 'center',
                backgroundPositionY: 'center',
              }}
            >
              <div className="league">
                <Icon>
                  <img src={challengeData?.event.sport.image} alt="" />
                </Icon>
                <p style={{ fontWeight: '700' }}>
                  {challengeData?.event.league.name}
                </p>
                <Icon>
                  <img src={challengeData?.event.league.image} alt="" />
                </Icon>
              </div>
              <TeamsWrapper>
                <div className="team">
                  <p>{challengeData?.event.team1.name} </p>
                  <Icon>
                    <img src={challengeData?.event.team1.image} alt="" />
                  </Icon>
                </div>
                <p>vs</p>
                <div className="team">
                  <Icon>
                    <img src={challengeData?.event.team2.image} alt="" />
                  </Icon>
                  <p>{challengeData?.event.team2.name} </p>
                </div>
              </TeamsWrapper>
              <p>
                {returnTime(
                  challengeData?.event.start_datetime,
                  context.store.timezone,
                  {
                    dateStyle: 'medium',
                  }
                )}
                {' • '}
                <span style={{ color: '#aaa' }}>
                  {returnTime(
                    challengeData?.event.start_datetime,
                    context.store.timezone,
                    {
                      timeStyle: 'short',
                    }
                  )}
                </span>
              </p>
              <p style={{ fontSize: '18rem' }}>
                {challengeData?.creator.username} {t('Te_reta_por')}{' '}
                <span style={{ color: 'var(--accent)', fontWeight: '700' }}>
                  {challengeData?.challenge_cap} GCC
                </span>{' '}
                {t('A_que_gana')} {challengeData?.creator_team.name}
              </p>
              {!challengeData.rival ? (
                <Button
                  text={t('Aceptar_el_reto_boton')}
                  color="#fff"
                  backgroundColor="#39CB6B"
                  onClick={acceptChallenge}
                />
              ) : (
                <p style={{ fontSize: '18rem', color: 'var(--warning)' }}>
                  {t('Reto_ya_aceptado')}
                </p>
              )}
            </div>
          </WrapperMobile>
        </div>
        <div className="info">
          <Results />
          {/* <NewsItem /> */}
        </div>
      </Wrapper>
    </Main>
  );
};

export default MyChallenges;
