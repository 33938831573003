import React from 'react';
import styled from 'styled-components';
import ProfileImage from '../../../assets/images/profile.jpg';
import axiosInstance from '../../../utils/axios';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 150px;
  justify-content: space-between;

  .textSecondary {
    color: var(--secondaryText);
  }

  .user_data {
    display: flex;
    align-items: center;

    .user_name {
      font-weight: 700;
    }

    & > div {
      margin: 20px;
    }

    .user_image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .user_actions {
    button {
      margin: 20px 0 20px 20px;
      font-weight: 700;
    }

    .challenge_user {
      color: var(--accent);
    }

    .block_user {
      color: var(--warning);
    }

    .accept_user {
      color: #07bc0c;
    }
  }
`;

const FriendsRequestListItem = ({ friendData, refreshData }) => {
  const { t } = useTranslation();
  const actionInvitation = async (action) => {
    /* Toma como argumento la accion que desea ejecutar el usuario: "accepted" o "rejected" */
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/friend_request_action/${friendData.id}/`,
        { status: action }
      );
      refreshData();
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  return (
    <Wrapper>
      <div className="user_data">
        <div className="user_image">
          <img src={ProfileImage} alt="" />
        </div>
        <div>
          <p className="user_name">
            {friendData?.challenger.first_name}{' '}
            {friendData?.challenger.last_name}
          </p>
          <p className="textSecondary">{friendData?.challenger.username}</p>
        </div>
      </div>
      <div className="user_actions">
        <button
          className="accept_user"
          onClick={() => actionInvitation('accepted')}
        >
          {t('Aceptar')}
        </button>
        <button
          className="block_user"
          onClick={() => actionInvitation('rejected')}
        >
          {t('Rechazar')}
        </button>
      </div>
    </Wrapper>
  );
};

export default FriendsRequestListItem;
