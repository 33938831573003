import { useRef, useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import styled from 'styled-components';
import UserChallenges from './userChallenges';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import CreateChallengeModal from './createChallengeModal';
import { returnTime } from '../../utils/formatDate';
import axiosInstance from '../../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  padding: 5px;

  .hide {
    display: none !important;
  }

  .event-box {
    border-radius: 5px;
    overflow: hidden;
  }

  .event-header {
    background-color: var(--secondaryBackground);
    color: var(--primaryText);
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 5fr 3fr 3fr;
    border-radius: 5px;

    .header-item {
      font-weight: 500;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        font-weight: 300;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  .event-info {
    background-color: var(--secondaryBackground);
    padding: 10px;

    p {
      font-weight: 500;
    }

    .challengesUsers {
      display: flex;
      flex-wrap: wrap;

      & > div {
        width: 50%;
        padding: 20px;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      a {
        text-decoration: underline;
        font-weight: 300;
        color: var(--secondaryText);
      }
    }
  }
`;

const ResultsItems = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const TeamsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 20px;
    }
  }
}

.justify-right {
  justify-content: right;
  text-align: right;
}
`;

const TeamsWrapperOp2 = styled(TeamsWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .teamOp2 {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--secondaryBackground);
  text-align: center;
  overflow: hidden;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    padding: 10px;
    background-color: #39cb6b;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const ActiveChallengesItem = ({ eventData, refreshData }) => {
  const { t } = useTranslation();
  const [openCreateChallengeModal, setOpenCreateChallengeModal] =
    useState(false);
  const ChallengerUsers = useRef();
  const date = new Date(eventData.start_datetime);
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const UnhideChallengers = () => {
    ChallengerUsers.current.classList.toggle('hide');
  };

  const getEventData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/${eventData.id}/`
      );
      console.log(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  return (
    <Wrapper>
      <div className="event-box">
        <div className="event-header">
          <div className="header-item">
            <Icon>
              <img src={eventData.sport.image} alt="" />
            </Icon>
          </div>
          <div className="header-item">
            <Icon>
              <img src={eventData.league.image} alt="" />
            </Icon>
            {eventData.league.name}
          </div>
          {/* <div className="header-item">
            <TeamsWrapper>
              <div className="team">
                <p>{eventData.team1.name}</p>
                <Icon>
                  <img src={eventData.team1.image} alt="" />
                </Icon>
              </div>
              <div>
                {eventData.status !== 'finished' ? (
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      filter: 'opacity(30%)',
                    }}
                  >
                    En espera de
                    <br />
                    resultados
                  </p>
                ) : (
                  <p style={{ whiteSpace: 'nowrap' }}>
                    {eventData.score_team1} - {eventData.score_team2}
                  </p>
                )}
              </div>
              <div className="team">
                <Icon>
                  <img src={eventData.team2.image} alt="" />
                </Icon>
                <p>{eventData.team2.name}</p>
              </div>
            </TeamsWrapper>
          </div> */}
          <div className="header-item" style={{ justifyContent: 'flex-start' }}>
            <TeamsWrapperOp2>
              <div className="teamOp2">
                <Icon>
                  <img src={eventData.team1.image} alt="" />
                </Icon>
                <p>{eventData.team1.name}</p>
              </div>
              <div className="teamOp2">
                <Icon>
                  <img src={eventData.team2.image} alt="" />
                </Icon>
                <p>{eventData.team2.name}</p>
              </div>
            </TeamsWrapperOp2>
            <TeamsWrapperOp2>
              {eventData.status !== 'finished' ||
              eventData.winner_team === '-' ? (
                <p style={{ filter: 'opacity(40%)', textAlign: 'left' }}>
                  {t('Esperando_resultados')}
                </p>
              ) : (
                <>
                  <div className="teamop2">
                    <p style={{ color: 'var(--accent)', fontWeight: '700' }}>
                      {eventData.score_team1}
                    </p>
                  </div>
                  <div className="teamOp2">
                    <p style={{ color: 'var(--accent)', fontWeight: '700' }}>
                      {eventData.score_team2}
                    </p>
                  </div>
                </>
              )}
            </TeamsWrapperOp2>
          </div>
          <div className="header-item">
            <p>
              {returnTime(eventData.start_datetime, context.store.timezone, {
                dateStyle: 'medium',
              })}
              <br />
              <span>
                {returnTime(eventData.start_datetime, context.store.timezone, {
                  timeStyle: 'short',
                })}
              </span>
            </p>
          </div>
          <div className="header-item">
            <ActionButton
              text={t('Ver')}
              color="#fff"
              backgroundColor="red"
              onClick={() => {
                navigate(`/eventos/${eventData.id}`);
              }}
            />
            <Button
              text={t('Ver_resultados')}
              color="#fff"
              backgroundColor="#bfbfbf"
              onClick={() => {
                navigate('eventos/3');
              }}
              disabled
            />
            {/* <ActionButton
              text={
                <ArrowDropDownIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              }
              color="#fff"
              backgroundColor="#bfbfbf"
              onClick={UnhideChallengers}
              disabled
            /> */}
          </div>
        </div>
        <WrapperMobile>
          <div className="challengeData">
            <div className="league">
              <Icon>
                <img src={eventData?.sport.image} alt="" />
              </Icon>
              <p>{eventData?.league.name}</p>
              <Icon>
                <img src={eventData?.league.image} alt="" />
              </Icon>
            </div>
            <ResultsItems>
              <TeamsWrapper>
                <div className="team justify-right">
                  <p>{eventData.team1.name}</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    margin: '0 10px',
                    alignItems: 'center',
                  }}
                >
                  <Icon>
                    <img src={eventData.team1.image} alt="" />
                  </Icon>

                  {eventData.status === 'finished' &&
                  eventData.winner_team !== '-' ? (
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                        color: 'var(--accent)',
                        fontWeight: '700',
                      }}
                    >
                      {eventData.score_team1} : {eventData.score_team2}
                    </p>
                  ) : (
                    <p
                      style={{
                        fontWeight: '700',
                        filter: 'opacity(30%)',
                      }}
                    >
                      Esperando
                      <br />
                      Resultados
                    </p>
                  )}
                  <Icon>
                    <img src={eventData.team2.image} alt="" />
                  </Icon>
                </div>
                <div className="team">
                  <p style={{ textAlign: 'left' }}>{eventData.team2.name}</p>
                </div>
              </TeamsWrapper>
            </ResultsItems>
            <p>
              {date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
              {' • '}
              <span style={{ color: 'var(--secondaryText)' }}>
                {date.toLocaleString('en-US').split(',')[1]}
              </span>
            </p>
          </div>
          <div
            className="buttonsBox"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              paddingBottom: '20px',
            }}
          >
            <ActionButton
              text={t('Ver')}
              color="#fff"
              backgroundColor="#bfbfbf"
              onClick={UnhideChallengers}
            />

            <Button
              text={t('Ver resultados')} /* {
                <ArrowDropDownIcon
                  style={{ width: '25px', height: '25px', color: '#fff' }}
                />
              } */
              color="#fff"
              backgroundColor="#bfbfbf"
              onClick={UnhideChallengers}
              disabled
            />
          </div>
        </WrapperMobile>
        {/* <div className="event-info">
          <div className="challengesUsers hide" ref={ChallengerUsers}>
            <div>
              <p>Retos creados por amigos para este evento:</p>
              <UserChallenges />
              <UserChallenges />
              <UserChallenges />
              <a href="-">Ver 4 mas</a>
            </div>
            <div>
              <p>Retos creados por otros usuarios para este evento:</p>
              <UserChallenges />
              <UserChallenges />
              <UserChallenges />
              <a href="-">Ver 132 mas</a>
            </div>
          </div>
          <p style={{ textAlign: 'right', fontWeight: '300' }}>
            7 de tus amigos han creado retos de este evento
          </p>
        </div> */}
      </div>
      {openCreateChallengeModal && (
        <CreateChallengeModal
          openModal={openCreateChallengeModal}
          setOpenModal={setOpenCreateChallengeModal}
          eventData={eventData}
        />
      )}
    </Wrapper>
  );
};

export default ActiveChallengesItem;
