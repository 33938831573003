import Modal from '../../components/templates/modal';
import styled from 'styled-components';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import FormField from '../../components/atoms/FormField';
import Button from '../../components/atoms/button';
import { useState, useRef, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import FriendsListItem from './FriendsListItem';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';

const ParentOfWrappers = styled.div`
  margin: 20px;
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  max-width: 800px;
  justify-content: center;

  .showList {
    @media (max-width: 800px) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }

  @media (max-width: 800px) {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }

  & > div {
    width: 50%;
    border-radius: 5px;

    @media (max-width: 800px) {
      width: 100vw;
      border-radius: 0;
    }
  }

  .hide {
    display: none;
  }

  .unhideMobile {
    @media (max-width: 800px) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
`;

const Wrapper = styled.div`
  text-align: center;
  background-color: var(--primaryBackground);

  .title {
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      margin: 0;
    }

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;

    .league {
      font-weight: 700;
    }
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  img {
    width: 100%;
  }
`;

const FriendsList = styled.div`
  height: 100%;
  display: grid;
  border-radius: 5px;
  grid-template-rows: 1fr;
  position: relative;

  .friendsList {
    overflow: auto;
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: grid;
    grid-template-rows: 1fr auto;

    & > button {
      @media (min-width: 800px) {
        display: none;
      }
    }

    .list {
      background-color: var(--secondaryBackground);
      border-radius: 5px;
      padding: 20px;
      overflow: auto;

      button {
        margin: 0;
      }
    }
  }
`;

const CreateChallengeModal = ({
  openModal,
  setOpenModal,
  refreshData,
  eventData,
}) => {
  const { t } = useTranslation();
  const frindsListRef = useRef();
  const [selectedFile, setSelectedFile] = useState('');
  const [friendsList, setFriendsList] = useState([]);
  const [newChallenge, setNewChallenge] = useState({
    challenge_cap: '',
    event: eventData.id,
    creator_team: '',
    is_public: true,
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();
  const changeToFriendListMobileRef = useRef();

  const ShowFriendsList = (e) => {
    e.preventDefault();
    frindsListRef.current.classList.toggle('hide');
    frindsListRef.current.classList.toggle('unhideMobile');
  };

  /* const ShowFriendsList = () => {
    frindsListRef.current.classList.toggle('unhideMobile');
  }; */

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewChallenge({
      ...newChallenge,
      [name]: value,
    });
  };

  const hadleCreateChallenge = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}challenges/`,
        newChallenge
      );
      setOpenModal(false);
      toast.success('Reto creado, Go Go Go!!!');
      // refreshData();
    } catch (error) {
      setMessage(error.response.data.error[0]);
    }
  };

  const getUsersList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/list/`
      );
      setFriendsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUsersList();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <ParentOfWrappers>
            <Wrapper>
              <div className="title">
                <div>
                  <h2>{t('Crea_reto')}</h2>
                  <p>{t('Crea_reto_descripcion')}</p>
                </div>
                <button
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CancelIcon
                    style={{
                      width: '30px',
                      height: '30px',
                      color: 'var(--warning)',
                    }}
                  />
                </button>
              </div>
              <div className="body">
                <p className="league">{eventData.league.name}</p>
                <TeamsWrapper>
                  <div className="team">
                    <p>{eventData.team1.name}</p>
                    <Icon>
                      <img src={eventData.team1.image} alt="" />
                    </Icon>
                  </div>
                  <p>vs</p>
                  <div className="team">
                    <Icon>
                      <img src={eventData.team2.image} alt="" />
                    </Icon>
                    <p>{eventData.team2.name}</p>
                  </div>
                </TeamsWrapper>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi,
                  leo, lacus mauris quam eu lacinia fringilla. Turpis dolor
                  consectetur pellentesque sed lectus sapien mattis.
                </p> */}
                <form>
                  <Dropdown>
                    <label htmlFor="creator_team">
                      {t('A_que_equipo_vas')}
                    </label>
                    <select
                      name="creator_team"
                      required
                      onChange={handleChange}
                    >
                      <option value="">{t('Selecciona_equipo')}</option>
                      <option value={eventData.team1.id}>
                        {eventData.team1.name}
                      </option>
                      <option value={eventData.team2.id}>
                        {eventData.team2.name}
                      </option>
                    </select>
                  </Dropdown>
                  <FormField
                    type="number"
                    name="challenge_cap"
                    labelText={t('Challenge_cap')}
                    onChange={handleChange}
                  />
                  {message !== '' ? (
                    <div
                      className="message"
                      style={{
                        color:
                          message === 'Email de recuperación enviado'
                            ? 'green'
                            : 'var(--warning)',
                        backgroundColor: '#f1f1f1',
                        borderRadius: '5px',
                        padding: '5px',
                        fontWeight: '500',
                      }}
                    >
                      {message}
                    </div>
                  ) : null}
                  <Button
                    text={t('Hacer_reto_publico')}
                    color="#fff"
                    backgroundColor="var(--primary)"
                    onClick={hadleCreateChallenge}
                  />
                  <p>{t('Hacer_reto_publico_descripcion')}</p>
                  <Button
                    text={t('Retar_amigos')}
                    color="#fff"
                    backgroundColor="var(--primary)"
                    onClick={ShowFriendsList}
                  />
                  <p>{t('Retar_amigos_descripcion')}</p>
                </form>
              </div>
            </Wrapper>
            <Wrapper className="hide" ref={frindsListRef}>
              <FriendsList>
                <div className="friendsList">
                  <div className="list">
                    {friendsList.map((friend) => {
                      return (
                        <FriendsListItem
                          newChallengeData={newChallenge}
                          friendData={friend}
                        />
                      );
                    })}
                  </div>
                  <Button
                    className="exitButton"
                    text={t('Listo')}
                    color="#fff"
                    backgroundColor="var(--primary)"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  />
                </div>
              </FriendsList>
            </Wrapper>
          </ParentOfWrappers>
        </ModalLayout>
      }
    />
  );
};

export default CreateChallengeModal;
