import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/atoms/button';
import styled from 'styled-components';
import GoChallengeLogo from '../assets/images/go_challenge_logo.png';
import FootballPlayer from '../assets/images/football-player2.png';
import RecoverPassModal from '../components/templates/account/RecoverPassModal';
import axios from 'axios';
import Loader from '../components/atoms/loader';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const WrapperLeft = styled.div`
  position: relative;
  padding: 20px;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: 100px 1fr auto;
`;

const WrapperLogo = styled.div`
  width: 200px;

  img {
    width: 100%;
  }
`;

const WrapperForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 300px;
    position: relative;
    top: -30px;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    margin: 10px 0;
    color: #98a0a7;
  }

  .form_buttons {
    text-align: center;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    font-weight: 700;
  }

  input {
    height: 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding-left: 10px;
  }

  input:focus {
    outline: 2px solid #98a0a7;
  }
`;

const WrapperConditions = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const ShowPass = styled.div`
  text-align: right;
  margin-bottom: 10px;
  label {
    color: var(--secondaryText);
  }
`;

const PlayerWrapper = styled.div`
  display: none;
  height: 100vh;
  filter: opacity(0.2);
  position: absolute;
  right: -100px;
  overflow: hidden;
  z-index: -1;

  img {
    height: 100%;
  }

  @media (min-width: 800px) {
    display: block;
  }
`;

const Login = () => {
  const { t } = useTranslation();
  var ln = navigator.language || navigator.userLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [signInData, setSignInData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignInData({
      ...signInData,
      [name]: value,
    });
  };

  const togglePassword = (e) => {
    e.target.checked ? setPasswordShown(true) : setPasswordShown(false);
  };

  // const auth = useAuth();

  const navigate = useNavigate();

  const submitLogin = async (e) => {
    e.preventDefault();
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);
    setIsLoading(true);

    try {
      let authresult = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/login/`,
        signInData
      );
      localStorage.setItem('user', JSON.stringify(authresult));
      localStorage.setItem(
        'GMT',
        `Etc/GMT${formatted < 0 ? '+' : null}${-formatted}`
      );
      setIsLoading(false);
      i18next.changeLanguage(ln, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
        t('key');
        localStorage.setItem('userLanguage', ln);
      });
      navigate('/');
    } catch (err) {
      setErrorMessage(
        err.response.data.message
          ? err.response.data.message
          : 'Ha ocurrido algo, intenta de nuevo'
      );
      setIsLoading(false);
    }
  };

  return (
    <WrapperLeft>
      <div style={{ position: 'absolute', right: '20px', top: '20px' }}>
        <button
          onClick={() => {
            i18next.changeLanguage('en', (err, t) => {
              if (err) return console.log('something went wrong loading', err);
              t('key');
              localStorage.setItem('userLanguage', 'en');
            });
          }}
          style={{
            color:
              localStorage.getItem('userLanguage') === 'en'
                ? '#293641'
                : '#d8e2e9',
          }}
        >
          EN
        </button>
        {' | '}
        <button
          onClick={() => {
            i18next.changeLanguage('es', (err, t) => {
              if (err) return console.log('something went wrong loading', err);
              t('key');
              localStorage.setItem('userLanguage', 'es');
            });
          }}
          style={{
            color:
              localStorage.getItem('userLanguage') === 'es'
                ? '#293641'
                : '#d8e2e9',
          }}
        >
          ES
        </button>
      </div>
      <PlayerWrapper>
        <img src={FootballPlayer} alt="" />
      </PlayerWrapper>
      <div>
        <WrapperLogo>
          <img src={GoChallengeLogo} alt="Logo de Go Challenge" />
        </WrapperLogo>
      </div>
      <WrapperForm>
        <div>
          <h2>{t('Ingreso')}</h2>
          <form className="form" onSubmit={submitLogin}>
            <FormField>
              <label htmlFor="email">{t('Correo')} *</label>
              <input
                type="text"
                name="email"
                value={signInData.email}
                placeholder={t('Escribe_tu_correo')}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </FormField>
            <FormField>
              <label htmlFor="password">{t('Contrasena')} *</label>
              <input
                type={passwordShown ? 'text' : 'password'}
                name="password"
                value={signInData.password}
                placeholder={t('Escribe_contrasena')}
                autoComplete="off"
                onChange={handleChange}
                required
              />
            </FormField>
            <ShowPass>
              <input type="checkbox" name="show" onClick={togglePassword} />
              <label htmlFor="show">{t('Mostrar_contrasena')}</label>
            </ShowPass>
            {errorMessage ? (
              <p
                style={{
                  backgroundColor: 'var(--warning)',
                  borderRadius: '5px',
                  color: '#fff',
                  fontWeight: '700',
                }}
              >
                {errorMessage}
              </p>
            ) : null}
            <div className="form_buttons">
              <Button
                backgroundColor={isLoading ? '#bfbfbf' : '#394a58'}
                color="#fff"
                text={t('Entrar')}
                disabled={isLoading}
              />
              {isLoading && <Loader />}
            </div>
          </form>
          <div>
            <p>
              {t('Aun_no_tienes_cuenta')}{' '}
              <a href="/registro">{t('Registrate_aqui')}</a>
            </p>
            <p>
              {t('Olvidaste_tu_contrasena')}{' '}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}
                href="/"
              >
                {t('Recuperar_contrasena')}
              </a>
            </p>
          </div>
          {/* <p>O ingresa con...</p>
          <WrapperSocialRegistry>
            <Button>Facebook</Button>
            <Button>Google</Button>
          </WrapperSocialRegistry> */}
        </div>
      </WrapperForm>
      <WrapperConditions>
        <p>{t('Copyright')}</p>
      </WrapperConditions>
      {openModal && (
        <RecoverPassModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          userId={1}
        />
      )}
    </WrapperLeft>
  );
};

export default Login;
