import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Modal from '../../../components/templates/modal';
import axiosInstance from '../../../utils/axios';
import ModalLayout from '../../../components/templates/modal/ModalLayout';
import Button from '../../../components/atoms/button';
import FormField from '../../atoms/FormField';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  text-align: center;
  width: 90%;
  max-width: 400px;
  background-color: var(--primaryBackground);
  border-radius: 5px;
  overflow: hidden;

  .title {
    background-color: #fff;
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 40px;
    max-height: 500px;
    overflow-y: scroll;
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
`;

const ListItem = styled.div`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--secondaryBackground);
  }
`;

const AddSportPreferencesModal = ({
  openModal,
  setOpenModal,
  refreshData,
  sportsList,
  updatePreferences,
}) => {
  const { t } = useTranslation();
  const [sportFilter, setSportFilter] = useState([]);
  const ModalLayoutRef = useRef();

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <h2>{t('Agregar_deporte')}</h2>
              <p>{t('Agregar_deporte_descripcion')}</p>
            </div>
            <div className="body">
              <div>
                <h3>{t('Lista_deportes')}</h3>
                <FormField
                  type="search"
                  name="name"
                  labelText={t('Buscar_deporte')}
                  value={sportFilter}
                  onChange={(e) => {
                    setSportFilter(e.target.value);
                  }}
                />
                {/* Lista de deportes de la plataforma */}
                {sportsList
                  .filter((item) => {
                    return item.name.toLowerCase().includes(sportFilter);
                  })
                  .sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((sport) => {
                    return (
                      <ListItem>
                        {sport.name}{' '}
                        <button
                          style={{
                            backgroundColor: 'var(--primary)',
                            color: 'var(--primaryBackground)',
                            padding: '5px',
                            borderRadius: '5px',
                          }}
                          onClick={() => updatePreferences(sport.id, null)}
                        >
                          {t('Agregar_deporte')}
                        </button>
                      </ListItem>
                    );
                  })}
              </div>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AddSportPreferencesModal;
