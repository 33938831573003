import { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../../components/atoms/button';
import AcceptChallengeModal from '../myChallenges/acceptChallengeModal';
import DeclineChallengeModal from '../myChallenges/declineChallengeModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    fit-content: cover;
  }
`;

const ResultsItems = styled.div`
  text-align: center;
  margin-top: 10px;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

  }
}
`;

const ChallengeReceived = ({ item, refreshData }) => {
  const { t } = useTranslation();
  const [openAcceptChallengeModal, setOpenAcceptChallengeModal] =
    useState(false);
  const [openDeclineChallengeModal, setOpenDeclineChallengeModal] =
    useState(false);

  return (
    <div
      style={{
        backgroundColor: 'var(--primaryBackground)',
        margin: '10px 0',
        padding: '20px',
        borderRadius: '5px',
      }}
    >
      <p
        style={{
          fontWeight: '500',
          color: 'var(--secondaryText)',
        }}
      >
        <span
          style={{
            color: 'var(--primaryText)',
          }}
        >
          {item.creator.username}
        </span>{' '}
        {t('Te_ha_retado_por')}{' '}
        <span style={{ color: 'var(--accent)', fontWeight: '700' }}>
          {item.challenge_cap} GCC
        </span>{' '}
        {t('Que_gana')} {item.creator_team.name}
      </p>
      <ResultsItems>
        <p>{item.event.league.name}</p>
        <TeamsWrapper>
          <div className="team">
            <p>{item.event.team1.name}</p>
            <Icon>
              <img src={item.event.team1.image} alt="" />
            </Icon>
          </div>
          <p>VS</p>
          <div className="team">
            <Icon>
              <img src={item.event.team2.image} alt="" />
            </Icon>
            <p>{item.event.team2.name}</p>
          </div>
        </TeamsWrapper>
      </ResultsItems>
      <div
        className="buttonsBox"
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <ActionButton
          text={t('Rechazar')}
          color="var(--primaryText)"
          border="solid 1px var(--warning)"
          onClick={(e) => {
            e.preventDefault();
            setOpenDeclineChallengeModal(true);
          }}
        />
        <ActionButton
          text={t('Aceptar')}
          color="var(--primaryText)"
          border="solid 1px #39cb6b"
          onClick={(e) => {
            e.preventDefault();
            setOpenAcceptChallengeModal(true);
          }}
        />
      </div>
      {openAcceptChallengeModal && (
        <AcceptChallengeModal
          openModal={openAcceptChallengeModal}
          setOpenModal={setOpenAcceptChallengeModal}
          eventData={item}
          refreshData={refreshData}
        />
      )}
      {openDeclineChallengeModal && (
        <DeclineChallengeModal
          openModal={openDeclineChallengeModal}
          setOpenModal={setOpenDeclineChallengeModal}
          eventData={item}
          refreshData={refreshData}
        />
      )}
    </div>
  );
};

ChallengeReceived.propTypess = {
  /**
   * Color de fondo para el boton
   */
  item: PropTypes.object,
  /**
   * Recibe una funcion para refrescar la lista de retos recibidos
   */
  refreshData: PropTypes.func,
};

ChallengeReceived.defaultProps = {
  item: null,
  refreshData: null,
};

export default ChallengeReceived;
