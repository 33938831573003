/* Funcion para dar formato a las fechas y horas y llevarlas al huso horario seleccionado por el cliente */

export const returnTime = (date, timezone, options) => {
  /* Argumentos que recibe date: string de la fecha recibida, timezone: huso horario seleccionado por el cliente ("ejemplo: Etc/GMT+8"), options: opciones para el formato de la fecha (opcional) (ejemplo: { timeStyle: 'full' | 'long' | 'medium' | 'short' }) */

  const d = new Date(date);

  return d.toLocaleString('es-MX', {
    ...options,
    //timeZone: myTimeZone,
    timeZone: timezone || localStorage.getItem('GMT'),
    hour12: false,
  });
};
