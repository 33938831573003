import { useRef, useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import styled from 'styled-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FriendsListItem from './FriendsListItem';
import FriendsRequestListItem from './FriendsRequestListItem';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  flex-wrap: wrap;

  .textSecondary {
    color: var(--secondaryText);

    span {
      color: var(--accent);
      font-weight: 700;
    }
  }
`;

const WrapperFriends = styled.div``;

const WrapperInvite = styled.div`
  & > div {
    margin-bottom: 40px;
  }

  .social_buttons {
    button {
      margin-right: 20px;
    }
  }

  .invite_code {
    & > div {
      display: flex;
      gap: 10px;
    }
    p {
      font-size: 30rem;
    }
  }
`;

const Friends = () => {
  const { t } = useTranslation();
  const [friendsList, setFriendsList] = useState([]);
  const [blockedFriendsList, setBlockedFriendsList] = useState([]);
  const [friendsInvitationList, setFriendsInvitationList] = useState([]);
  const inviteCode = useRef('');
  const [userData, setUserData] = useState({});

  /* Funcion para copiar el codigo de referido */
  const copyInviteCode = () => {
    var copyText = inviteCode.current.innerText;
    navigator.clipboard.writeText(copyText);
    alert('Se copio tu código de invitación: ' + copyText);
  };

  const getFriendsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/friends/`
      );
      setFriendsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getBlockedFriendsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/blockeds/`
      );
      setBlockedFriendsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getFriendsInvitation = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/friend_requests/`
      );
      setFriendsInvitationList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setUserData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const refreshData = () => {
    getFriendsList();
    getBlockedFriendsList();
    getFriendsInvitation();
    getUserData();
  };

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Wrapper>
      <WrapperFriends>
        {friendsInvitationList.length === 0 ? null : (
          <>
            <div>
              <h2>{t('Invitaciones_amigos')}</h2>
              <p className="textSecondary">
                {t('Invitaciones_amigos_descripcion')}
              </p>
            </div>
            <div>
              <div>
                {friendsInvitationList.map((friend) => {
                  return (
                    <FriendsRequestListItem
                      friendData={friend}
                      key={friend.user.id}
                      refreshData={refreshData}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div>
          <h2>{t('Amigos')}</h2>
          <p className="textSecondary">{t('Amigos_descripcion')}</p>
        </div>
        <div>
          <div>
            {friendsList.map((friend) => {
              return (
                <FriendsListItem
                  friendData={friend}
                  key={friend.user.id}
                  refreshData={refreshData}
                />
              );
            })}
          </div>
        </div>
        {/* <div>
          <h3>Amigos bloqueados</h3>
        </div>
        <div>
          <div>
            {blockedFriendsList.length === 0 ? (
              <p>No hay amigos bloqueados</p>
            ) : (
              blockedFriendsList.map((friend) => {
                return (
                  <FriendsListItem friendData={friend} key={friend.user.id} />
                );
              })
            )}
          </div>
        </div> */}
      </WrapperFriends>
      <WrapperInvite>
        <div>
          <h2>{t('Invitar_amigos')}</h2>
          <p className="textSecondary">{t('Invitar_amigos_descripcion')}</p>
          <div className="social_buttons">
            <button>
              <FacebookIcon
                style={{ width: '30px', height: '30px', color: '#3C86F4' }}
              />
            </button>
            <button>
              <WhatsAppIcon
                style={{ width: '30px', height: '30px', color: '#48C857' }}
              />
            </button>
            <button>
              <EmailIcon
                style={{ width: '30px', height: '30px', color: '#394A58' }}
              />
            </button>
          </div>
        </div>
        <div>
          <h2>{t('Codigo_invitacion')}</h2>
          <p className="textSecondary">{t('Codigo_invitacion_descripcion')} </p>
          <div className="invite_code">
            <div>
              <p className="textSecondary" ref={inviteCode}>
                {userData.invitation_code}
              </p>
              <button onClick={copyInviteCode}>
                <ContentCopyIcon
                  style={{ width: '25px', height: '25px', color: '#394A58' }}
                />
              </button>
            </div>
          </div>
          <p className="textSecondary">
            {t('Se_han_registrado')} <span>{userData.invitation_used}</span>{' '}
            {t('Usuarios_con_tu_codigo_de_invitacion')}
          </p>
        </div>
      </WrapperInvite>
    </Wrapper>
  );
};

export default Friends;
