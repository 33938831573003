import Modal from '../../components/templates/modal';
import styled from 'styled-components';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useState, useRef } from 'react';
import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 600px;
  background-color: var(--primaryBackground);

  .title {
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      margin: 0;
    }

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;

    .league {
      font-weight: 700;
    }
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .bet {
    font-weight: 500;
    line-height: 26rem;

    span {
      color: var(--accent);
      font-weight: 700;
    }
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const AcceptChallengeModal = ({
  openModal,
  setOpenModal,
  refreshData,
  eventData,
}) => {
  const { t } = useTranslation();
  const frindsListRef = useRef();
  const [selectedFile, setSelectedFile] = useState('');
  const [newChallenge, setNewChallenge] = useState({
    challenge_cap: '',
    event: eventData.id,
    creator_team: '',
    is_public: true,
  });
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewChallenge({
      ...newChallenge,
      [name]: value,
    });
  };

  const acceptChallenge = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}challenges/${eventData.id}/accept_challenge/`
      );
      toast.success(t('Reto_aceptado'));
      refreshData();
    } catch (error) {
      toast.success(t('Error_al_aceptar_reto'));
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <div>
                <h2>
                  {t('Aceptas_reto_de')} {eventData.creator.username}
                </h2>
              </div>
            </div>
            <div className="body">
              <p className="league">{eventData.event.league.name}</p>
              <TeamsWrapper>
                <div className="team">
                  <p>{eventData.event.team1.name}</p>
                  <Icon>
                    <img src={eventData.event.team1.image} alt="" />
                  </Icon>
                </div>
                <p>vs</p>
                <div className="team">
                  <Icon>
                    <img src={eventData.event.team2.image} alt="" />
                  </Icon>
                  <p>{eventData.event.team2.name}</p>
                </div>
              </TeamsWrapper>
              <div className="bet">
                <p>
                  {t('Por_mayuscula')}{' '}
                  <span>{eventData.challenge_cap} GCC</span>
                </p>
                <p>
                  {t('Aceptar_reto_favor')} {eventData.rival_team.name}
                </p>
              </div>
              {message !== '' ? (
                <div
                  className="message"
                  style={{
                    color:
                      message === 'Email de recuperación enviado'
                        ? 'green'
                        : 'var(--warning)',
                    backgroundColor: '#f1f1f1',
                    borderRadius: '5px',
                    padding: '5px',
                    fontWeight: '500',
                  }}
                >
                  {message}
                </div>
              ) : null}
              <Button
                text={t('Aceptar_el_reto_boton')}
                color="#fff"
                backgroundColor="#39CB6B"
                onClick={acceptChallenge}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AcceptChallengeModal;
