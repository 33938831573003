import styled from 'styled-components';

const ResultsItems = styled.div`
  text-align: center;
  margin-top: 20px;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  overflow: hidden;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  

  &>div{
    width: 40%;
  }

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: left;

    &>div {
      flex: 1 0 20px;
    }
  }
}

.justify-right {
  justify-content: right;
  text-align: right;
}
`;

const ResultsItem = ({ eventData }) => {
  return (
    <ResultsItems>
      <p>{eventData.league.name}</p>
      <TeamsWrapper>
        <div className="team justify-right">
          <p>{eventData.team1.name}</p>
        </div>
        <div style={{ display: 'flex' }}>
          <Icon>
            <img src={eventData.team1.image} alt="" />
          </Icon>
          <p
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {eventData.score_team1} : {eventData.score_team2}
          </p>
          <Icon>
            <img src={eventData.team2.image} alt="" />
          </Icon>
        </div>
        <div className="team">
          <p style={{ textAlign: 'left' }}>{eventData.team2.name}</p>
        </div>
      </TeamsWrapper>
    </ResultsItems>
  );
};

export default ResultsItem;
