import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import Results from '../../components/templates/results';
import UsersRanking from '../../components/templates/usersRanking';
import Main from '../../layouts/main';
import LiveChallengesItem from '../challenges/liveChallengesItem';
import Carousel from 'react-material-ui-carousel';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChallengeReceived from './challengeReceived';
import ChallengeItem from './challengeItem';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
// import ChallengeSkeleton from '../../components/templates/challengeSkeleton';
// import NewsItem from '../../components/templates/news';
import SelectAllIcon from '@mui/icons-material/SelectAll';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  height: 100%;

  .main {
    width: 100%;
    padding: 20px;
  }

  .info {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }

    h3 {
      color: #fff;
      font-weight: 300;
    }

    button {
      margin-top: 20px;
    }
  }

  .info_sports {
    @media (max-width: 1380px) {
      display: none;
    }
  }

  a {
    font-size: 12rem;
    color: var(--secondaryText);
  }

  .challengesBox {
    background-color: var(--secondaryBackground);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  @media (max-width: 1380px) {
    grid-template-columns: 300px 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Table = styled.div`
  width: 100%;
  border-collapse: collapse;

  tr {
    position: relative;
  }

  tr:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    font-weight: 700;
    width: 20%;
    padding: 20px;
  }

  span {
    font-weight: 300;
  }

  .message-no-challenges {
    margin-top: 10px;

    a {
      color: var(--accent);
      font-size: 16rem;
    }
  }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const IconLeagues = styled(Icon)`
  margin: 15px;
  position: relative;

  &:hover {
    background-color: var(--primaryBackground);
  }
`;

const ResultsItems = styled.div`
  text-align: center;
  margin-top: 10px;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 30px;
    }
  }
}
`;

const FilterLeagues = styled.div`
  display: flex;
  background-color: var(--secondaryBackground);\
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  overflow-x: auto;

  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }

  .iconsBox {
    display: flex;
  }
`;

const FilterSport = styled(FilterLeagues)`
  width: 100%;

  @media (max-width: 1100px) {
    display: flex;
    width: calc(100vw - 40px);
  }
`;

export const Dashboard = () => {
  const { t } = useTranslation();
  const [challengesList, setChallengesList] = useState([]);
  const [sportsList, setSportsList] = useState([]);
  const [sportFilter, setSportFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [myChallengesList, setMyChallengesList] = useState([]);
  const [liveEventsList, setLiveEventsList] = useState([]);
  const myId = localStorage.getItem('user');
  const data = JSON.parse(myId);
  const id = data?.data.id;

  const getSportsList = async (e) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response?.status);
    }
  };

  const getChallengesList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/`
      );
      setChallengesList(data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  const getMyChallengesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/user_challenge_history/`
      );
      setMyChallengesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getLiveEventsList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=live&?page=1&per_page=6`
      );
      setLiveEventsList(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  useEffect(() => {
    getChallengesList();
    getMyChallengesList();
    getLiveEventsList();
    getSportsList();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="info info_challenges">
          <h3>
            {t('Mira_quien_reto')} <a href="/mis-retos">{t('Ver_mas')}</a>
          </h3>
          {myChallengesList
            .filter((item) => {
              return item.creator.id !== id;
            })
            .filter((item) => {
              return item.status === 'pending';
            }).length === 0 ? (
            <p style={{ color: 'var(--secondaryText)', padding: '10px 0' }}>
              {t('No_has_recibido_reto')}
            </p>
          ) : (
            myChallengesList
              .filter((item) => {
                return item.creator.id !== id;
              })
              .filter((item) => {
                return item.status === 'pending';
              })
              .slice(0, 3)
              .map((item) => {
                return (
                  <ChallengeReceived
                    key={item.id}
                    item={item}
                    refreshData={getMyChallengesList}
                  />
                );
              })
          )}
          <h3>
            {t('Tus_retos_activos')} <a href="/mis-retos">{t('Ver_mas')}</a>
          </h3>
          {myChallengesList
            .filter((item) => {
              return item.creator.id !== id;
            })
            .filter((item) => {
              return item.status === 'active';
            }).length === 0 ? (
            <p style={{ color: 'var(--secondaryText)', padding: '10px 0' }}>
              {t('Sin_retos_activos')}
            </p>
          ) : (
            myChallengesList
              .filter((item) => {
                return item.creator.id !== id;
              })
              .filter((item) => {
                return item.status === 'active';
              })
              .map((item) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: 'var(--primaryBackground)',
                      margin: '10px 0',
                      padding: '20px',
                      borderRadius: '5px',
                    }}
                  >
                    <p
                      style={{
                        fontWeight: '500',
                        color: 'var(--secondaryText)',
                      }}
                    >
                      {t('Reto_activo_con')}{' '}
                      <span
                        style={{
                          color: 'var(--primaryText)',
                        }}
                      >
                        {item.creator.username}
                      </span>{' '}
                      {t('Por')}{' '}
                      <span
                        style={{ color: 'var(--accent)', fontWeight: '700' }}
                      >
                        {item.challenge_cap} GCC
                      </span>{' '}
                    </p>
                    <ResultsItems>
                      <p>{item.event.league.name}</p>
                      <TeamsWrapper>
                        <div className="team">
                          <p>{item.creator_team.name}</p>
                          <Icon>
                            <img src={item.creator_team.image} alt="" />
                          </Icon>
                        </div>
                        <p>VS</p>
                        <div className="team">
                          <Icon>
                            <img src={item.rival_team.image} alt="" />
                          </Icon>
                          <p>{item.rival_team.name}</p>
                        </div>
                      </TeamsWrapper>
                    </ResultsItems>
                  </div>
                );
              })
          )}
        </div>
        <div className="main">
          {/* <FilterSport>
              <div className="iconsBox">
                <IconLeagues
                  onClick={(e) => {
                    setSportFilter('');
                  }}
                >
                  <SelectAllIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'var(--primaryText)',
                    }}
                  />
                </IconLeagues>
                {sportsList
                  .sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((sport) => {
                    return (
                      <IconLeagues
                        key={sport.id}
                        onClick={() => setSportFilter(`${sport.name}`)}
                      >
                        <img src={sport.image} alt={sport.name} />
                        <span
                          style={{
                            backgroundColor:
                              sportFilter === sport.name
                                ? 'var(--primaryText)'
                                : null,
                            width: '30px',
                            borderRadius: '0 0 5px 5px',
                            height: '4px',
                            padding: '2px',
                            position: 'absolute',
                            bottom: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                          }}
                        ></span>
                      </IconLeagues>
                    );
                  })}
              </div>
            </FilterSport> */}
          {liveEventsList.length !== 0 ? (
            <>
              <h3>{t('Eventos_en_vivo_titulo')}</h3>
              <div className="liveEventBox" style={{ marginBottom: '20px' }}>
                <Carousel
                  NextIcon={
                    <ArrowRightIcon
                      style={{ width: '25px', height: '25px', color: '#fff' }}
                    />
                  }
                  PrevIcon={
                    <ArrowLeftIcon
                      style={{ width: '25px', height: '25px', color: '#fff' }}
                    />
                  }
                >
                  {liveEventsList.map((item, i) => (
                    <LiveChallengesItem eventData={item} key={item.id} />
                  ))}
                </Carousel>
              </div>
            </>
          ) : null}
          <div className="challengesBox">
            <h3>
              {t('Retos_activos_plataforma')}{' '}
              <span>
                <a href="/">{t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Retos_activos_plataforma_descripcion')}</p>
            <Table>
              {/* {loading && (
              <div>
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
              </div>
            )} */}
              {challengesList
                ?.filter((item) => {
                  return item.status === 'pending';
                })
                .filter((item) => {
                  return !item.rival;
                })
                .filter((item) => {
                  return item.creator.id !== id;
                }).length === 0 ? (
                <p className="message-no-challenges">
                  {t('No_hay_retos_activos')}{' '}
                  <a href="/eventos">{t('Crea_uno')}</a>
                </p>
              ) : (
                challengesList
                  ?.filter((item) => {
                    return item.status === 'pending';
                  })
                  .filter((item) => {
                    return !item.rival;
                  })
                  .filter((item) => {
                    return item.creator.id !== id;
                  })
                  ?.filter((event) => {
                    return event.event.sport.name.includes(sportFilter);
                  })
                  .map((item) => {
                    return (
                      <ChallengeItem
                        challengeData={item}
                        refreshData={getChallengesList}
                        key={item.id}
                      />
                    );
                  })
              )}
            </Table>
          </div>
        </div>
        <div className="info info_sports">
          <Results />
          <UsersRanking />
          {/* <NewsItem /> */}
        </div>
      </Wrapper>
    </Main>
  );
};

export default Dashboard;
