import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonComponent = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  &:hover {
    filter: opacity(50%);
  }
`;

const ActionButton = (props) => {
  return (
    <ButtonComponent
      style={{ backgroundColor: props.backgroundColor, color: props.color }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.text}
    </ButtonComponent>
  );
};

ActionButton.propTypes = {
  /**
   * Color de fondo para el boton
   */
  backgroundColor: PropTypes.string,
  /**
   * Define si el boton es clickeable o no
   */
  disabled: PropTypes.bool,
  /**
   * Funcion que ejecutara el boton
   */
  onClick: PropTypes.func,
  /**
   * El texto del boton?
   */
  text: PropTypes.string.isRequired,
  /**
   * Recibe los parametros del boton
   */
  props: PropTypes.object,
};

ActionButton.defaultProps = {
  backgroundColor: null,
  disabled: false,
  onClick: undefined,
  text: null,
};

export default ActionButton;
