import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../components/atoms/button';
import GoChallengeLogo from '../assets/images/go_challenge_logo.png';
import KicksSoccer from '../assets/images/kicks-soccer.jpg';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  height: 100vh;

  & > div {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      width: 400px;

      img {
        width: 100%;
      }
    }

    button {
      margin-top: 20px;
    }
  }
`;

const ActivateAccount = () => {
  const { t } = useTranslation();
  const [activation, setActivation] = useState(t('Estamos_activando_cuenta'));
  const { first } = useParams(); /* Primera parte del token en la URL */
  const { second } = useParams(); /* Segunda parte del token en la URL */
  const navigate = useNavigate();

  const HandleActivation = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BASE_URL}auth/activation/${first}/${second}/`
      );
      setActivation(t('Ha_sido_activada'));
      setTimeout(() => {
        navigate('/mis-retos');
      }, 3000);
    } catch (err) {
      setActivation(t('Problema_al_activar'));
    }
  };

  useEffect(() => {
    HandleActivation();
  }, []);

  return (
    <Wrapper
      style={{
        background: ` linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(245, 246, 252, 0.80)),url(${KicksSoccer})`,
        backgroundSize: 'cover',
      }}
    >
      <div>
        <div className="logo">
          <img src={GoChallengeLogo} alt="" />
        </div>
        <h2>{activation}</h2>
        <Button
          text={t('Volver_a_plataforma')}
          color="#fff"
          backgroundColor="#324250"
          onClick={() => navigate('/mis-retos')}
        />
      </div>
    </Wrapper>
  );
};

export default ActivateAccount;
