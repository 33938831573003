import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FormField from '../components/atoms/FormField';
import GoChallengeLogo from '../assets/images/go_challenge_logo.png';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const WrapperLeft = styled.div`
  padding: 20px;
  height: 100vh;
  display: grid;
  overflow: auto;
  grid-template-rows: 100px 1fr auto;
`;

const WrapperLogo = styled.div`
  width: 200px;

  img {
    width: 100%;
  }
`;

const WrapperForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 300px;
    position: relative;
    margin: 40px 0;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    margin: 10px 0;
    color: #98a0a7;
  }

  .form_buttons {
    text-align: center;
  }
`;

const Button = styled.button`
  height: 30px;
  padding: 0 20px;
  border-radius: 5px;
  background-color: #394a58;
  color: #fff;
`;

const WrapperConditions = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const PasswordRequirements = styled.div`
  background-color: var(--secondaryBackground);
  padding: 20px;
  border-radius: 5px;

  p {
    margin-top: 0;
    color: var(--primaryText);
    font-weight: 700;
  }

  li {
    list-style: disc;
    margin-left: 20px;
  }
`;

const ShowPass = styled.div`
  text-align: right;
  margin-bottom: 10px;
  label {
    color: var(--secondaryText);
  }
`;

const RecoverPassword = () => {
  const { t } = useTranslation();
  const { first } =
    useParams(); /* Primera parte del token extraido de la URL */
  const { second } =
    useParams(); /* Segunda parte del token extraido de la URL */
  const [passwordShown, setPasswordShown] = useState(false);
  const [recoverData, setRecoverData] = useState({
    passwd1: '',
    passwd2: '',
  });
  const passwordValidator =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.+_-])[A-Za-z\d@.+_-]{8,20}$/gm;

  const passIsValid = passwordValidator.test(recoverData.password);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRecoverData({
      ...recoverData,
      [name]: value,
    });
  };

  const togglePassword = (e) => {
    e.target.checked ? setPasswordShown(true) : setPasswordShown(false);
  };

  const navigate = useNavigate();

  const submitLogin = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/reset_password/${first}/${second}/`,
        recoverData
      );
      navigate('/login');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <WrapperLeft>
      <div>
        <WrapperLogo>
          <img src={GoChallengeLogo} alt="Logo de Go Challenge" />
        </WrapperLogo>
      </div>
      <WrapperForm>
        <div>
          <h2>{t('Recuperar_contrasena')}</h2>
          <form className="form" onSubmit={submitLogin}>
            <FormField
              type={passwordShown ? 'text' : 'password'}
              name="passwd1"
              value={recoverData.passwd1}
              placeholder={t('Escribe_contrasena')}
              labelText={t('Nueva_contrasena')}
              onChange={handleChange}
            />
            {passIsValid || recoverData.passwd1.length < 1 ? null : (
              <PasswordRequirements>
                {/* A medida que se vayan cumpliendo los requisitos de la contraseña ira cambiando a verde el requisito, se verifica con expresiones regulares la contraseña que se este escribiendo */}
                <p>La contraseña debe tener al menos:</p>
                <ul>
                  <li
                    style={{
                      color: recoverData.passwd1.length >= 8 ? 'green' : 'red',
                    }}
                  >
                    8 caracteres
                  </li>
                  <li
                    style={{
                      color: recoverData.passwd1.match(/[A-Z]/g)
                        ? 'green'
                        : 'red',
                    }}
                  >
                    una letra mayuscula
                  </li>
                  <li
                    style={{
                      color: recoverData.passwd1.match(/[a-z]/g)
                        ? 'green'
                        : 'red',
                    }}
                  >
                    una letra minuscula
                  </li>
                  <li
                    style={{
                      color: recoverData.passwd1.match(/[\d]/g)
                        ? 'green'
                        : 'red',
                    }}
                  >
                    un numero
                  </li>
                  <li
                    style={{
                      color: recoverData.passwd1.match(/[@.+_\-s]/g)
                        ? 'green'
                        : 'red',
                    }}
                  >
                    un caracter especial (@.+_-)
                  </li>
                </ul>
              </PasswordRequirements>
            )}
            <FormField
              type={passwordShown ? 'text' : 'password'}
              name="passwd2"
              value={recoverData.passwd2}
              placeholder={t('Confirmar_contrasena')}
              labelText={t('Confirmar_contrasena')}
              onChange={handleChange}
            />
            <ShowPass>
              <input type="checkbox" name="show" onClick={togglePassword} />
              <label htmlFor="show">{t('Mostrar_contrasena')}</label>
            </ShowPass>
            <p>
              {t('Recordaste_contrasena')}{' '}
              <a href="/login">{t('Entra_aqui')}</a>
            </p>
            <div className="form_buttons">
              <Button>{t('Recuperar_contrasena')}</Button>
            </div>
          </form>
        </div>
      </WrapperForm>
      <WrapperConditions>
        <p>{t('Copyright')}</p>
      </WrapperConditions>
    </WrapperLeft>
  );
};

export default RecoverPassword;
