import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import ProfileComponent from '../../components/templates/account/profile';

const Profile = (props) => {
  return (
    <Main>
      <AccountMenu>
        <ProfileComponent />
      </AccountMenu>
    </Main>
  );
};

export default Profile;
