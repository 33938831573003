import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import CreditBalanceComponent from '../../components/templates/account/creditBalance';

const CreditBalance = () => {
  return (
    <Main>
      <AccountMenu>
        <CreditBalanceComponent />
      </AccountMenu>
    </Main>
  );
};

export default CreditBalance;
