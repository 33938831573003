import { useState, useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import Button from '../../components/atoms/button';
import axiosInstance from '../../utils/axios';
import AcceptChallengeModal from './acceptChallengeModal';
import { toast } from 'react-toastify';
import { returnTime } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 100px 1fr 150px;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-content: center;
  margin: 40px 0;
  flex-wrap: wrap;

  .date {
    p {
      text-align: center;

      span {
        color: var(--secondaryText);
      }
    }
  }

  & > div {
    display: flex;
    place-items: center;
    justify-content: center;
    gap: 10px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--primaryBackground);
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #39cb6b;
  }

  @media (min-width: 800px) {
    display: none;
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const breatheAnimation = keyframes`
 0% { transform: scale(1) }
 30% { transform: scale(1.1) }
 40% { transform: scale(1.3) }
 100% { transform: scale(1) }
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ResultsItems = styled.div`
  text-align: center;

  p {
    font-size: 14rem;
  }

  & > p {
    color: var(--secondaryText);
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;

  .anim {
    animation-name: ${breatheAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  }

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 30px;
    }
  }
}
`;

const ChallengeItem = ({ challengeData, refreshData }) => {
  const { t } = useTranslation();
  const [openAcceptChallengeModal, setOpenAcceptChallengeModal] =
    useState(false);
  const date = new Date(challengeData?.event.start_datetime);
  const myId = localStorage.getItem('user');
  const data = JSON.parse(myId);
  const id = data.data.id;
  const context = useContext(AppContext);

  const acceptChallenge = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}challenges/${challengeData.id}/accept_challenge/`
      );
      toast.success(t('Reto_aceptado'));
      refreshData();
    } catch (error) {
      toast.error(t('Error_al_aceptar_reto'));
      console.log(error.response.data.detail.name);
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/client/${challengeData.creator.id}/`
      );
      console.log(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  /* const returnTime = (date) => {
    const myTimeZone = localStorage.getItem('GMT');

    const d = new Date(date);

    return d.toLocaleString('en-US', {
      timeZone: myTimeZone,
      hour12: false,
    });
  }; */

  // console.log(context.store.preferences.some((item) => item.team === number));

  return (
    <>
      <Wrapper>
        <div>
          <Icon>
            <img src={challengeData?.event.sport.image} alt="" />
          </Icon>
        </div>
        <div>
          <ResultsItems>
            <p>{challengeData?.event.league.name} </p>
            <TeamsWrapper>
              <div className="team">
                <p>{challengeData?.event.team1.name} </p>
                <Icon
                  className={`${
                    context.store.preferences.some(
                      (item) => item.team === challengeData?.event.team1.id
                    )
                      ? 'anim'
                      : null
                  }`}
                >
                  <img src={challengeData?.event.team1.image} alt="" />
                </Icon>
              </div>
              <p>vs</p>
              <div className="team">
                <Icon
                  className={`${
                    context.store.preferences.some(
                      (item) => item.team === challengeData?.event.team2.id
                    )
                      ? 'anim'
                      : null
                  }`}
                >
                  <img src={challengeData?.event.team2.image} alt="" />
                </Icon>
                <p>{challengeData?.event.team2.name} </p>
              </div>
            </TeamsWrapper>
          </ResultsItems>
        </div>
        <div className="date">
          <p>
            {returnTime(
              challengeData?.event.start_datetime,
              context.store.timezone,
              {
                dateStyle: 'medium',
              }
            )}
            <br />
            <span>
              {returnTime(
                challengeData?.event.start_datetime,
                context.store.timezone,
                {
                  timeStyle: 'short',
                }
              )}
            </span>
          </p>
        </div>
        <div>
          <div>
            <Icon>
              <img src={challengeData?.creator_team.image} alt="" />
            </Icon>
          </div>
          <div>
            <p>
              {t('Retador')}:{' '}
              <span style={{ color: 'var(--secondaryText)' }}>
                <a onClick={getUserData}>{challengeData?.creator.username}</a>
              </span>{' '}
            </p>
            <p style={{ color: 'var(--accent)' }}>
              {challengeData?.challenge_cap} GCC
            </p>
          </div>
        </div>
        <div>
          <ActionButtonWrapper>
            <Button
              text={
                challengeData?.rival?.id !== id ? t('Aceptar') : t('Aceptado')
              }
              color="#fff"
              backgroundColor={
                challengeData?.rival?.id !== id ? '#39CB6B' : '#bfbfbf'
              }
              /* onClick={acceptChallenge} */
              onClick={(e) => {
                e.preventDefault();
                setOpenAcceptChallengeModal(true);
              }}
              disabled={challengeData?.rival?.id !== id ? false : true}
            />
          </ActionButtonWrapper>
        </div>
        {openAcceptChallengeModal && (
          <AcceptChallengeModal
            openModal={openAcceptChallengeModal}
            setOpenModal={setOpenAcceptChallengeModal}
            eventData={challengeData}
            refreshData={refreshData}
          />
        )}
      </Wrapper>
      <WrapperMobile>
        <div className="challengeData">
          <div className="league">
            <Icon>
              <img src={challengeData?.event.sport.image} alt="" />
            </Icon>
            <p>{challengeData?.event.league.name}</p>
            <Icon>
              <img src={challengeData?.event.league.image} alt="" />
            </Icon>
          </div>
          <TeamsWrapper>
            <div className="team">
              <p>{challengeData?.event.team1.name} </p>
              <Icon>
                <img src={challengeData?.event.team1.image} alt="" />
              </Icon>
            </div>
            <p>vs</p>
            <div className="team">
              <Icon>
                <img src={challengeData?.event.team2.image} alt="" />
              </Icon>
              <p>{challengeData?.event.team2.name} </p>
            </div>
          </TeamsWrapper>
          <p>
            {date.toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
            {' • '}
            <span style={{ color: 'var(--secondaryText)' }}>
              {date.toLocaleString('en-US').split(',')[1]}
            </span>
          </p>
          <div>
            <p style={{ color: 'var(--secondaryText)' }}>
              <span style={{ color: 'var(--primaryText)' }}>
                {challengeData?.creator.username}
              </span>{' '}
              {t('Apuesta')}{' '}
              <span style={{ color: 'var(--accent)' }}>
                {challengeData?.challenge_cap} GCC
              </span>{' '}
              {t('A_que_gana')} {challengeData?.creator_team.name}
              {/* Retador:{' '}
              <span style={{ color: 'var(--secondaryText)' }}>
                {challengeData?.creator.username}
              </span>{' '} */}
            </p>
            {/* <p style={{ color: 'var(--accent)' }}>
              {challengeData?.challenge_cap} GCC
            </p> */}
          </div>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setOpenAcceptChallengeModal(true);
          }}
        >
          {t('Aceptar_el_reto_boton')}
        </button>
      </WrapperMobile>
    </>
  );
};

export default ChallengeItem;
