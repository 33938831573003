const translationES = {
  // Common translations
  //
  Correo: 'Correo',
  Escribe_tu_correo: 'Escribe tu correo electronico',
  Contrasena: 'Contraseña',
  Escribe_contrasena: 'Escribe tu contraseña',
  Mostrar_contrasena: 'Mostrar contraseña',
  Copyright: 'Copyright © 2023 | GoChallenge | Todos los Derechos Reservados',
  Entra_aqui: 'Entra aquí',
  Depositar_gcc: 'Depositar GCC',
  Confirmar_contrasena: 'Repite tu contraseña',
  Ver_mas: 'Ver mas',
  Por: 'por',
  Por_mayuscula: 'Por',
  Crea_uno: 'Crea uno',
  Crea_reto: 'Crear un reto',
  Aceptar_reto: 'Aceptar uno',
  Cancelar_reto: 'Cancelar reto',
  Has_apostado: 'Has apostado',
  Reto_aceptado: 'Reto aceptado',
  Error_al_aceptar_reto: 'Hubo un error aceptando el reto',
  Aceptado: 'Aceptado',
  Te_reta_por: 'te reta por',
  Reto_ya_aceptado: 'Este reto ya ha sido aceptado',
  Buscar_deporte: 'Buscar deporte',
  Buscar_equipo: 'Buscar equipo',
  Buscar_evento: 'Buscar evento',
  //
  // Login Page (src/pages/signin)
  //
  Ingreso: 'Ingreso',
  Entrar: 'Entrar',
  Aun_no_tienes_cuenta: 'Aun no tienes cuenta?',
  Registrate_aqui: 'Regístrate aquí',
  Olvidaste_tu_contrasena: 'Olvidaste tu contraseña',
  Recuperar_contrasena: 'Recuperar contraseña',
  //
  // Register Page (src/pages/signup)
  //
  Registro: 'Registro',
  Registro_normal: 'Registro normal',
  Registro_rapido: 'Registro rapido',
  Codigo_invitado: 'Codigo de invitado',
  Codigo_invitado_placeholder:
    'Si tienes un codigo de un amigo, escribelo aqui',
  Apodo: 'Apodo',
  Apodo_placeholder: 'Escribe con que nombre quieres que te vean',
  Atencion: 'Atención',
  Atencion_texto:
    'Se creara un usuario y contraseña aleatorio y se enviara a tu correo.',
  Ya_tienes_cuenta: 'Ya tienes cuenta?',
  Registrarme: 'Registrarme',
  Mensaje_de_registro: 'Al registrarte confirmas que has leído y aceptado los',
  Terminos_y_condiciones: 'Terminos y condiciones',
  Y_la: 'y la',
  Politica_de_privacidad: 'Politica de privacidad',
  De_la_compania: 'de la compañia',
  Bienvenido_GoChallenge: 'Bienvenido a GoChallenge',
  Bienvenida_mensaje:
    'Un espacio para conocer y retar apasionados por los deportes como tu',
  Pasos_texto: 'Sigue estos pasos para comenzar a retar',
  Paso_1: 'Recarga tu billetera de GoChallenge Coins',
  Paso_2:
    'Selecciona uno de los eventos deportivos de la plataforma para crear un reto',
  Paso_2: 'Comparte el reto a tus amigos y usuarios de la plataforma',
  //
  // Recover password modal (src/components/templates/account/RecoverPassModal)
  //
  Olvidaste_la_contrasena_question: 'Olvidaste la contraseña?',
  Olvidaste_la_contrasena_texto:
    'Introduce la dirección de correo electrónico que usas en GoChallenge. Te enviaremos un enlace para restablecer tu contraseña.',
  Enviar_correo: 'Enviar correo de recuperacion',
  Email_enviado: 'Email de recuperación enviado',
  //
  // Navbar (src/components/templates/navbar)
  //
  Dashboard: 'Dashboard',
  Eventos: 'Eventos',
  Mis_retos: 'Mis retos',
  A_jugar: 'A Jugar',
  Saldo: 'Saldo',
  Tu_zona_horaria: 'Tu zona horaria',
  Selecciona_zona_horaria: 'Selecciona tu zona horaria',
  Perfil: 'Perfil',
  Preferencias_deportivas: 'Preferencias deportivas',
  Amigos: 'Amigos',
  Privacidad: 'Privacidad',
  Eliminar_cuenta: 'Eliminar cuenta',
  Cerrar_sesion: 'Cerrar sesión',
  //
  // Activate account page (src/pages/ActivateAccount)
  //
  Ha_sido_activada: 'Tu cuenta ha sido activada, a jugar!!!',
  Estamos_activando_cuenta: 'Estamos activando tu cuenta',
  Problema_al_activar: 'Hubo un problema con tu activacion',
  Volver_a_plataforma: 'Volver a la plataforma',
  //
  // Recover Password page (src/pages/RecoveryPassword)
  //
  Recuperar_contrasena: 'Restablecer contraseña',
  Nueva_contrasena: 'Nueva contraseña',
  Recordaste_contrasena: 'Ya recordaste tu contraseña?',
  //
  // Dashboard (src/pages/dashboard)
  //
  Mira_quien_reto: 'Mira quien te ha retado',
  No_has_recibido_reto: 'No has recibido ningun reto',
  Tus_retos_activos: 'Tus retos activos',
  Sin_retos_activos: 'No tienes ningun reto activo',
  Reto_activo_con: 'Tienes un reto activo con',
  Eventos_en_vivo_titulo: 'Eventos en vivo',
  Retos_activos_plataforma: 'Retos activos de la plataforma',
  Retos_activos_plataforma_descripcion:
    'Estos son todos los retos disponibles en la plataforma.',
  No_hay_retos_activos: 'No hay retos activos',
  No_hay_mas_retos_activos: 'No hay mas retos activos',
  //
  // Retos recibidos component (src/pages/dashboard/challengeReceived)
  //
  Te_ha_retado_por: 'te ha retado por',
  Que_gana: 'que gana',
  //
  // Results component (src/components/templates/results)
  //
  Resultados: 'Resultados',
  //
  // User Rankings component (src/components/templates/usersRanking)
  //
  Usuarios_mas_referidos: 'Usuarios con mas referidos',
  //
  // Eventos page (src/pages/challenges/Challenges)
  //
  Todos: 'Todos',
  Con_resultados: 'Con resultados',
  Eventos_vivo: 'Eventos en vivo',
  Eventos_activos: 'Eventos activos',
  No_eventos_activos: 'No hay eventos activos',
  Eventos_finalizados: 'Eventos finalizados',
  No_eventos_finalizados: 'No hay eventos finalizados',
  //
  //Create challenge modal (src/pages/challenges/createChallengeModal)
  //
  Crea_reto_descripcion:
    'Reta a tus amigos o deja un reto público en la plataforma',
  A_que_equipo_vas: 'A que equipo le vas?',
  Selecciona_equipo: 'Selecciona el equipo',
  Challenge_cap: 'Cual sera el monto del reto en GCC?',
  Hacer_reto_publico: 'Hacer reto publico',
  Hacer_reto_publico_descripcion:
    'Haz un reto publico para que cualquier persona de la comunidad pueda aceptarlo',
  Retar_amigos: 'Retar a amigos >>',
  Retar_amigos_descripcion:
    'Reta a uno o varias de las personas en tu lista de amigos, el reto se hara con la primera que lo acepte',
  Listo: 'Listo',
  //
  // Retos activos item (src/pages/challenges/activeChallenges)
  //
  Retos_creados_amigos: 'Retos creados por amigos para este evento:',
  Sin_retos_evento: 'No hay retos activos para este evento',
  Todos_retos: 'Todos los retos creados para este evento',
  Hay: 'Hay',
  Reto: 'challenge',
  Retos: 'challenges',
  Para_este_evento: 'for this event',
  //
  // Retos finalizados item (src/pages/challenges/finishedChallengesItem)
  //
  Esperando_resultados: 'Esperando resultados',
  Ver: 'Ver',
  Ver_resultados: 'Ver resultados',
  No_hay_retos_finalizados: 'No hay retos finalizados',
  //
  // Lista de amigos (src/pages/challenges/FriendListItem)
  //
  Reto_enviado_a: 'Reto enviado a',
  Solicitud_amistad: 'Se envio la solicitud de amistad a',
  Verifica_datos_reto:
    'Verfica que has seleccionado tu equipo y el monto del reto',
  Retar: 'Retar',
  //
  // News component (src/components/templates/news)
  //
  Noticias: 'Noticias',
  //
  // My challenges page (src/pages/myChallenges)
  //
  Mis_retos_activos: 'Mis retos activos',
  Mis_retos_activos_descripcion:
    'Estos son todos los retos que ya tienen rival.',
  Retos_recibidos: 'Retos recibidos',
  Retos_recibidos_descripcion:
    'Estos son todos los eventos en los que te han retado, aceptalos!!',
  No_has_recibido_retos: 'No has recibido ningun reto',
  Retos_creados: 'Retos creados',
  Retos_creados_descripcion:
    'Estos son los retos que has creado o enviado a amigos y estan en espera de ser aceptados.',
  Retos_cancelados: 'Retos cancelados',
  Retos_cancelados_descripcion:
    'Estos son los retos que has decidido cancelar.',
  No_hay_Retos_cancelados: 'No hay retos cancelados.',
  //
  // Active challenge item (src/pages/myChallenges/challengeItem)
  //
  Retador: 'Retador',
  Toast_no_puedes_cancelar: 'No puedes cancelar un reto activo',
  Apuesta: 'apuesta',
  A_que_Gana: 'a que gana',
  //
  // Accept challenge modal (src/pages/myChallenges/acceptChallengeModal)
  //
  Aceptas_reto_de: 'Aceptas el reto de',
  Aceptar_reto_favor: 'Aceptaras el reto a favor de',
  Aceptar_el_reto_boton: 'Acepta el reto',
  //
  // Accept challenge modal (src/pages/myChallenges/declineChallengeModal)
  //
  Rechazas_reto_de: 'Rechazas el reto de',
  Estas_a_favor: 'Estas a favor de',
  Rechazar_reto_boton: 'Rechazar el reto',
  //
  // Share modal (src/pages/myChallenges/shareModal)
  //
  Compartir_reto: 'Compartir reto',
  Copiar_enlace_reto: 'Copiar enlace del reto',
  //
  // Profile page (src/components/templates/account/profile)
  //
  Editar_perfil: 'Editar perfil',
  Editar_perfil_descripcion: 'Actualiza tu foto e información general aquí',
  Documento_identidad: 'Documento de identidad',
  Reenviar: 'Reenviar',
  Agregar: 'Agregar',
  Tu_correo: 'Tu Correo',
  Email_verificado: 'Email verificado',
  Email_no_verificado_enviar_verificar:
    'Email no verificado, enviar verificacion',
  Fotografia: 'Fotografía',
  Nombre: 'Nombre',
  Nombre_placeholder: 'Escribe tu nombre',
  Apellido: 'Apellido',
  Apellido_placeholder: 'Escribe tu apellido',
  Username: 'Apodo',
  Username_aviso: 'Al cambiarlo debes esperar un año para volver a cambiarlo',
  Username_placeholder:
    'Escribe el nombre como quieres que te vean los demás usuarios',
  Fecha_nacimiento: 'Fecha de nacimiento',
  Fecha_nacimiento_placeholder: 'dd / mm / aaaa',
  Descripcion_personal: 'Descripcion personal',
  Descripcion_personal_placeholder: 'Escribe una breve descripcion personal',
  Telefono: 'Telefono',
  Telefono_placeholder: 'Escribe tu numero telefónico',
  Guardar_cambios: 'Guardar cambios',
  //
  // Preferencais deportivas page (src/components/templates/account/sportPreferences)
  //
  Preferencias_deportivas: 'Preferencias deportivas',
  Preferencias_deportivas_descripcion:
    'Selecciona tus deportes y equipos favoritos aquí',
  Deportes_favoritos: 'Deportes favoritos',
  No_tienes_deportes: 'No tienes deportes favoritos',
  Agregar_deporte: 'Agregar deporte',
  Agregar_deporte_descripcion: 'Agrega un nuevo deporte para la plataforma',
  Lista_deportes: 'Lista de deportes de la plataforma',
  Equipos_favoritos: 'Equipos favoritos',
  No_tienes_equipos: 'No tienes equipos favoritos',
  Agregar_equipo: 'Agregar equipos',
  Agregar_equipo_descripcion: 'Agrega un nuevo equipo para la plataforma',
  Lista_equipos: 'Lista de equipos de la plataforma',
  //
  // Friends page (src/components/templates/account/friends)
  //
  Invitaciones_amigos: 'Invitaciones de amigos',
  Invitaciones_amigos_descripcion: 'Peticiones de amistad recibidas',
  Amigos: 'Amigos',
  Amigos_descripcion: 'Ve el listado de amigos aquí',
  Invitar_amigos: 'Invitar amigos',
  Invitar_amigos_descripcion: 'Invita a tus amigos a que se unan y retales',
  Codigo_invitacion: 'Codigo de invitación',
  Codigo_invitacion_descripcion:
    'Comparte este código de invitación con tus amigos y gana GCC',
  Se_han_registrado: 'Se han registrado',
  Usuarios_con_tu_codigo_de_invitacion: 'usuarios con tu codigo de invitacion.',
  Solicitud_enviada: 'Solicitud enviada',
  Eliminar: 'Eliminar',
  //
  // Friendrequest item (src/components/templates/account/friendsRequestListItem)
  //
  Aceptar: 'Aceptar',
  Rechazar: 'Rechazar',
  //
  // Delete account page (src/components/templates/account/deleteAccount)
  //
  Eliminar_cuenta_descripcion:
    'Tu cuenta quedara desactivada por unos días y luego pasara a ser eliminada, ten en cuenta que:',
  En_cuenta_1: 'No debes tener saldo disponible de GCC.',
  En_cuenta_2: 'No debes tener ningún reto activo.',
  Escribe_apodo: 'Escribe tu apodo para confirmar la eliminación',
  Confirmar_eliminacion: 'Confirmar eliminación',
  Confirmar_eliminacion_verificacion: 'Realmente desea eliminar su cuenta?',
  //
  // Privacy page (src/components/templates/account/privacy)
  //
  Privacidad: 'Privacidad',
  Privacidad_descripcion:
    'Selecciona quien te puede buscar y retar en la plataforma',
  Amigos_amigos: 'Amigos de amigos',
  Cualquiera: 'Cualquiera',
  Privacidad_1: 'Quien puede enviarte solicitudes de amistad?',
  Privacidad_2: 'Quien puede enviarte solicitudes de retos?',
  Privacidad_3: 'Quien puede enviarte mensajes directos?',
  Como_te_encuentran: 'Como deseas que te puedan encontrar en las búsquedas?',
  Como_te_encuentran_descripcion:
    'Los otros usuarios pueden buscarte escribiendo tu...',
  //
  // Eventos Activos (src/pages/challenges/activeChallenges)
  //
  Proximos: 'Proximos',
  Dias: 'dias',
  //
  // Share modal
  //
  Se_copio_enlace: 'Se copio el enlace del reto',
};

export default translationES;
