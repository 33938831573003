import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../../utils/axios';
import AddSportPreferencesModal from './AddSportPreferencesModal';
import AddTeamPreferencesModal from './AddTeamPreferencesModal';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const PreferencesListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  .logo {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      fit-object: cover;
    }
  }
`;

const SportPreferences = () => {
  const { t } = useTranslation();
  const [openSportModal, setOpenSportModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [sportsList, setSportsList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [userSportPreferences, setUserSportPreferences] = useState([]);

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setUserSportPreferences(data.preferences);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getSportsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getTeamsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}teams/?status=1/`
      );
      setTeamsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const updatePreferences = async (sportId, teamId) => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/preferences/ `,
        {
          preferences: [
            ...userSportPreferences,
            teamId ? { sport: sportId, team: teamId } : { sport: sportId },
          ],
        }
      );
      setOpenSportModal(false);
      setOpenTeamModal(false);
      getUserData();
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const deletePreference = async (sportId, teamId) => {
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/preferences/`,
        {
          preferences: [
            ...userSportPreferences.filter((item) => item.id !== sportId),
          ],
        }
      );
      getUserData();
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getSportsList();
    getTeamsList();
    getUserData();
  }, []);

  return (
    <Wrapper>
      <div>
        <h2>{t('Preferencias_deportivas')}</h2>
        <p className="textSecondary">
          {t('Preferencias_deportivas_descripcion')}
        </p>
      </div>
      <div>
        <h3>{t('Deportes_favoritos')}</h3>
        <PreferencesListWrapper>
          {userSportPreferences?.filter((item) => !item.team).length === 0 ? (
            <p>{t('No_tienes_deportes')}</p>
          ) : (
            /* Lista de los deportes preferidos del usuario */
            userSportPreferences
              .filter((item) => !item.team)
              .map((item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      backgroundColor: 'var(--primary)',
                      color: 'var(--primaryBackground)',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                    key={item.id}
                  >
                    <div className="logo">
                      <img src={item.sport.image} alt="" />
                    </div>
                    <p>{item.sport.name}</p>
                    <button onClick={() => deletePreference(item.id)}>X</button>
                  </div>
                );
              })
          )}
          <div
            style={{
              display: 'flex',
              gap: '10px',
              backgroundColor: 'var(--primary)',
              color: 'var(--primaryBackground)',
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenSportModal(true);
              }}
            >
              {t('Agregar_deporte')}
            </button>
          </div>
        </PreferencesListWrapper>
      </div>
      <div>
        <h3>{t('Equipos_favoritos')}</h3>
        <PreferencesListWrapper>
          {userSportPreferences?.filter((item) => item.team).length === 0 ? (
            <p>{t('No_tienes_equipos')}</p>
          ) : (
            /* Lista de los equipos preferidos del usuario */
            userSportPreferences
              .filter((item) => item.team)
              .map((item) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      backgroundColor: 'var(--primary)',
                      color: 'var(--primaryBackground)',
                      padding: '5px',
                      borderRadius: '5px',
                    }}
                    key={item.id}
                  >
                    <div className="logo">
                      <img src={item.team.image} alt="" />
                    </div>
                    <p>{item.team.name}</p>
                    <button onClick={() => deletePreference(item.id)}>X</button>
                  </div>
                );
              })
          )}
          <div
            style={{
              display: 'flex',
              gap: '10px',
              backgroundColor: 'var(--primary)',
              color: 'var(--primaryBackground)',
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenTeamModal(true);
              }}
            >
              {t('Agregar_equipo')}
            </button>
          </div>
        </PreferencesListWrapper>
      </div>
      {openSportModal && (
        <AddSportPreferencesModal
          openModal={openSportModal}
          setOpenModal={setOpenSportModal}
          sportsList={sportsList}
          updatePreferences={updatePreferences}
        />
      )}
      {openTeamModal && (
        <AddTeamPreferencesModal
          openModal={openTeamModal}
          setOpenModal={setOpenTeamModal}
          teamsList={teamsList}
          updatePreferences={updatePreferences}
        />
      )}
    </Wrapper>
  );
};

export default SportPreferences;
