import axios from 'axios';
import { useLocalStorage } from './useLocalStorage';

export const useAuth = () => {
  const [user, setUser] = useLocalStorage('user', null);

  const signIn = async (data) => {
    try {
      let authresult = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/login/`,
        data
      );
      let userObj = { authresult };
      userObj.token = authresult.data.access_token;
      setUser(userObj);
    } catch (err) {
      console.error(err);
    }
  };

  const signUp = async (data) => {
    try {
      let authresult = await axios.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/create/`,
        data
      );
      let userObj = { ...authresult.data?.createdUser };
      userObj.token = authresult.data?.encodedToken;
      setUser(userObj);
    } catch (err) {
      console.error(err);
    }
  };

  const signOut = async (data) => {
    let authresult = await axios.post(
      `${process.env.REACT_APP_BASE_URL}auth/logout/`,
      data
    );
    console.log(authresult);
    setUser(null);
  };

  return { user, signIn, signUp, signOut };
};
