import styled from 'styled-components';
import Button from '../../components/atoms/button';
import ActionButton from '../../components/atoms/actionButtons';
import axiosInstance from '../../utils/axios';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
    cursor: pointer;
  }

  .info {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      color: var(--primaryText);
      font-weight: 500;
      text-align: left;
      word-break: break-all;
    }
  }

  .check {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
  }
`;

const Icon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const FriendsList = ({ newChallengeData, friendData }) => {
  const { t } = useTranslation();
  const hadleCreateChallenge = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}challenges/`,
        { ...newChallengeData, is_public: false, rival: friendData.id }
      );
      toast.success(`${t('Reto_enviado_a')} ${friendData.username}`);
    } catch (error) {
      // setMessage(error.response.data.error[0]);
      console.log(error);
      toast.error(t('Verifica_datos_reto'));
    }
  };

  const AddFriend = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/add_friend/${friendData.id}/`,
        { ...newChallengeData, is_public: false, rival: friendData.id }
      );
      toast.success(`${t('Solicitud_amistad')} ${friendData.username}`);
    } catch (error) {
      // setMessage(error.response.data.error[0]);
      console.log(error);
      toast.error(t('Verifica_datos_reto'));
    }
  };

  return (
    <Wrapper>
      <div className="info">
        <Icon>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFr_8_OG0lsB0SmFgR-x1BfvC81aXEUfxvVBS94sEPwzf-YBx7LkHfabwa_upjApwXBDI&usqp=CAU"
            alt=""
          />
        </Icon>
        <p>{friendData.username}</p>
      </div>
      <div className="check">
        {/* <input type="checkbox" name="friend" id="" /> */}
        <Button
          text={t('Retar')}
          color="#fff"
          backgroundColor="var(--primary)"
          onClick={hadleCreateChallenge}
        />
        <ActionButton
          text={
            <PersonAddIcon
              style={{ width: '20px', height: '20px', color: '#fff' }}
            />
          }
          color="#fff"
          backgroundColor="var(--accent)"
          onClick={AddFriend}
        />
      </div>
    </Wrapper>
  );
};

export default FriendsList;
