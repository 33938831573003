import axios from 'axios';

// Instancia de axios para interceptar peticiones y respuestas
const axiosInstance = axios.create();

// Agregar header con el token (si existe) en cada request
axiosInstance.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('user');
    const token = JSON.parse(userData);
    const userToken = localStorage.getItem('user');
    if (userToken) {
      config.headers['Authorization'] = 'Bearer ' + token.data.access_token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Manejo de errores
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Si la respuesta es 401 limpiar localStorage y redirigir al login en todas las respuestas
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
      return;
    }
    throw error;
  }
);

export default axiosInstance;
