import styled from 'styled-components';
import Button from '../../atoms/button';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  max-width: 800px;
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  input {
    width: 20px;
    height: 20px;
  }

  .search_options div {
    margin-top: 10px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;

  .align_left {
    justify-self: start;
  }
`;

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <h2>{t('Privacidad')}</h2>
        <p className="textSecondary">{t('Privacidad_descripcion')}</p>
      </div>
      <Grid>
        <div></div>
        <div>{t('Amigos')}</div>
        <div>{t('Amigos_amigos')}</div>
        <div>{t('Cualquiera')}</div>
        <div className="align_left">
          <label htmlFor="solicitudes_amistad">{t('Privacidad_1')}</label>
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_amistad" />
        </div>
        <div className="align_left">
          <label htmlFor="solicitudes_retos">{t('Privacidad_2')}</label>
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div>
          <input type="checkbox" name="solicitudes_retos" />
        </div>
        <div className="align_left">
          <label htmlFor="mensajes">{t('Privacidad_3')}</label>
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
        <div>
          <input type="checkbox" name="mensajes" />
        </div>
      </Grid>
      <div className="search_options">
        <p>{t('Como_te_encuentran')}</p>
        <p className="textSecondary">{t('Como_te_encuentran_descripcion')}</p>
        <div>
          <label>
            <input type="checkbox" name="busquedas" value="Correo" />{' '}
            {t('Correo')}
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="busquedas" /> {t('Nombre')}
          </label>
        </div>
        <div>
          <label>
            <input type="checkbox" name="busquedas" /> {t('Apodo')}
          </label>
        </div>
      </div>
      <div>
        <Button
          text={t('Guardar_cambios')}
          color="var(--primaryBackground)"
          backgroundColor="var(--button)"
          onClick=""
        />
      </div>
    </Wrapper>
  );
};

export default Privacy;
