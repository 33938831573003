import { useState } from 'react';
import styled from 'styled-components';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import ConfirmDeleteModal from './confirmProfileDeleteModal';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const DeleteAccount = () => {
  const { t } = useTranslation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    nickname: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDeleteConfirmation({
      ...deleteConfirmation,
      [name]: value,
    });
  };

  return (
    <Wrapper>
      {/* Para eliminar la cuenta el usuario debera introducir su apodo en el campo nickname para poder hacer click en eliminar cuenta, luego de hacer click se abrira un modal de confirmacion */}
      <div>
        <h2>{t('Eliminar_cuenta')}</h2>
        <p className="textSecondary">{t('Eliminar_cuenta_descripcion')}</p>
        <ul className="textSecondary">
          <li>{t('En_cuenta_1')}</li>
          <li>{t('En_cuenta_2')}</li>
        </ul>
      </div>
      <div>
        <FormField
          type="text"
          name="nickname"
          value={deleteConfirmation.nickname}
          placeholder={t('Escribe_apodo')}
          labelText={t('Escribe_apodo')}
          onChange={handleChange}
        />
      </div>
      <div>
        <Button
          text={t('Eliminar_cuenta')}
          color="#fff"
          backgroundColor="var(--warning)"
          onClick={(e) => {
            e.preventDefault();
            setOpenConfirmModal(true);
          }}
          disabled={true}
        />
      </div>
      {openConfirmModal && (
        <ConfirmDeleteModal
          openModal={openConfirmModal}
          setOpenModal={setOpenConfirmModal}
        />
      )}
    </Wrapper>
  );
};

export default DeleteAccount;
