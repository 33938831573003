import Modal from '../../components/templates/modal';
import styled from 'styled-components';
import ModalLayout from '../../components/templates/modal/ModalLayout';
import Button from '../../components/atoms/button';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 600px;
  background-color: var(--primaryBackground);

  .title {
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      margin: 0;
    }

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;

    .league {
      font-weight: 700;
    }
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .bet {
    font-weight: 500;
    line-height: 26rem;

    span {
      color: var(--accent);
      font-weight: 700;
    }
  }
`;

const AcceptChallengeModal = ({
  openModal,
  setOpenModal,
  refreshData,
  eventData,
}) => {
  const { t } = useTranslation();

  const ModalLayoutRef = useRef();

  const copyChallengeLink = () => {
    /* Get the text field */
    var copyText = `${window.location.host}/challenge/${eventData.id}/`;

    /* Copy the text to the clipboard */
    navigator.clipboard.writeText(copyText);

    /* Alert the copied text */
    toast.success(t('Se_copio_enlace'));
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <div>
                <h2>{t('Compartir_reto')}</h2>
              </div>
            </div>
            <div className="body">
              <Button
                text={t('Copiar_enlace_reto')}
                color="#fff"
                backgroundColor="#39CB6B"
                onClick={copyChallengeLink}
              />
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default AcceptChallengeModal;
