import styled from 'styled-components';

const Event = styled.div`
  text-align: center;

  .event_teams {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;

    .teams {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .team_logo {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: red;
    }
  }
`;

const CreditInformationListItem = () => {
  return (
    <>
      <div>16 de abril de 2022</div>
      <div>Ganado</div>
      <div>
        <Event>
          <p>UEFA Championship League</p>
          <div className="event_teams">
            <div className="teams">
              Barcelona<div className="team_logo"></div>
            </div>
            VS
            <div className="teams">
              <div className="team_logo"></div>Chelsea
            </div>
          </div>
        </Event>
      </div>
      <div>+50 GCC</div>
      <div>295 GCC</div>
    </>
  );
};

export default CreditInformationListItem;
