import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import RequireAuth from './routes/requireAuth';
import Profile from './pages/account/profile';
import SportPreferences from './pages/account/sportPreferences';
import BankInformation from './pages/account/bankInformation';
import CreditBalance from './pages/account/creditBalance';
import Friends from './pages/account/friends';
import Privacy from './pages/account/privacy';
import DeleteAccount from './pages/account/deleteAccount';
import Challenges from './pages/challenges/Challenges';
import EventData from './pages/eventData';
import ChallengeData from './pages/challengeData';
import ActiveChallenges from './pages/challenges/activeChallenges';
import FinishedChallenges from './pages/challenges/finishedChallenges';
import Dashboard from 'pages/dashboard/Dashboard';
import MyChallenges from './pages/myChallenges/';
import Blog from './pages/Blog';
import Chat from './pages/chat/Chat';
import Rewards from './pages/Rewards';
import SignUp from './pages/signup';
import SignIn from './pages/signin';
import ActivateAccount from './pages/ActivateAccount';
import RecoverPassword from './pages/RecoverPassword';

function App() {
  /* Obtener del localStorage tema seleccionado al cargar */
  const theme = localStorage.getItem('theme');

  if (theme === 'dark') {
    document.documentElement.setAttribute('data-theme', 'dark');
    localStorage.setItem('theme', 'dark');
  } else {
    document.documentElement.setAttribute('data-theme', 'light');
    localStorage.setItem('theme', 'light');
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/eventos" element={<Challenges />} />{' '}
          {/* Muestra un resumen de todos los eventos, activos, en vivo, finalizados. */}
          <Route path="/eventos/activos" element={<ActiveChallenges />} />{' '}
          {/* Muestra la lista de eventos activos. */}
          <Route
            path="/eventos/finalizados"
            element={<FinishedChallenges />}
          />{' '}
          {/* Muestra la lista de eventos finalizados. */}
          <Route path="/" element={<Dashboard />} />{' '}
          {/* Dashboard de la plataforma */}
          <Route path="account">
            {' '}
            {/* Página del perfil del usuario logueado, para administrar su cuenta. */}
            <Route path="profile" element={<Profile />} />
            <Route path="sport-preferences" element={<SportPreferences />} />
            <Route
              path="informacion-bancaria"
              element={<BankInformation />}
            />{' '}
            {/* En desarrollo */}
            <Route path="balance-credito" element={<CreditBalance />} />{' '}
            {/* En desarrollo */}
            <Route path="amigos" element={<Friends />} />
            <Route path="privacidad" element={<Privacy />} />{' '}
            {/* En desarrollo */}
            <Route path="eliminar-cuenta" element={<DeleteAccount />} />
          </Route>
          <Route path="mis-retos" element={<MyChallenges />} />{' '}
          {/* Muestra los retos activos y creados por el usuario, y el historial de los retos en los que participó. */}
          <Route path="blog" element={<Blog />} />
          {/* Página de noticias de la plataforma y del mundo deportivo */}
          <Route path="chat" element={<Chat />} />{' '}
          {/* Chat de la plataforma entre usuarios (En desarrollo) */}
          <Route path="rewards" element={<Rewards />} />{' '}
          {/* Página para listar las recompensas que podrán obtener los usuarios de la página, por fidelidad, o recompensas de temporada (En desarrollo). */}
          <Route path="eventos/:eventId" element={<EventData />} />{' '}
          {/* Página individual de cada evento, se mostrarán datos del mismo y los retos creados del evento. */}
          <Route path="challenge/:challengeId" element={<ChallengeData />} />{' '}
          {/* Página para mostrar el perfil individual de cada usuario, sus datos públicos, retos públicos creados. */}
        </Route>
        <Route path="/login" element={<SignIn />} />
        <Route
          path="/v1/auth/activation/:first/:second"
          element={<ActivateAccount />}
        />{' '}
        {/* Activación del usuario y verificacion de correo */}
        <Route
          path="/v1/auth/reset_password/:first/:second"
          element={<RecoverPassword />}
        />
        <Route path="/registro" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
