import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import Button from '../atoms/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 240px;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }

  .marqueeContainer {
    display: flex;

    & > div {
      margin-right: 50px;
    }
  }

  marquee {
    color: #fff;
    margin: 10px;

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperBuy = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  color: #fff;

  @media (max-width: 800px) {
    display: none;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
`;

const Searchbar = () => {
  const { t } = useTranslation();

  const [eventsList, setEventsList] = useState([]);

  const getEventsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=finished&?page=1&per_page=20&ordering=-start_datetime`
      );
      setEventsList(data.data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getEventsList();
  }, []);

  return (
    <Wrapper>
      <marquee behavior="loop" direction="">
        <div className="marqueeContainer">
          {eventsList.map((result) => {
            return (
              <TeamsWrapper key={result.id}>
                <div className="team">
                  <p>{result.team1.name}</p>
                  <Icon>
                    <img src={result.team1.image} alt="" />
                  </Icon>
                </div>
                <p>
                  {result.score_team1} : {result.score_team2}
                </p>
                <div className="team">
                  <Icon>
                    <img src={result.team2.image} alt="" />
                  </Icon>
                  <p>{result.team2.name}</p>
                </div>
              </TeamsWrapper>
            );
          })}
        </div>
      </marquee>
      <WrapperBuy>
        <Button
          text={t('Depositar_gcc')}
          color="#394A58"
          backgroundColor="#FADC41"
        />
      </WrapperBuy>
    </Wrapper>
  );
};

export default Searchbar;
