import React, { useState } from 'react';

export const AppContext = React.createContext(null);

export const ContextWrapper = (props) => {
  const [store, setStore] = useState({
    timezone: localStorage.getItem('GMT'),
    preferences: [],
  });

  return (
    <AppContext.Provider value={{ store, setStore }}>
      {props.children}
    </AppContext.Provider>
  );
};
