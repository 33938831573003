const translationEN = {
  // Common translations
  //
  Correo: 'Mail',
  Escribe_tu_correo: 'Enter your email',
  Contrasena: 'Password',
  Escribe_contrasena: 'Enter your password',
  Mostrar_contrasena: 'Show password',
  Copyright: 'Copyright © 2023 | GoChallenge | All Rights Reserved',
  Entra_aqui: 'Enter here',
  Depositar_gcc: 'Deposit GCC',
  Confirmar_contrasena: 'Repeat your password',
  Ver_mas: 'Show more',
  Por: 'for',
  Por_mayuscula: 'For',
  Crea_uno: 'Create one',
  Retador: 'Challenger',
  Cancelar_reto: 'Cancel Challenge',
  A_que_gana: 'that wins',
  Has_apostado: "You've bet",
  Apuesta: 'bets',
  Reto_aceptado: 'Challenge accepted',
  Error_al_aceptar_reto: 'There was a mistake accepting the challenge',
  Aceptado: 'Accepted',
  Te_reta_por: 'challenges you for',
  Reto_ya_aceptado: 'This challenge has already been accepted',
  Buscar_deporte: 'Search sport',
  Buscar_equipo: 'Search team',
  Buscar_evento: 'Search event',
  //
  // Login Page
  //
  Ingreso: 'Login',
  Entrar: 'Login',
  Aun_no_tienes_cuenta: "Don't have an account yet?",
  Registrate_aqui: 'Register here',
  Olvidaste_tu_contrasena: 'Forgot your password',
  Recuperar_contrasena: 'Recover password',
  //
  // Register Page
  //
  Registro: 'Register',
  Registro_normal: 'Normal registration',
  Registro_rapido: 'Quick registration',
  Codigo_invitado: 'Guest code',
  Codigo_invitado_placeholder:
    'If you have a code from a friend, write it here',
  Apodo: 'Nickname',
  Apodo_placeholder: 'Write what name you want to be seen',
  Atencion: 'Attention',
  Atencion_texto:
    'A random username and password will be created and sent to your email.',
  Ya_tienes_cuenta: 'You already have an account?',
  Registrarme: 'Register',
  Mensaje_de_registro:
    'By registering you confirm that you have read and accepted the',
  Terminos_y_condiciones: 'Terms & Conditions',
  Y_la: 'and the',
  Politica_de_privacidad: 'Privacy Policy',
  De_la_compania: 'of the company',
  Bienvenido_GoChallenge: 'Welcome to GoChallenge',
  Bienvenida_mensaje:
    'A space to meet and challenge sports enthusiasts like you',
  Pasos_texto: 'Follow these steps to start challenging',
  Paso_1: 'Top up your GoChallenge Coins wallet',
  Paso_2: "Select one of the platform's sporting events to create a challenge",
  Paso_3: 'Share the challenge with your friends and users of the platform',
  //
  // Recover password modal
  //
  Olvidaste_la_contrasena_question: 'Forgot password?',
  Olvidaste_la_contrasena_texto:
    "Enter the email address you use on GoChallenge. We'll send you a link to reset your password.",
  Enviar_correo: 'Send recovery email',
  Email_enviado: 'Recovery email sent',
  //
  // Navbar (src/components/templates/navbar)
  //
  Dashboard: 'Dashboard',
  Eventos: 'Events',
  Mis_retos: 'My challenges',
  A_jugar: "Let's play",
  Saldo: 'Balance',
  Tu_zona_horaria: 'Your Timezone',
  Selecciona_zona_horaria: 'Select your timezone',
  Perfil: 'Profile',
  Preferencias_deportivas: 'Sport preferences',
  Amigos: 'Friends',
  Privacidad: 'Privacy',
  Eliminar_cuenta: 'Delete account',
  Cerrar_sesion: 'Logout',
  //
  // Activate account page (src/pages/ActivateAccount)
  //
  Ha_sido_activada: "Your account has been activated, let's play!!",
  Estamos_activando_cuenta: "We're activating your account",
  Problema_al_activar: 'There was a problem with your activation',
  Volver_a_plataforma: 'Back to platform',
  //
  // Recover Password page (src/pages/RecoveryPassword)
  //
  Recuperar_contrasena: 'Reset password',
  Nueva_contrasena: 'New password',
  Recordaste_contrasena: 'Already remembered your password?',
  //
  // Dashboard (src/pages/dashboard)
  //
  Mira_quien_reto: 'See who challenged you',
  No_has_recibido_reto: "You haven't received any challenges",
  Tus_retos_activos: 'Your active challenges',
  Sin_retos_activos: "You don't have any active challenges",
  Reto_activo_con: 'You have an active challenge with',
  Eventos_en_vivo_titulo: 'Live events',
  Retos_activos_plataforma: 'Active challenges on the platforms',
  Retos_activos_plataforma_descripcion:
    'These are all the challenges available on the platform.',
  No_hay_retos_activos: 'No active challenges',
  No_hay_mas_retos_activos: 'No more active challenges',
  //
  // Retos recibidos component (src/pages/dashboard/challengeReceived)
  //
  Te_ha_retado_por: 'has challenged you for',
  Que_gana: 'to win',
  //
  // Results component (src/components/templates/results)
  //
  Resultados: 'Results',
  //
  // User Rankings component (src/components/templates/usersRanking)
  //
  Usuarios_mas_referidos: 'Users with more referrals',
  //
  // Eventos page (src/pages/challenges/Challenges)
  //
  Todos: 'All',
  Con_resultados: 'With results',
  Eventos_vivo: 'Live events',
  Eventos_activos: 'Active events',
  No_eventos_activos: 'No active events',
  Eventos_finalizados: 'Completed events',
  No_eventos_finalizados: 'No events completed',
  //
  //Create challenge modal (src/pages/challenges/createChallengeModal)
  //
  Crea_reto: 'Create challenge',
  Crea_reto_descripcion:
    'Challenge your friends or leave a public challenge on the platform',
  A_que_equipo_vas: 'What team are you going to?',
  Selecciona_equipo: 'Select your team',
  Challenge_cap: 'What will be the amount of the challenge in GCC?',
  Hacer_reto_publico: 'Make public challenge',
  Hacer_reto_publico_descripcion:
    'Make a public challenge so that anyone in the community can accept it',
  Retar_amigos: 'Challenge friends >>',
  Retar_amigos_descripcion:
    'Challenge one or more of the people on your friends list, the challenge will be done with the first one who accepts it',
  Listo: 'Done',
  //
  // Retos activos item (src/pages/challenges/activeChallenges)
  //
  Retos_creados_amigos: 'Challenges created by friends for this event:',
  Sin_retos_evento: 'There are no active challenges for this event',
  Todos_retos: 'All the challenges created for this event',
  Hay: 'There',
  Reto: 'challenge',
  Retos: 'challenges',
  Para_este_evento: 'for this event',
  //
  // Retos finalizados item (src/pages/challenges/finishedChallengesItem)
  //
  Esperando_resultados: 'Waiting for results',
  Ver: 'View',
  Ver_resultados: 'See results',
  //
  // Lista de amigos (src/pages/challenges/FriendListItem)
  //
  Reto_enviado_a: 'Challenge sent to',
  Verifica_datos_reto:
    'Verify that you have selected your team and the amount of the challenge',
  Retar: 'Challenge',
  //
  // News component (src/components/templates/news)
  //
  Noticias: 'News',
  //
  // My challenges page
  //
  Mis_retos_activos: 'My active challenges',
  Mis_retos_activos_descripcion:
    'These are all challenges that already have a rival.',
  Retos_recibidos: 'Challenges received',
  Retos_recibidos_descripcion:
    'These are all the events in which you have been challenged, accept them!!',
  No_has_recibido_retos: "You haven't received any challenges",
  Retos_creados: 'Challenges created',
  Retos_creados_descripcion:
    'These are the challenges you have created or sent to friends and are waiting to be accepted.',
  Retos_cancelados: 'Cancelled challenges',
  Retos_cancelados_descripcion:
    'These are the challenges you have decided to cancel.',
  No_hay_retos_cancelados: 'There are no cancelled challenges.',
  Historial_retos: 'Challenge history',
  Historial_retos_descripcion:
    'Check here all the challenges past, won, lost or in a draw.',
  No_hay_finalizados: 'There are no completed challenges.',
  //
  // Active challenge item (src/pages/myChallenges/challengeItem)
  //
  Toast_no_puedes_cancelar: "You can't cancel an active challenge",
  //
  // Accept challenge modal (src/pages/myChallenges/acceptChallengeModal)
  //
  Aceptas_reto_de: 'You accept the challenge by',
  Aceptar_reto_favor: 'You will accept the challenge in favor of',
  Aceptar_el_reto_boton: 'Accept the challenge',
  //
  // Accept challenge modal (src/pages/myChallenges/declineChallengeModal)
  //
  Rechazas_reto_de: 'You reject the challenge of',
  Estas_a_favor: 'You are in favor of',
  Rechazar_reto_boton: 'Reject the challenge',
  //
  // Share modal (src/pages/myChallenges/shareModal)
  //
  Compartir_reto: 'Share Challenge',
  Copiar_enlace_reto: 'Copy challenge link',
  //
  // Profile page (src/components/templates/account/profile)
  //
  Editar_perfil: 'Edit profile',
  Editar_perfil_descripcion: 'Update your photo and general information here',
  Documento_identidad: 'Identity document',
  Reenviar: 'Forward',
  Agregar: 'Add',
  Tu_correo: 'Your Email',
  Email_verificado: 'Email verified',
  Email_no_verificado_enviar_verificar: 'Email not verified, send verification',
  Fotografia: 'Photography',
  Nombre: 'Name',
  Nombre_placeholder: 'Enter your name',
  Apellido: 'Surname',
  Apellido_placeholder: 'Enter your last name',
  Username: 'Username',
  Username_aviso: 'When changing it you must wait a year to change it again',
  Username_placeholder: 'Type the name as you want other users to see you',
  Fecha_nacimiento: 'Date of birth',
  Fecha_nacimiento_placeholder: 'dd / mm / yyyy',
  Descripcion_personal: 'Personal description',
  Descripcion_personal_placeholder: 'Write a brief personal description',
  Telefono: 'Phone',
  Telefono_placeholder: 'Enter your phone number',
  Guardar_cambios: 'Save changes',
  //
  // Preferencais deportivas page (src/components/templates/account/sportPreferences)
  //
  Preferencias_deportivas: 'Sports preferences',
  Preferencias_deportivas_descripcion:
    'Select your favorite sports and teams here',
  Deportes_favoritos: 'Favorite sports',
  No_tienes_deportes: "You don't have favorite sports",
  Agregar_deporte: 'Add sport',
  Agregar_deporte_descripcion: 'Add a new sport to the platform',
  Lista_deportes: 'List of sports on the platform',
  Equipos_favoritos: 'Favorite teams',
  No_tienes_equipos: "You don't have any favorite teams",
  Agregar_equipo: 'Add teams',
  Agregar_equipo_descripcion: 'Add a new team for the platform',
  Lista_equipos: 'List of sports teams on the platform',
  //
  // Friends page (src/components/templates/account/friends)
  //
  Invitaciones_amigos: 'Invitations from friends',
  Invitaciones_amigos_descripcion: 'Friend requests received',
  Amigos: 'Friends',
  Amigos_descripcion: 'See the list of friends here',
  Invitar_amigos: 'Invite friends',
  Invitar_amigos_descripcion: 'Invite your friends to join and remnant',
  Codigo_invitacion: 'Invitation code',
  Codigo_invitacion_descripcion:
    'Share this invite code with your friends and earn GCC',
  Se_han_registrado: 'Have registered',
  Usuarios_con_tu_codigo_de_invitacion: 'users with your invitation code.',
  //
  // Friendrequest item (src/components/templates/account/friendsRequestListItem)
  //
  Aceptar: 'Accept',
  Rechazar: 'Reject',
  //
  // Delete account page (src/components/templates/account/deleteAccount)
  //
  Eliminar_cuenta: 'Delete account',
  Eliminar_cuenta_descripcion:
    'Your account will be deactivated for a few days and then deleted, keep in mind that:',
  En_cuenta_1: 'You must not have an available GCC balance.',
  En_cuenta_2: 'You should not have any active challenges.',
  Escribe_apodo: 'Enter your nickname to confirm deletion',
  Confirmar_eliminacion: 'Confirm deletion',
  Confirmar_eliminacion_verificacion:
    'Do you really want to delete your account?',
  //
  // Privacy page (src/components/templates/account/privacy)
  //
  Privacidad: 'Privacy',
  Privacidad_descripcion:
    'Select who can search and challenge you on the platform',
  Amigos_amigos: 'Friends of friends',
  Cualquiera: 'Any',
  Privacidad_1: 'Who can send you friend requests?',
  Privacidad_2: 'Who can send you challenge requests?',
  Privacidad_3: 'Who can send you direct messages?',
  Como_te_encuentran: 'How do you want to be found in searches?',
  Como_te_encuentran_descripcion:
    'Other users can search for you by typing your...',
  //
  // Eventos Activos (src/pages/challenges/activeChallenges)
  //
  Proximos: 'Next',
  Dias: 'days',
  //
  // Share modal
  //
  Se_copio_enlace: 'Challenge link copied',
};

export default translationEN;
