import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import styled from 'styled-components';
import Results from '../../components/templates/results';
import UsersRanking from '../../components/templates/usersRanking';
// import NewsItem from '../../components/templates/news';
import Main from '../../layouts/main';
import ActiveChallengesItem from './activeChallengesItem';
import FinishedChallengesItem from './finishedChallengesItem';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import FormField from '../../components/atoms/FormField';
import LiveChallengesItem from './liveChallengesItem';
import Carousel from 'react-material-ui-carousel';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChallengeSkeleton from '../../components/templates/challengeSkeleton';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  height: 100%;
  .main {
    width: 100%;
    padding: 20px;
  }

  .info_sports {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1380px) {
      display: none;
    }
  }

  .filterSports {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  h3 {
    margin-bottom: 20px;
    a {
      color: var(--secondaryText);
      font-size: 14rem;
    }
  }

  @media (max-width: 1380px) {
    grid-template-columns: 300px 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Table = styled.div`
  width: 100%;
`;

const Icon = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const IconLeagues = styled(Icon)`
  margin: 15px;
`;

const Sport = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: #394a58;
    border-radius: 5px;
    cursor: pointer;
  }

  p {
    color: #fff;
  }
`;

const FilterLeagues = styled.div`
  display: flex;
  background-color: var(--secondaryBackground);\
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  overflow: auto;

  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }

  .iconsBox {
    display: flex;
  }
`;

const FilterSport = styled(FilterLeagues)`
  display: none;
  width: 100%;

  @media (max-width: 1100px) {
    display: flex;
    width: calc(100vw - 40px);
  }
`;

const SearchBar = styled.div`
  & > div {
    margin: 0 0 10px 0;
  }
  label {
    font-weight: 500;
    color: #fff;
  }
`;

const Challenges = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sportsList, setSportsList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const [upcomingEventsList, setUpcomingEventsList] = useState([]);
  const [liveEventsList, setLiveEventsList] = useState([]);
  const [finishedEventsList, setFinishedEventsList] = useState([]);
  const [sportFilter, setSportFilter] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [search, setSearch] = useState('');

  const getSportsList = async (e) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      //console.log(error.response?.status);
    }
  };

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/?status=1/`
      );
      setLeaguesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getUpcomingEventsList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=upcoming&?page=1&per_page=5`
      );
      setUpcomingEventsList(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  const getLiveEventsList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=live&?page=1&per_page=5`
      );
      setLiveEventsList(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  const getFinishedEventsList = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/?status=finished&?page=1&per_page=5&ordering=-start_datetime`
      );
      setFinishedEventsList(data.data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSportsList();
    getLeaguesList();
    getUpcomingEventsList();
    getLiveEventsList();
    getFinishedEventsList();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="filterSports">
          <SearchBar>
            <FormField
              type="search"
              name="name"
              labelText={t('Buscar_deporte')}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </SearchBar>
          <Sport
            onClick={(e) => {
              setSportFilter('');
              setSearch('');
            }}
          >
            <Icon>
              <SelectAllIcon
                style={{ width: '30px', height: '30px', color: '#fff' }}
              />
            </Icon>
            <p>{t('Todos')}</p>
          </Sport>

          {sportsList
            .filter((item) => {
              return item.name.toLowerCase().includes(search);
            })
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((sport) => {
              return (
                <Sport
                  onClick={(e) => {
                    setSportFilter(e.target.innerText);
                  }}
                  style={{
                    backgroundColor:
                      sportFilter === sport.name ? '#293641' : null,
                    borderRadius: '5px',
                  }}
                  key={sport.id}
                >
                  <Icon>
                    <img src={sport.image} alt="" />
                  </Icon>
                  <p>{sport.name}</p>
                </Sport>
              );
            })}
        </div>
        <div className="main">
          <FilterSport>
            <div className="iconsBox">
              <IconLeagues
                onClick={(e) => {
                  setSportFilter('');
                  setLeagueFilter('');
                }}
              >
                <SelectAllIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    color: 'var(--primaryText)',
                  }}
                />
              </IconLeagues>
              {sportsList
                .sort((a, b) => {
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((sport) => {
                  return (
                    <IconLeagues
                      key={sport.id}
                      onClick={() => setSportFilter(`${sport.name}`)}
                    >
                      <img src={sport.image} alt={sport.name} />
                      <span
                        style={{
                          backgroundColor:
                            sportFilter === sport.name
                              ? 'var(--primaryText)'
                              : null,
                          width: '30px',
                          borderRadius: '0 0 5px 5px',
                          height: '4px',
                          padding: '2px',
                          position: 'absolute',
                          bottom: '-10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      ></span>
                    </IconLeagues>
                  );
                })}
            </div>
          </FilterSport>
          <FilterLeagues>
            <div className="iconsBox">
              <IconLeagues
                onClick={(e) => {
                  setLeagueFilter('');
                }}
              >
                <SelectAllIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    color: 'var(--primaryText)',
                  }}
                />
              </IconLeagues>
              {leaguesList
                .filter((item) => {
                  return item.sport.name.includes(sportFilter);
                })
                .map((item) => {
                  return (
                    <IconLeagues
                      key={item.id}
                      onClick={() => setLeagueFilter(`${item.name}`)}
                    >
                      <img src={item.image} alt={item.name} />
                      <span
                        style={{
                          backgroundColor:
                            leagueFilter === item.name
                              ? 'var(--primaryText)'
                              : null,
                          width: '30px',
                          borderRadius: '0 0 5px 5px',
                          height: '4px',
                          padding: '2px',
                          position: 'absolute',
                          bottom: '-10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      ></span>
                    </IconLeagues>
                  );
                })}
            </div>
          </FilterLeagues>
          {liveEventsList &&
          liveEventsList
            ?.filter((event) => {
              return event.sport.name.includes(sportFilter);
            })
            .filter((item) => {
              return item.league.name.includes(leagueFilter);
            }).length !== 0 ? (
            <>
              <h3>{t('Eventos_vivo')}</h3>
              <div className="liveEventBox" style={{ marginBottom: '20px' }}>
                <Carousel
                  NextIcon={
                    <ArrowRightIcon
                      style={{ width: '25px', height: '25px', color: '#fff' }}
                    />
                  }
                  PrevIcon={
                    <ArrowLeftIcon
                      style={{ width: '25px', height: '25px', color: '#fff' }}
                    />
                  }
                >
                  {liveEventsList
                    ?.filter((event) => {
                      return event.sport.name.includes(sportFilter);
                    })
                    .filter((item) => {
                      return item.league.name.includes(leagueFilter);
                    })
                    .slice(0, 6)
                    .map((item, i) => (
                      <LiveChallengesItem eventData={item} key={item.id} />
                    ))}
                </Carousel>
              </div>
            </>
          ) : null}

          <h3>
            {t('Eventos_activos')} <a href="/eventos/activos">{t('Ver_mas')}</a>
          </h3>
          <Table>
            {loading && (
              <div>
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
              </div>
            )}
            {upcomingEventsList.length === 0 ? (
              <p>{t('No_eventos_activos')}</p>
            ) : (
              upcomingEventsList
                ?.filter((event) => {
                  return event.sport.name.includes(sportFilter);
                })
                .filter((item) => {
                  return item.league.name.includes(leagueFilter);
                })
                .map((eventData) => {
                  return (
                    <ActiveChallengesItem
                      eventData={eventData}
                      key={eventData.id}
                      refreshData={getUpcomingEventsList}
                    />
                  );
                })
            )}
          </Table>
          <h3 style={{ marginTop: '40px' }}>
            {t('Eventos_finalizados')}{' '}
            <a href="/eventos/finalizados">{t('Ver_mas')}</a>
          </h3>
          <Table>
            {finishedEventsList.length === 0 ? (
              <p>{t('No_eventos_finalizados')}</p>
            ) : (
              finishedEventsList
                ?.filter((event) => {
                  return event.sport.name.includes(sportFilter);
                })
                .filter((item) => {
                  return item.league.name.includes(leagueFilter);
                })
                .map((eventData) => {
                  return (
                    <FinishedChallengesItem
                      eventData={eventData}
                      key={eventData.id}
                      refreshData={getFinishedEventsList}
                    />
                  );
                })
            )}
          </Table>
        </div>
        <div className="info_sports">
          <Results />
          <UsersRanking />
          {/* <NewsItem /> */}
        </div>
      </Wrapper>
    </Main>
  );
};

export default Challenges;
