import React from 'react';
import Main from '../../layouts/main';
import AccountMenu from '../../components/templates/account';
import PrivacyComponent from '../../components/templates/account/privacy';

const Privacy = () => {
  return (
    <Main>
      <AccountMenu>
        <PrivacyComponent />
      </AccountMenu>
    </Main>
  );
};

export default Privacy;
