import React from 'react';
import styled from 'styled-components';
import ProfileImage from '../../../assets/images/profile.jpg';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 150px;
  justify-content: space-between;

  .textSecondary {
    color: var(--secondaryText);
  }

  .user_data {
    display: flex;
    align-items: center;

    .user_name {
      font-weight: 700;
    }

    & > div {
      margin: 20px;
    }

    .user_image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .user_actions {
    button {
      margin: 20px 0 20px 20px;
      font-weight: 700;
    }

    .challenge_user {
      color: var(--accent);
    }

    .delete_user {
      color: var(--warning);
    }

    .block_user {
      color: var(--accent);
    }
  }
`;

const FriendsListItem = ({ friendData, refreshData }) => {
  const { t } = useTranslation();
  /* const blockFriend = async (action) => {
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/block/${friendData.user.id}/`
      );
      toast.success('Amigo bloqueado');
    } catch (error) {
      toast.error('Ocurrio un error, intenta mas tarde');
      // console.log(error.response.data.detail.name);
    }
  };

  const unblockFriend = async (action) => {
    try {
      const { data } = await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_URL}auth/users/unblock/${friendData.user.id}/`
      );
      toast.success('Amigo desbloqueado');
    } catch (error) {
      toast.error('Ocurrio un error, intenta mas tarde');
      // console.log(error.response.data.detail.name);
    }
  }; */

  const deleteFriend = async (action) => {
    try {
      const { data } = await axiosInstance.delete(
        `${process.env.REACT_APP_BASE_URL}auth/users/remove_friend/${friendData.user.id}/`
      );
      toast.success('Amigo eliminado');
      refreshData();
    } catch (error) {
      toast.error('Ocurrio un error, intenta mas tarde');
      // console.log(error.response.data.detail.name);
    }
  };

  return (
    <Wrapper>
      <div className="user_data">
        <div className="user_image">
          <img src={ProfileImage} alt="" />
        </div>
        <div>
          <p className="user_name">
            {friendData?.user.first_name} {friendData?.user.last_name}
          </p>
          <p className="textSecondary">{friendData?.user.username}</p>
        </div>
      </div>
      <div className="user_actions">
        {/* <button className="challenge_user">Retar</button> */}
        {friendData?.status === 'pending' ? (
          <>
            <p>{t('Solicitud_enviada')}</p>
          </>
        ) : (
          <>
            {/* <button
              className="block_user"
              onClick={friendData.status ? blockFriend : unblockFriend}
            >
              {friendData.status ? 'Bloquear' : 'Desbloquear'}
            </button> */}
            <button className="delete_user" onClick={deleteFriend}>
              {t('Eliminar')}
            </button>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default FriendsListItem;
