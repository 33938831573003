import styled from 'styled-components';
import axiosInstance from '../../utils/axios';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Results from '../../components/templates/results';
import Main from '../../layouts/main';
import NewsItem from '../../components/templates/news';
import UserChallenges from './userChallenges';
import Camp from '../../assets/images/camp.jpg';
import { returnTime } from '../../utils/formatDate';
import { AppContext } from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 1fr 300px;
  height: 100%;
  .main {
    width: 100%;
    padding: 20px;

    a {
      font-size: 12rem;
      color: var(--secondaryText);
    }

    .challengesBox {
      background-color: var(--secondaryBackground);
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }

  .info {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .team {
    display: flex;
    gap: 10px;
    align-items: center;

    &>div {
      flex: 1 0 20px;
    }
  }
}
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const WrapperMobile = styled.div`
  background-color: var(--secondaryBackground);
  color: #fff;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 20px;

  .challengeData {
    padding: 20px;
    & > * {
      margin: 10px 0;
    }

    .league {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  button {
    padding: 10px;
    background-color: #39cb6b;
  }
`;

const MyChallenges = () => {
  const { t } = useTranslation();
  const data = localStorage.getItem('user');
  const [challengeList, setChallengeList] = useState([]);
  const [friendsChallengeList, setFriendsChallengeList] = useState([]);
  const { eventId } = useParams();
  const [eventData, setEventData] = useState({
    description: '',
    end_bet_datetime: '',
    id: '',
    league: { id: '', name: '' },
    name: '',
    score_team1: '',
    score_team2: '',
    sport: { id: '', name: '' },
    start_datetime: '',
    status: '',
    team1: { id: '', name: '' },
    team2: { id: '', name: '' },
    winner_team: { id: '', name: '' },
    status_code: '',
  });
  const context = useContext(AppContext);

  const getEventData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}events/${eventId}/`
      );
      setEventData(data.detail);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getChallengesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/?event=${eventId}`
      );
      setChallengeList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getFriendsChallengesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/friends_challenges/${eventId}/`
      );
      setFriendsChallengeList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getEventData();
    getChallengesList();
    getFriendsChallengesList();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <WrapperMobile>
            <div
              className="challengeData"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(25, 25, 25, 0.52), rgba(25, 25, 25, 0.73)), url(${Camp})`,
                borderRadius: '5px',
                backgroundSize: 'cover',
                backgoundPosition: 'center',
                backgroundPositionY: 'center',
              }}
            >
              <div className="league">
                <Icon>
                  <img src={eventData?.sport.image} alt="" />
                </Icon>
                <p style={{ fontWeight: '700' }}>{eventData?.league.name}</p>
                <Icon>
                  <img src={eventData?.league.image} alt="" />
                </Icon>
              </div>
              <TeamsWrapper>
                <div className="team">
                  <p>{eventData?.team1.name} </p>
                  <Icon>
                    <img src={eventData?.team1.image} alt="" />
                  </Icon>
                </div>
                <p>vs</p>
                <div className="team">
                  <Icon>
                    <img src={eventData?.team2.image} alt="" />
                  </Icon>
                  <p>{eventData?.team2.name} </p>
                </div>
              </TeamsWrapper>
              <p>
                {returnTime(eventData.start_datetime, context.store.timezone, {
                  dateStyle: 'medium',
                })}
                {' • '}
                <span style={{ color: '#aaa' }}>
                  {returnTime(
                    eventData.start_datetime,
                    context.store.timezone,
                    {
                      timeStyle: 'short',
                    }
                  )}
                </span>
              </p>
            </div>
          </WrapperMobile>
          <div className="challengesUsers hide">
            {friendsChallengeList.length === 0 ? null : (
              <>
                <p>{t('Retos_creados_amigos')}</p>
                <div style={{ marginBottom: '20px' }}>
                  {friendsChallengeList.length === 0 ? (
                    <p
                      style={{
                        color: 'var(--secondaryText)',
                        marginTop: '10px',
                      }}
                    >
                      {t('Sin_retos_eventos')},{' '}
                      <button
                        /* onClick={(e) => {
                          e.preventDefault();
                          setOpenCreateChallengeModal(true);
                        }} */
                        style={{ color: 'var(--accent)', fontWeight: '700' }}
                      >
                        {t('Crea_uno')}
                      </button>
                    </p>
                  ) : (
                    friendsChallengeList
                      .filter((item) => {
                        return item.status != 'active';
                      })
                      .map((challenge) => {
                        return (
                          <UserChallenges
                            challengeData={challenge}
                            key={challenge.id}
                          />
                        );
                      })
                  )}
                </div>
              </>
            )}
            <p>{t('Todos_retos')}:</p>
            <div>
              {challengeList.length === 0 ? (
                <p style={{ color: 'var(--secondaryText)', marginTop: '10px' }}>
                  {t('Sin_retos_eventos')}
                  <button
                    /* onClick={(e) => {
                      e.preventDefault();
                      setOpenCreateChallengeModal(true);
                    }} */
                    style={{ color: 'var(--accent)', fontWeight: '700' }}
                  >
                    {t('Crea_uno')}
                  </button>
                </p>
              ) : (
                challengeList
                  .filter((item) => {
                    return item.status != 'active';
                  })
                  .map((challenge) => {
                    return (
                      <UserChallenges
                        challengeData={challenge}
                        key={challenge.id}
                      />
                    );
                  })
              )}
            </div>
          </div>
        </div>
        <div className="info">
          <Results />
          <NewsItem />
        </div>
      </Wrapper>
    </Main>
  );
};

export default MyChallenges;
