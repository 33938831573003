import styled from 'styled-components';
import NewsItem from '../components/templates/news';
import { useContext } from 'react';
import { AppContext } from '../contexts/AppContext';
import Results from '../components/templates/results';
import Main from '../layouts/main';
import Reward from '../assets/images/rewards.png';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: flex;
  height: 100%;

  .main {
    width: 100%;
    padding: 20px;

    .rewardsBox {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 20px;

      & > div {
        width: 100%;
      }

      img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }

  .info {
    background-color: #324250;
    width: 400px;
    padding: 20px;

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

const Rewards = () => {
  /* const getEventsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/`
      );
      setEventsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getEventsList();
  }, []); */

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <h3>Rewards</h3>
          <div className="rewardsBox">
            <div>
              <img src={Reward} alt="" />
            </div>
            <div>
              <img src={Reward} alt="" />
            </div>
            <div>
              <img src={Reward} alt="" />
            </div>
            <div>
              <img src={Reward} alt="" />
            </div>
          </div>
        </div>
        <div className="info">
          <Results />
          <NewsItem />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Rewards;
