import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../../utils/axios';
import ProfileImage from '../../../assets/images/profile.jpg';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 40px;
  h3,
  p {
    color: #fff;
    font-weight: 300;
  }

  a,
  span {
    color: var(--secondaryText);
    font-size: 14rem;
  }
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 5px 0 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const UsersRanking = () => {
  const { t } = useTranslation();
  const [eventsList, setEventsList] = useState({
    data: [],
    detail: {},
  });

  const getRankingList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/invitations/top/`
      );
      setEventsList(data);
    } catch (error) {}
  };

  useEffect(() => {
    getRankingList();
  }, []);

  return (
    <Wrapper>
      <h3>
        {t('Usuarios_mas_referidos')}
        {/* <a href="/">Ver mas</a> */}
      </h3>
      {eventsList.data.map((user) => {
        return (
          <div style={{ margin: '5px 0', display: 'flex' }} key={user.id}>
            <Icon>
              <img
                src={user.avatar === '' ? ProfileImage : user.avatar}
                alt=""
              />
            </Icon>
            <p style={{ fontSize: '14rem', color: 'var(--secondaryText)' }}>
              <a href="">{user.username}</a>{' '}
              <span style={{ color: '#fff' }}>{user.total_used}</span>
            </p>
          </div>
        );
      })}
    </Wrapper>
  );
};

export default UsersRanking;
