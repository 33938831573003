import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import axiosInstance from '../../../utils/axios';
import NewIdentityModal from './newIdentityModal';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;

const PersonalIdentifier = styled.div`
  a {
    margin-left: 30px;
    font-size: 13rem;
  }
`;

const Form = styled.form`
  .profilePicture {
    display: flex;
    align-items: center;

    span {
      font-size: 12rem;
    }

    input {
      width: 0.1px;
      height: 0.1px;
    }

    label {
      font-weight: 700;
    }

    input::before {
      content: '+';
      position: absolute;
      cursor: pointer;
      font-weight: 700;
      font-size: 50rem;
      color: var(--primary);
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
    }

    .profile_picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  div {
    flex-direction: row;
    align-items: center;

    label {
      width: 200px;
    }

    input {
      width: 100%;
      max-width: 400px;
    }
  }
`;

const InputFileWrapper = styled.div`
  display: flex;
  width: 400px;

  button {
    color: #ff5353;
    font-weight: 700;
    font-size: 12rem;
    margin-left: 20px;
  }
`;

const Profile = () => {
  const { t } = useTranslation();
  const [profileData, setProfileData] = useState({
    id: '',
    avatar: null,
    preferences: [],
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    phone: '',
    personal_description: '',
    status: '',
    is_email_verified: null,
    e_wallet: '',
  });
  const [selectedFile, setSelectedFile] = useState('');
  const [identities, setIdentities] = useState({
    detail: {
      id: '',
      document_number: '',
      document_type: { name: '' },
      validation: { status: '' },
    },
  });
  const [updatedData, setUpdatedData] = useState({});
  const [openNewIdentityModal, setOpenNewIdentityModal] = useState(false);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };

  const submitUserUpdate = async (e) => {
    const dataForm = new FormData();

    if (selectedFile) {
      dataForm.append('avatar', selectedFile);

      for (var key in updatedData) {
        dataForm.append(key, updatedData[key]);
      }
    }

    e.preventDefault();
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/update/`,
        selectedFile ? dataForm : updatedData
      );
      toast.success('Datos actualizados con exito');
    } catch (error) {
      if (error.response.data.phone) {
        toast.warn(`Telefono: ${error.response.data.phone[0]}`);
      } else if (error.response.data.username) {
        toast.warn(error.response.data.username[0]);
      } else {
        toast.error('Ocurrio un error inesperado');
      }
      // console.log(error.response.data.username[0]);
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setProfileData(data);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const resendEmailVerification = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/accounts/resend_activation/${profileData.id}`
      );
      toast.success('Email de verificacion enviado');
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const getIdentities = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/identities/`
      );
      setIdentities(data);
    } catch (error) {
      toast.warn(error.response.data.detail);
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getUserData();
    getIdentities();
  }, []);

  return (
    <Wrapper>
      <div>
        <h2>{t('Editar_perfil')}</h2>
        <p className="textSecondary">{t('Editar_perfil_descripcion')}</p>
      </div>
      <PersonalIdentifier>
        <p>
          {t('Documento_identidad')}:{' '}
          <span className="textSecondary">
            {identities.detail.document_number !== ''
              ? `${identities.detail.document_number} (${identities.detail.document_type.name})`
              : null}
          </span>
          {identities.detail.validation ? (
            <span
              style={{
                marginLeft: '5px',
                color:
                  identities?.detail.validation.status === 'verified'
                    ? 'green'
                    : 'var(--accent)',
                fontWeight: '700',
                fontSize: '14rem',
              }}
            >
              {identities?.detail.validation.status}
            </span>
          ) : (
            <a
              onClick={(e) => {
                e.preventDefault();
                setOpenNewIdentityModal(true);
              }}
            >
              {identities.detail.validation
                ? `Reject (${identities.detail.validation.error}) - ${t(
                    'Reenviar'
                  )}`
                : t('Add')}
            </a>
          )}
        </p>
      </PersonalIdentifier>
      <PersonalIdentifier>
        <p>
          {t('Tu_correo')}:{' '}
          <span className="textSecondary">{profileData.email}</span>
          {profileData.is_email_verified ? (
            <span
              style={{
                color: 'green',
                fontWeight: '700',
                fontSize: '14rem',
                marginLeft: '30px',
              }}
            >
              {t('Email_verificado')}
            </span>
          ) : (
            <a
              onClick={(e) => {
                e.preventDefault();
                resendEmailVerification();
              }}
              style={{ color: 'var(--warning)' }}
            >
              {t('Email_no_verificado_enviar_verificar')}
            </a>
          )}
        </p>
      </PersonalIdentifier>
      <div>
        <Form>
          <div className="profilePicture">
            <label htmlFor="profilePicture">
              {t('Fotografia')} <span className="textSecondary">(Max 3Mb)</span>
            </label>
            <InputFileWrapper>
              <div className="profile_picture" style={{ flex: '0 0 60px' }}>
                <img
                  src={
                    selectedFile != ''
                      ? URL.createObjectURL(selectedFile)
                      : profileData.avatar
                  }
                  alt=""
                />
                <input
                  type="file"
                  name="profilePicture"
                  onChange={handleFileSelect}
                />
              </div>
              {/* <button>Eliminar</button> */}
            </InputFileWrapper>
          </div>
          <FormField
            type="text"
            name="first_name"
            value={updatedData.first_name || profileData.first_name}
            placeholder={t('Nombre_placeholder')}
            labelText={t('Nombre')}
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="last_name"
            value={updatedData.last_name || profileData.last_name}
            placeholder={t('Apellido_placeholder')}
            labelText={t('Apellido')}
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="username"
            value={updatedData.username || profileData.username}
            placeholder={t('Username_placeholder')}
            labelText={
              <p style={{ lineHeight: '14rem' }}>
                {t('Username')}
                <br />
                <span
                  style={{
                    color: 'var(--secondaryText)',
                    fontSize: '12rem',
                    lineHeight: '12rem',
                  }}
                >
                  {t('Username_aviso')}
                </span>
              </p>
            }
            onChange={handleChange}
          />
          <FormField
            type="date"
            name="date_of_birth"
            value={updatedData.date_of_birth || profileData.date_of_birth}
            labelText={t('Fecha_nacimiento')}
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="personal_description"
            value={
              updatedData.personal_description ||
              profileData.personal_description
            }
            placeholder={t('Descripcion_personal_placeholder')}
            labelText={t('Descripcion_personal')}
            onChange={handleChange}
            disabled
          />
          <FormField
            type="number"
            name="phone"
            value={updatedData.phone || profileData.phone}
            placeholder={t('Telefono_placeholder')}
            labelText={t('Telefono')}
            onChange={handleChange}
          />
          {/* <FormField
            type="text"
            name="address"
            value={profileData.address}
            placeholder="Escribe tu dirección"
            labelText="Dirección"
            onChange={handleChange}
          />
          <FormField
            type="text"
            name="country"
            value={profileData.country}
            placeholder="Escribe el nombre de tu país"
            labelText="País"
            onChange={handleChange}
          /> */}
          <div>
            <Button
              text={t('Guardar_cambios')}
              color="#fff"
              backgroundColor="#324250"
              onClick={submitUserUpdate}
            />
          </div>
        </Form>
      </div>
      {openNewIdentityModal && (
        <NewIdentityModal
          openModal={openNewIdentityModal}
          setOpenModal={setOpenNewIdentityModal}
          refreshData={getIdentities}
          identity={identities.detail.id}
        />
      )}
    </Wrapper>
  );
};

export default Profile;
