import Main from '../../layouts/main';
import styled from 'styled-components';
import SendIcon from '@mui/icons-material/Send';
import FriendsListItem from './FriendsListItem';

const Wrapper = styled.div`
  display: flex;
  height: 100%;

  .friendsList {
    padding: 10px;
    width: 400px;
    background-color: var(--secondaryBackground);

    @media (max-width: 800px) {
      display: none;
    }
  }

  .chatBox {
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
    background-color: var(--primaryBackground);

    .messageBox {
      padding: 20px;
      .message {
        display: flex;
        width: 100%;

        div {
          background-color: var(--primary);
          color: #fff;
          border-radius: 5px;
          padding: 10px;
          margin: 20px;
          max-width: 40%;
        }
      }

      .userMessage {
        justify-content: right;

        div {
          background-color: var(--secondaryBackground);
          color: var(--primaryText);
        }
      }
    }

    .inputMessage {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      background-color: #293641;
      padding: 20px;

      input {
        width: 100%;
        margin-right: 20px;
        border-radius: 15px;
        padding: 5px;
      }
    }
  }
`;

const Chat = () => {
  return (
    <Main>
      <Wrapper>
        <div className="friendsList">
          <FriendsListItem />
          <FriendsListItem />
          <FriendsListItem />
          <FriendsListItem />
          <FriendsListItem />
          <FriendsListItem />
        </div>
        <div className="chatBox">
          <div className="messageBox">
            <div className="message userMessage">
              <div>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Architecto nostrum nisi sit et quaerat. Commodi.
                </p>
              </div>
            </div>
            <div className="message friendMessage">
              <div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut
                  sit blanditiis, eveniet magni rem odit unde quasi, quisquam
                  fugiat, ipsa qui consequatur aliquam possimus dolorem.
                </p>
              </div>
            </div>
          </div>
          <div className="inputMessage">
            <input type="text" name="" id="" />
            <SendIcon
              style={{ width: '25px', height: '25px', color: '#fff' }}
            />
          </div>
        </div>
      </Wrapper>
    </Main>
  );
};

export default Chat;
