import styled from 'styled-components';
import axiosInstance from '../../utils/axios';
import { useEffect, useState } from 'react';
import Results from '../../components/templates/results';
import Main from '../../layouts/main';
import ChallengeItem from './challengeItem';
import CancelledChallengeItem from './cancelledChallengeItem';
import CreatedChallengeItem from './createdChallengeItem';
import ReceivedChallengeItem from './receivedChallengeItem';
import FinishedChallengeItem from './finishedChallengeItem';
import NewsItem from '../../components/templates/news';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 1fr 300px;
  height: 100%;
  .main {
    width: 100%;
    padding: 20px;

    a {
      font-size: 12rem;
      color: var(--secondaryText);
    }

    .challengesBox {
      background-color: var(--secondaryBackground);
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 20px;
    }
  }

  .info {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Table = styled.div`
  width: 100%;
  border-collapse: collapse;

  tr {
    position: relative;
  }

  tr:hover {
    background-color: var(--primaryBackground);
    border-radius: 5px;
  }

  th,
  td {
    text-align: center;
    font-weight: 700;
    width: 20%;
    padding: 20px;
  }

  span {
    font-weight: 300;
  }
`;

const MyChallenges = () => {
  const { t } = useTranslation();
  const data = localStorage.getItem('user');
  const parseData = JSON.parse(data);
  const id = parseData?.data.id;
  const [eventsList, setEventsList] = useState([]);

  const getEventsList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}challenges/user_challenge_history/`
      );
      setEventsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  useEffect(() => {
    getEventsList();
  }, []);

  return (
    <Main>
      <Wrapper>
        <div className="main">
          <div className="challengesBox">
            <h3>
              {t('Mis_retos_activos')}
              <span>
                <a href="/"> {t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Mis_retos_activos_descripcion')}</p>
            <Table>
              {eventsList
                .filter((item) => {
                  return item.status == 'active';
                })
                .map((item) => {
                  return (
                    <ChallengeItem
                      challengeData={item}
                      refreshData={getEventsList}
                      key={item.id}
                    />
                  );
                })}
            </Table>
          </div>
          <div className="challengesBox">
            <h3>
              {t('Retos_recibidos')}{' '}
              <span>
                <a href="/">{t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Retos_recibidos_descripcion')}</p>
            {eventsList
              .filter((item) => {
                return item.status === 'pending';
              })
              .filter((item) => {
                return item.creator.id !== id;
              }).length === 0 ? (
              <p style={{ color: 'var(--secondaryText)', paddingTop: '20px' }}>
                {t('No_has_recibido_retos')}
              </p>
            ) : (
              <Table>
                {eventsList
                  .filter((item) => {
                    return item.status === 'pending';
                  })
                  .filter((item) => {
                    return item.creator.id !== id;
                  })
                  .map((item) => {
                    return (
                      <ReceivedChallengeItem
                        challengeData={item}
                        refreshData={getEventsList}
                        key={item.id}
                      />
                    );
                  })}
              </Table>
            )}
          </div>
          <div className="challengesBox">
            <h3>
              {t('Retos_creados')}{' '}
              <span>
                <a href="/">{t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Retos_creados_descripcion')}</p>
            <Table>
              {eventsList
                .filter((item) => {
                  return item.status === 'pending';
                })
                .filter((item) => {
                  return item.creator.id === id;
                })
                .map((item) => {
                  return (
                    <CreatedChallengeItem
                      challengeData={item}
                      refreshData={getEventsList}
                      key={item.id}
                    />
                  );
                })}
            </Table>
          </div>
          <div className="challengesBox">
            <h3>
              {t('Retos_cancelados')}{' '}
              <span>
                <a href="/">{t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Retos_cancelados_descripcion')}</p>
            {eventsList.filter((item) => {
              return item.status === 'cancelled';
            }).length === 0 ? (
              <p style={{ color: 'var(--secondaryText)', paddingTop: '20px' }}>
                {t('No_hay_retos_cancelados')}
              </p>
            ) : (
              <Table>
                {eventsList
                  .filter((item) => {
                    return item.status === 'cancelled';
                  })
                  .map((item) => {
                    return (
                      <CancelledChallengeItem
                        challengeData={item}
                        refreshData={getEventsList}
                        key={item.id}
                      />
                    );
                  })}
              </Table>
            )}
          </div>
          <div className="challengesBox">
            <h3>
              {t('Historial_retos')}{' '}
              <span>
                <a href="/">{t('Ver_mas')}</a>
              </span>
            </h3>
            <p>{t('Historial_retos_descripcion')}</p>
            {eventsList.filter((item) => {
              return item.status === 'finished';
            }).length === 0 ? (
              <p style={{ color: 'var(--secondaryText)', paddingTop: '20px' }}>
                {t('No_hay_finalizados')}
              </p>
            ) : (
              <Table>
                {eventsList
                  .filter((item) => {
                    return item.status === 'finished';
                  })
                  .map((item) => {
                    return (
                      <FinishedChallengeItem
                        challengeData={item}
                        refreshData={getEventsList}
                        key={item.id}
                      />
                    );
                  })}
              </Table>
            )}
          </div>
        </div>
        <div className="info">
          <Results />
          <NewsItem />
        </div>
      </Wrapper>
    </Main>
  );
};

export default MyChallenges;
