import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom/client';
import './i18n';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { ContextWrapper } from './contexts/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  /* Ofrece contexto sobre la autorizacion del usuario y su timezone */
  <ContextWrapper>
    <AuthProvider>
      <App />
      {/* Wrapper de las notificaciones toast de la aplicacion */}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={localStorage.getItem('theme')}
      />
    </AuthProvider>
  </ContextWrapper>
);
