import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Modal from '../../../components/templates/modal';
import ModalLayout from '../modal/ModalLayout';
import FormField from '../../atoms/FormField';
import Button from '../../atoms/button';
import axiosInstance from '../../../utils/axios';

const Wrapper = styled.div`
  margin: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 600px;
  background-color: var(--primaryBackground);

  .title {
    border-bottom: solid var(--accent) 5px;
    text-align: left;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    button {
      margin: 0;
    }

    p {
      color: var(--secondaryText);
    }
  }

  .body {
    padding: 20px;

    .league {
      font-weight: 700;
    }
  }

  input {
    max-width: unset;
  }

  input[type='file'] {
    background-color: transparent;
    box-shadow: unset;
  }
  .exampleBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: var(--primaryText);
    font-weight: 700;

    .iconExample {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }
  .message {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .bet {
    font-weight: 500;
    line-height: 26rem;

    span {
      color: var(--accent);
      font-weight: 700;
    }
  }
`;

const Dropdown = styled.div`
  padding-top: 20px;
  label {
    font-weight: 700;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondaryBackground);
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

const NewIdentityModal = ({
  openModal,
  setOpenModal,
  refreshData,
  identity,
}) => {
  const [newIdentity, setNewIdentity] = useState({});
  const [message, setMessage] = useState('');
  const ModalLayoutRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewIdentity({
      ...newIdentity,
      [name]: value,
    });
  };

  const createIdentities = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}auth/users/identities/`,
        formData
      );
      toast.success('Nueva identificación creada');
      setOpenModal(false);
    } catch (error) {
      toast.warn(error.response.data.detail);
    }
  };

  const editIdentities = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      const { data } = await axiosInstance.patch(
        `${process.env.REACT_APP_BASE_URL}auth/users/identities/`,
        formData
      );
      toast.success('Nueva identificación creada');
      setOpenModal(false);
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  return (
    <Modal
      isOpen={openModal}
      element={
        <ModalLayout
          ref={ModalLayoutRef}
          myOwnContainer
          setOpenModal={setOpenModal}
        >
          <Wrapper>
            <div className="title">
              <div>
                <h2>Agregar documento de identidad</h2>
              </div>
            </div>
            <div className="body">
              <form onSubmit={identity ? editIdentities : createIdentities}>
                <Dropdown>
                  <label htmlFor="document_type">Tipo de documento</label>
                  <select name="document_type" required onChange={handleChange}>
                    <option value="">Selecciona el tipo de documento</option>
                    <option value="1">INE</option>
                    <option value="2">'Pasaporte'</option>
                  </select>
                </Dropdown>
                <FormField
                  type="text"
                  name="document_number"
                  labelText="Numero del documento"
                  onChange={handleChange}
                />
                <FormField
                  type="file"
                  name="image_front"
                  labelText="Imagen frontal"
                  onChange={handleChange}
                />
                <FormField
                  type="file"
                  name="image_back"
                  labelText="Imagen trasera"
                  onChange={handleChange}
                />
                {message !== '' ? (
                  <div
                    className="message"
                    style={{
                      color:
                        message === 'Email de recuperación enviado'
                          ? 'green'
                          : 'var(--warning)',
                      backgroundColor: '#f1f1f1',
                      borderRadius: '5px',
                      padding: '5px',
                      fontWeight: '500',
                    }}
                  >
                    {message}
                  </div>
                ) : null}
                <Button
                  text={
                    identity
                      ? 'Editar identificacion'
                      : 'Agregar identificacion'
                  }
                  color="#fff"
                  backgroundColor="#39CB6B"
                />
              </form>
            </div>
          </Wrapper>
        </ModalLayout>
      }
    />
  );
};

export default NewIdentityModal;
