import { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import styled from 'styled-components';
import Results from '../../components/templates/results';
import NewsItem from '../../components/templates/news';
import Main from '../../layouts/main';
import FinishedChallengesItem from './finishedChallengesItem';
import ChallengeSkeleton from '../../components/templates/challengeSkeleton';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import FormField from '../../components/atoms/FormField';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../components/atoms/loader';
import Button from '../../components/atoms/button';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--primaryBackground);
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  height: 100%;
  .main {
    width: 100%;
    padding: 20px;
  }

  .info_sports {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1380px) {
      display: none;
    }
  }

  .filterSports {
    background-color: #324250;
    width: 300px;
    padding: 20px;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  @media (max-width: 1380px) {
    grid-template-columns: 300px 1fr;
  }

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

const Table = styled.div`
  width: 100%;
`;

const Icon = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const IconLeagues = styled(Icon)`
  margin: 15px;
`;

const Sport = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: #394a58;
    border-radius: 5px;
    cursor: pointer;
  }

  p {
    color: #fff;
  }
`;

const FilterLeagues = styled.div`
  display: flex;
  background-color: var(--secondaryBackground);\
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  overflow: auto;

  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }

  .iconsBox {
    display: flex;
  }
`;

const FilterSport = styled(FilterLeagues)`
  display: none;
  width: 100%;

  @media (max-width: 1100px) {
    display: flex;
    width: calc(100vw - 40px);
  }
`;

const SearchBar = styled.div`
  & > div {
    margin: 0 0 10px 0;
  }
  label {
    font-weight: 500;
    color: #fff;
  }
`;

const Challenges = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [withResults, setWithResults] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [sportsList, setSportsList] = useState([]);
  const [leaguesList, setLeaguesList] = useState([]);
  const [finishedEventsList, setFinishedEventsList] = useState({
    data: [],
    detail: {},
  });
  const [sportFilter, setSportFilter] = useState([]);
  const [leagueFilter, setLeagueFilter] = useState([]);
  const [search, setSearch] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [searchPast7Days, setSearchPast7Days] = useState(false);
  const [searchPast30Days, setSearchPast30Days] = useState(false);

  const sumarDias = (fecha, dias) => {
    // Agregar dias en negativo para restar
    fecha.setDate(fecha.getDate() + dias);
    return fecha.toISOString().slice(0, 10);
  };

  const URL = `${
    process.env.REACT_APP_BASE_URL
  }events/?page=${pageCount}&per_page=50&status=finished&status=finished_pending_results${
    inputSearch != '' ? `&search=${inputSearch.replace(' ', ',')}` : ''
  }${
    searchPast30Days
      ? `&start_at=${sumarDias(
          new Date(),
          -30
        )}T00:00:00.00Z&end_at=${new Date()
          .toISOString()
          .slice(0, 10)}T23:59:59.00Z`
      : ''
  }${
    searchPast7Days
      ? `&start_at=${sumarDias(new Date(), -7)}T00:00:00.00Z&end_at=${new Date()
          .toISOString()
          .slice(0, 10)}T23:59:59.00Z`
      : ''
  }&ordering=-start_datetime`;

  const getSportsList = async (e) => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}sports/?status=1/`
      );
      setSportsList(data);
    } catch (error) {
      console.log(error.response?.status);
    }
  };

  const getLeaguesList = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}leagues/?status=1/`
      );
      setLeaguesList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  };

  const getFinishedEventsList = async (e) => {
    setLoading(true);
    setPageCount(1);
    try {
      const { data } = await axiosInstance.get(URL);
      setFinishedEventsList(data);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  const fetchData = async (nextURL) => {
    try {
      const { data } = await axiosInstance.get(nextURL);
      setFinishedEventsList({
        ...data,
        data: [...finishedEventsList.data, ...data.data],
      });
      setLoading(false);
      // setPageCount(pageCount + 1);
    } catch (error) {
      console.log(error.response.data.detail.name);
      setLoading(false);
    }
  };

  /* const Search = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${
          process.env.REACT_APP_BASE_URL
        }events/?page=1&per_page=50&search=${inputSearch.replace(
          ' ',
          ','
        )}&status=finished&status=finished_pending_results&ordering=-start_datetime`
      );
      setFinishedEventsList(data);
    } catch (error) {
      console.log(error.response.data.detail.name);
    }
  }; */

  useEffect(() => {
    getSportsList();
    getLeaguesList();
    getFinishedEventsList();
  }, [searchPast30Days, searchPast7Days, inputSearch]);

  return (
    <Main>
      <Wrapper>
        <div className="filterSports">
          <SearchBar>
            <FormField
              type="search"
              name="name"
              labelText={t('Buscar_deporte')}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </SearchBar>
          <Sport
            onClick={(e) => {
              setSportFilter('');
              setSearch('');
            }}
          >
            <Icon>
              <SelectAllIcon
                style={{ width: '30px', height: '30px', color: '#fff' }}
              />
            </Icon>
            <p>{t('Todos')}</p>
          </Sport>

          {sportsList
            .filter((item) => {
              return item.name.toLowerCase().includes(search);
            })
            .sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .map((sport) => {
              return (
                <Sport
                  onClick={(e) => {
                    setSportFilter(e.target.innerText);
                  }}
                  style={{
                    backgroundColor:
                      sportFilter === sport.name ? '#293641' : null,
                    borderRadius: '5px',
                  }}
                  key={sport.id}
                >
                  <Icon>
                    <img src={sport.image} alt="" />
                  </Icon>
                  <p>{sport.name}</p>
                </Sport>
              );
            })}
        </div>
        <div className="main">
          <FilterSport>
            <div className="iconsBox">
              <IconLeagues
                onClick={(e) => {
                  setSportFilter('');
                  setLeagueFilter('');
                }}
              >
                <SelectAllIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    color: 'var(--primaryText)',
                  }}
                />
              </IconLeagues>
              {sportsList
                .sort((a, b) => {
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                })
                .map((sport) => {
                  return (
                    <IconLeagues
                      key={sport.id}
                      onClick={() => setSportFilter(`${sport.name}`)}
                    >
                      <img src={sport.image} alt={sport.name} />
                      <span
                        style={{
                          backgroundColor:
                            sportFilter === sport.name
                              ? 'var(--primaryText)'
                              : null,
                          width: '30px',
                          borderRadius: '0 0 5px 5px',
                          height: '4px',
                          padding: '2px',
                          position: 'absolute',
                          bottom: '-10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      ></span>
                    </IconLeagues>
                  );
                })}
            </div>
          </FilterSport>
          <FilterLeagues>
            <div className="iconsBox">
              <IconLeagues
                onClick={(e) => {
                  setLeagueFilter('');
                }}
              >
                <SelectAllIcon
                  style={{
                    width: '30px',
                    height: '30px',
                    color: 'var(--primaryText)',
                  }}
                />
              </IconLeagues>
              {leaguesList
                .filter((item) => {
                  return item.sport.name.includes(sportFilter);
                })
                .map((item) => {
                  return (
                    <IconLeagues
                      key={item.id}
                      onClick={() => setLeagueFilter(`${item.name}`)}
                    >
                      <img src={item.image} alt={item.name} />
                      <span
                        style={{
                          backgroundColor:
                            leagueFilter === item.name
                              ? 'var(--primaryText)'
                              : null,
                          width: '30px',
                          borderRadius: '0 0 5px 5px',
                          height: '4px',
                          padding: '2px',
                          position: 'absolute',
                          bottom: '-10px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                        }}
                      ></span>
                    </IconLeagues>
                  );
                })}
            </div>
          </FilterLeagues>
          <FormField
            type="search"
            name="inputSearch"
            labelText={t('Buscar_evento')}
            value={inputSearch}
            onChange={(e) => {
              setInputSearch(e.target.value);
            }}
          />

          <h3>{t('Eventos_finalizados')}</h3>
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <Button
              text={t('Todos')}
              color={!withResults ? '#293641' : 'var(--secondaryText)'}
              backgroundColor={!withResults ? 'var(--secondary)' : '#fadc415c'}
              onClick={(e) => {
                e.preventDefault();
                setWithResults(false);
              }}
            />
            <Button
              text={t('Con_resultados')}
              color={withResults ? '#293641' : 'var(--secondaryText)'}
              backgroundColor={withResults ? 'var(--secondary)' : '#fadc415c'}
              onClick={(e) => {
                e.preventDefault();
                setWithResults(true);
              }}
            />
            <button
              style={{
                backgroundColor: 'var(--primary)',
                margin: '0 5px',
                width: '2px',
                height: '26px',
                borderRadius: '5px',
              }}
            ></button>
            <Button
              text={`${t('Proximos')} 7 ${t('Dias')}`}
              color={searchPast7Days ? '#293641' : 'var(--secondaryText)'}
              backgroundColor={
                searchPast7Days ? 'var(--secondary)' : '#fadc415c'
              }
              onClick={(e) => {
                e.preventDefault();
                setSearchPast7Days(!searchPast7Days);
                setSearchPast30Days(false);
              }}
            />
            <Button
              text={`${t('Proximos')} 30 ${t('Dias')}`}
              color={searchPast30Days ? '#293641' : 'var(--secondaryText)'}
              backgroundColor={
                searchPast30Days ? 'var(--secondary)' : '#fadc415c'
              }
              onClick={(e) => {
                e.preventDefault();
                setSearchPast30Days(!searchPast30Days);
                setSearchPast7Days(false);
              }}
            />
          </div>
          <Table>
            {loading && (
              <div>
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
                <ChallengeSkeleton />
              </div>
            )}
            <InfiniteScroll
              dataLength={finishedEventsList.data.length} //This is important field to render the next data
              next={() => fetchData(finishedEventsList.detail.next_page)}
              hasMore={finishedEventsList.detail.has_more}
              loader={<Loader />}
              endMessage={
                <p
                  style={{
                    textAlign: 'center',
                    color: 'var(--secondaryText)',
                    marginTop: '10px',
                  }}
                >
                  {t('No_hay_retos_finalizados')}
                </p>
              }
            >
              {finishedEventsList.data
                .filter((event) => {
                  return event.sport.name.includes(sportFilter);
                })
                .filter((item) => {
                  return item.league.name.includes(leagueFilter);
                })
                .filter((event) => {
                  return withResults ? event.winner_team !== '-' : event;
                })
                .map((eventData) => {
                  return (
                    <FinishedChallengesItem
                      eventData={eventData}
                      key={eventData.id}
                      refreshData={getFinishedEventsList}
                    />
                  );
                })}
            </InfiniteScroll>
          </Table>
        </div>
        <div className="info_sports">
          <Results />
          <NewsItem />
        </div>
      </Wrapper>
    </Main>
  );
};

export default Challenges;
