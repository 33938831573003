import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../components/atoms/button';
import { styled as muistyled } from '@mui/material/styles';
import GoChallengeLogo from '../assets/images/go_challenge_logo.png';
import KicksSoccer from '../assets/images/kicks-soccer.jpg';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import Loader from '../components/atoms/loader';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;

  & > div {
    width: 50%;

    @media (max-width: 800px) {
      width: 100%;
    }
  }
`;

const WrapperLeft = styled.div`
  padding: 20px;
  height: 100vh;
  display: grid;
  grid-template-rows: 100px 1fr auto;
  overflow: auto;
  position: relative;
`;

const WrapperLogo = styled.div`
  width: 200px;

  img {
    width: 100%;
  }
`;

const WrapperForm = styled.div`
  display: flex;
  margin: 50px 0;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 300px;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    margin: 10px 0;
    color: var(--primaryText);
  }

  .form_buttons {
    text-align: center;
  }

  span {
    color: var(--accent);
    font-weight: 700;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  label {
    font-weight: 700;
  }

  input {
    height: 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding-left: 10px;
  }

  input:focus {
    outline: 2px solid #98a0a7;
  }
`;

const WrapperConditions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapperRight = styled.div`
  padding: 40px;

  & > div {
    background-color: #394a58;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;
    border-radius: 5px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .wrapper-bg {
      width: 100%;
      height: 100%;
      background-color: #394a58c7;
      position: absolute;
    }
  }

  .info_welcome {
    font-weight: 700;
    font-size: 40rem;
    color: #fadc41;
  }

  span {
    background-color: #e37222;
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    float: left;
    position: relative;
    top: -5px;
    margin-right: 10px;
  }

  p {
    font-size: 20rem;
    font-weight: 300;
    color: #fff;
    margin: 30px 0;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapperInfo = styled.div`
  position: relative;
`;

const ShowPass = styled.div`
  text-align: right;
  margin-bottom: 10px;
  label {
    color: var(--secondaryText);
  }
`;

const FastCheckBox = styled.div`
  display: flex;
  justify-content: center;
  color: 'var(--primary)';
`;

const PasswordRequirements = styled.div`
  background-color: #dedede;
  padding: 20px;
  border-radius: 5px;

  p {
    margin-top: 0;
  }

  li {
    list-style: disc;
    margin-left: 20px;
  }
`;

const GCRegistrySwitch = muistyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .css-jsexje-MuiSwitch-thumb': {
    backgroundColor: '#e37222',
  },
  '& .Mui-checked': {
    color: '#e37222',
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#e37222',
  },
}));

const Register = () => {
  const { t } = useTranslation();
  var ln = navigator.language || navigator.userLanguage;
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false); //Select if the registry method is quick or not
  const [errorMessage, setErrorMessage] = useState(''); // Show a message if the pass not complete the format
  const [passwordShown, setPasswordShown] = useState(false); // Show or hide the password
  const [signUpData, setSignUpData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  console.log(ln);

  //Regex for validate the password format
  const passwordValidator =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&()*+,-./:;<=>?@_{|}])[A-Za-z\d!#$%&()*+,-./:;<=>?@_{|}]{8,20}$/gm;

  // check if the password is valid
  const passIsValid = passwordValidator.test(signUpData.password);

  const navigate = useNavigate();

  // Function to create a random username and a random password for quick registry
  const BuildRandom = () => {
    let a = '',
      b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890.+-_';

    for (let ma = 0; ma < 20; ma++) {
      a += b[Math.floor(Math.random() * b.length)];
    }

    return a;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignUpData({
      ...signUpData,
      [name]: value,
    });
  };

  // Select if the registry method is quick or not
  const handleCheck = (event) => {
    setChecked(event.target.checked);
    setErrorMessage('');
  };

  const togglePassword = (e) => {
    e.target.checked ? setPasswordShown(true) : setPasswordShown(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const offset = new Date().getTimezoneOffset();
    const formatted = -(offset / 60);
    setIsLoading(true);

    if (passIsValid || checked) {
      try {
        let authresult = await axios.post(
          `${process.env.REACT_APP_BASE_URL}auth/users/create/`,
          {
            username: checked ? BuildRandom() : signUpData.username,
            email: signUpData.email,
            invited_by: signUpData.invited_by,
            password: checked ? BuildRandom() : signUpData.password,
          }
        );
        localStorage.setItem('user', JSON.stringify(authresult));
        localStorage.setItem(
          'GMT',
          `Etc/GMT${formatted < 0 ? '+' : null}${-formatted}`
        );
        setIsLoading(false);
        i18next.changeLanguage(ln, (err, t) => {
          if (err) return console.log('something went wrong loading', err);
          t('key');
          localStorage.setItem('userLanguage', ln);
        });
        navigate('/');
      } catch (error) {
        setErrorMessage(
          error.response.data.email[0]
            ? error.response.data.email[0]
            : 'Ocurrio algo, intenta de nuevo'
        );
        setIsLoading(false);
      }
    } else {
      setErrorMessage('Tu contraseña no es valida');
    }
  };

  return (
    <Wrapper>
      <WrapperLeft>
        <div>
          <WrapperLogo>
            <img src={GoChallengeLogo} alt="Logo de Go Challenge" />
          </WrapperLogo>
        </div>
        <WrapperForm>
          <div>
            <h2>{t('Registro')}</h2>
            <FastCheckBox>
              <p>{t('Registro_normal')}</p>
              <GCRegistrySwitch
                checked={checked}
                onChange={handleCheck}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p>{t('Registro_rapido')}</p>
            </FastCheckBox>
            {checked ? (
              <p>
                <span>{t('Atencion')}: </span>
                {t('Atencion_texto')}
              </p>
            ) : null}
            <form className="form" onSubmit={handleSubmit}>
              <FormField>
                <label htmlFor="invited_by">{t('Codigo_invitado')}</label>
                <input
                  type="text"
                  name="invited_by"
                  value={signUpData.invited_by}
                  placeholder={t('Codigo_invitado_placeholder')}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <label htmlFor="subtitle">{t('Correo')} *</label>
                <input
                  type="email"
                  name="email"
                  value={signUpData.email}
                  placeholder={t('Escribe_tu_correo')}
                  autoComplete="off"
                  onChange={handleChange}
                  required
                />
              </FormField>
              {checked ? null : (
                <>
                  <FormField>
                    <label htmlFor="nickname">{t('Apodo')} *</label>
                    <input
                      type="text"
                      name="username"
                      value={signUpData.username}
                      placeholder={t('Apodo_placeholder')}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />
                  </FormField>
                  <FormField>
                    <label htmlFor="subtitle">{t('Contrasena')} *</label>
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="password"
                      value={signUpData.password}
                      placeholder={t('Escribe_contrasena')}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />
                  </FormField>
                  {passIsValid || signUpData.password.length < 1 ? null : (
                    <PasswordRequirements>
                      <p>La contraseña debe tener al menos:</p>
                      <ul>
                        <li
                          style={{
                            color:
                              signUpData.password.length >= 8 ? 'green' : 'red',
                          }}
                        >
                          8 caracteres
                        </li>
                        <li
                          style={{
                            color: signUpData.password.match(/[A-Z]/g)
                              ? 'green'
                              : 'red',
                          }}
                        >
                          una letra mayuscula
                        </li>
                        <li
                          style={{
                            color: signUpData.password.match(/[a-z]/g)
                              ? 'green'
                              : 'red',
                          }}
                        >
                          una letra minuscula
                        </li>
                        <li
                          style={{
                            color: signUpData.password.match(/[\d]/g)
                              ? 'green'
                              : 'red',
                          }}
                        >
                          un numero
                        </li>
                        <li
                          style={{
                            color: signUpData.password.match(
                              /[!#$%&()*+,-./:;<=>?@_{|}]/g
                            )
                              ? 'green'
                              : 'red',
                          }}
                        >
                          un caracter especial ({'!#$%&*+,-./:;?@_=<>{|}()'})
                        </li>
                      </ul>
                    </PasswordRequirements>
                  )}
                  <FormField>
                    <label htmlFor="confirmPassword">
                      {t('Confirmar_contrasena')} *
                    </label>
                    <input
                      type={passwordShown ? 'text' : 'password'}
                      name="confirmPassword"
                      value={signUpData.confirmPassword}
                      placeholder={t('Escribe_contrasena')}
                      autoComplete="off"
                      onChange={handleChange}
                      required
                    />
                  </FormField>
                  <ShowPass>
                    <input
                      type="checkbox"
                      name="show"
                      onClick={togglePassword}
                    />
                    <label htmlFor="show">{t('Mostrar_contrasena')}</label>
                  </ShowPass>
                </>
              )}

              {errorMessage ? (
                <p
                  style={{
                    backgroundColor: 'var(--warning)',
                    borderRadius: '5px',
                    color: '#fff',
                    fontWeight: '700',
                  }}
                >
                  {errorMessage}
                </p>
              ) : null}
              <p>
                {t('Ya_tienes_cuenta')} <a href="/login"> {t('Entra_aqui')}</a>
              </p>
              <div className="form_buttons">
                <Button
                  backgroundColor={isLoading ? '#bfbfbf' : '#394a58'}
                  color="#fff"
                  text={t('Registrarme')}
                  disabled={isLoading}
                />
                {isLoading && <Loader />}
              </div>
            </form>
            {/* <p>O regístrate con...</p>
            <WrapperSocialRegistry>
              <Button>Facebook</Button>
              <Button>Google</Button>
            </WrapperSocialRegistry> */}
          </div>
        </WrapperForm>
        <WrapperConditions>
          <p>
            {t('Mensaje_de_registro')}{' '}
            <a href="/">{t('Terminos_y_condiciones')}</a> {t('Y_la')}{' '}
            <a href="/">{t('Politica_de_privacidad')}</a> {t('De_la_compania')}.
          </p>
        </WrapperConditions>
      </WrapperLeft>
      <WrapperRight>
        <div
          style={{
            background: `url(${KicksSoccer})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
          }}
        >
          <div className="wrapper-bg"></div>
          <WrapperInfo>
            <p className="info_welcome">{t('Bienvenido_GoChallenge')}</p>
            <p>{t('Bienvenida_mensaje')}</p>
            <p>{t('Pasos_texto')}</p>
            <p>
              <span>1</span> {t('Paso_1')}
            </p>
            <p>
              <span>2</span> {t('Paso_2')}
            </p>
            <p>
              <span>3</span> {t('Paso_3')}
            </p>
          </WrapperInfo>
        </div>
      </WrapperRight>
    </Wrapper>
  );
};

export default Register;
