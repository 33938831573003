import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .circle {
    background-color: var(--accent);
    padding: 5px;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &:hover {
    background-color: #394a58;
    border-radius: 5px;
    cursor: pointer;
  }

  .info {
    display: flex;
    gap: 10px;
    align-items: center;

    p {
      color: var(--primaryText);
      font-weight: 500;
    }
  }
`;

const Icon = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const FriendsList = () => {
  return (
    <Wrapper>
      <div className="info">
        <Icon>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFr_8_OG0lsB0SmFgR-x1BfvC81aXEUfxvVBS94sEPwzf-YBx7LkHfabwa_upjApwXBDI&usqp=CAU"
            alt=""
          />
        </Icon>
        <p>Pancho Sevilla</p>
      </div>
      <div className="check">
        <div className="circle">2</div>
      </div>
    </Wrapper>
  );
};

export default FriendsList;
