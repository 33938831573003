import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import NavbarComponent from 'components/templates/navbar';
import SearchbarComponent from 'components/templates/searchbar';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 100px 60px 1fr 40px;
`;

const Navbar = styled.div``;

const Searchbar = styled.div`
  background-color: #293641;
`;

const MainContent = styled.div`
  height: 100%;
`;

const Footer = styled.div`
  background-color: #fadc41;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #293641;
`;

const Main = (props) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Navbar>
        <NavbarComponent />
      </Navbar>
      <Searchbar>
        <SearchbarComponent />
      </Searchbar>
      <MainContent>{props.children}</MainContent>
      <Footer>{t('Copyright')}</Footer>
    </Wrapper>
  );
};

export default Main;
