import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonComponent = styled.button`
  padding: 5px 20px;
  border-radius: 3px;
  font-weight: 700;

  &:hover {
    filter: opacity(50%);
  }
`;

const Button = ({
  backgroundColor,
  color,
  border,
  onClick,
  disabled,
  text,
}) => {
  return (
    <ButtonComponent
      style={{
        backgroundColor: backgroundColor,
        color: color,
        border: border,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </ButtonComponent>
  );
};

Button.propTypess = {
  backgroundColor: PropTypes.any,
  color: PropTypes.any,
  border: PropTypes.any,
  any: PropTypes.any,
  disabled: PropTypes.any,
  text: PropTypes.any,
};

export default Button;
