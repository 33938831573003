import { useEffect, useRef, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import GoChallengeLogo from '../../assets/images/go_challenge_logo.png';
import ProfileImage from '../../assets/images/profile.jpg';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { styled as muistyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuIcon from '@mui/icons-material/Menu';
import axiosInstance from '../../utils/axios';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .active {
    color: var(--accent);
  }

  .hide {
    display: none;
  }

  .site-navigation li {
    display: flex;
    position: relative;
  }

  .site-navigation li:hover {
    @include transition(background, 0.2s);
  }

  .dropd {
    display: block;
    background: var(--primaryBackground);
    border-radius: 5px;
    border: 1px grey solid;
    visibility: hidden;
    float: left;
    min-width: 150px;
    position: absolute;
    transition: visibility 0.65s ease-in;
    margin-top: 12px;
    left: 0;
    bottom: -65px;
    z-index: 999;
  }

  .site-navigation ul li:hover > .dropd,
  .site-navigation ul li .dropd:hover {
    visibility: visible;
  }

  .site-navigation ul li .dropd .sub-menu {
    clear: both;
    padding: 5px 0 5px 18px;
    width: 100%;
  }

  .site-navigation ul li .dropd .sub-menu:hover {
    background: var(--secondary);
  }
`;

const WrapperLogo = styled.div`
  width: 150px;
  margin: 0 20px;

  img {
    width: 100%;
  }
`;

const WrapperMenu = styled.div`
  ul {
    display: flex;
    gap: 20px;
  }

  li:last-child {
    color: var(--warning);
  }

  li {
    display: flex;
    align-items: center;
    gap: 5px;

    a {
      color: var(--primaryText);
    }
  }

  .roundNotification {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--accent);
    text-align: center;
    line-height: 25px;
    color: #fff;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapperProfile = styled.div`
  display: flex;
  margin-right: 20px;
  gap: 20px;

  .profile_notificaciones {
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
      display: none;
    }
  }

  .roundNotification {
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: var(--accent);
    text-align: center;
    line-height: 25px;
    color: #fff;
  }

  .profile_info {
    text-align: right;
    position: relative;

    .userData_desktop {
      p {
        font-weight: 500;
      }
      @media (max-width: 800px) {
        display: none;
      }
    }

    .menu_mobile {
      @media (min-width: 800px) {
        display: none;
      }
    }

    .hide {
      display: none;
    }

    .dropdown_menu {
      position: absolute;
      z-index: 5;
      background-color: #fff;
      box-shadow: 4px 4px 4px -4px var(--primaryText);
      border-radius: 5px;
      right: 0;
      top: 70px;
      padding: 10px;
      border: solid 1px var(--primary);

      li {
        padding: 5px;

        a {
          color: var(--primary);
          font-weight: 500;
        }
      }

      li:hover {
        background-color: #f1f1f1;
        border-radius: 5px;
      }

      li:last-child button {
        color: var(--warning);
        font-weight: 700;
      }
    }

    p {
      font-weight: 700;

      span {
        color: var(--accent);
        font-weight: 300;
      }
    }

    div {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  .profile_picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const FastCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: 'var(--primary)';
`;

const MobileMenu = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--primaryBackground);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  span {
    color: var(--accent);
  }

  .menu_mobile_header {
    display: flex;
    justify-content: space-between;
    background-color: var(--secondaryBackground);
    padding: 20px;

    p {
      color: var(--secondaryText);
      font-weight: 500;
    }
  }

  ul {
    width: 100%;
    padding: 20px;
    padding-top: 5px;

    li {
      font-size: 22rem;
      margin: 20px;
      text-align: center;

      a {
        font-weight: 500;
      }
    }
  }

  .logout {
    background-color: var(--warning);
    color: #fff;
    padding: 10px;
    font-weight: 700;
    border-radius: 5px;
    line-height: 16rem;
    font-size: 16rem;
    width: 100%;
  }

  .closeMenu {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Dropdown = styled.div`
  flex-direction: column;
  padding-bottom: 10px;

  label {
    font-weight: 700;
    color: #293641;
  }

  select {
    height: 30px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 4px 4px -4px var(--shadow);
    border: none;
    font-size: 13rem;
    padding: 0 10px;
    margin-top: 5px;
    max-width: 400px;
  }
`;

/* Estilos para switch de cambio de tema */
const GCRegistrySwitch = muistyled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '& .css-jsexje-MuiSwitch-thumb': {
    backgroundColor: '#e37222',
  },
  '& .Mui-checked': {
    color: '#e37222',
  },
  '& .Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#e37222',
  },
}));

const Navbar = () => {
  const { t } = useTranslation();
  const theme =
    localStorage.getItem(
      'theme'
    ); /* Obtener tema seleccionado del localStorage */
  const context = useContext(AppContext);
  const [checked, setChecked] = useState(theme === 'dark' ? true : false); // Estado para almacenar la seleccion del tema elegido
  const [userData, setUserData] = useState(''); // Almacenar datos del usuario como nombre, GCC
  const dropdownMenuRef = useRef(null); // Referencia para el menu de opciones del usuario en vista desktop
  const menuMobileRef = useRef(null); // Referencia para el menu en vista movil

  const getUserData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}auth/users/current/`
      );
      setUserData(data);
      context.setStore({
        ...context.store,
        preferences: data.preferences,
      });
    } catch (error) {
      // toast.success('Hubo un error aceptando el reto');
      console.log(error.response.data.detail.name);
    }
  };

  const navigate = useNavigate();

  // Abrir o cerrar menu de opciones de usuario en vista desktop
  const toggleMenu = () => {
    dropdownMenuRef.current.classList.toggle('hide');
  };

  // Abrir o cerrar menu mobile
  const toggleMobileMenu = () => {
    menuMobileRef.current.classList.toggle('hide');
  };

  const handleCheck = (event) => {
    if (event.target.checked) {
      document.documentElement.setAttribute('data-theme', 'dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      localStorage.setItem('theme', 'light');
    }
    setChecked(event.target.checked);
  };

  const logout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  // Conjunto de zonas horarias para que el usuario seleccione
  const timezones = [
    {
      zone: '(UTC-12:00) Baker (Island)',
      value: '+12',
    },
    { zone: '(UTC-11:00) Midway Islands, Samoa', value: '+11' },
    {
      zone: '(UTC-10:00) Tahiti, Hawaii',
      value: '+10',
    },
    {
      zone: '(UTC-09:00) Alaska, Anchorage',
      value: '+9',
    },
    { zone: '(UTC-08:00) Vancouver, Los Angeles', value: '+8' },
    {
      zone: '(UTC-07:00) Mazatlán, Denver',
      value: '+7',
    },
    {
      zone: '(UTC-06:00) Mexico City, Monterrey',
      value: '+6',
    },
    {
      zone: '(UTC-05:00) New York, Bogotá',
      value: '+5',
    },
    {
      zone: '(UTC-04:00) La Paz, Venezuela',
      value: '+4',
    },
    {
      zone: '(UTC-03:00) Brasília, Buenos Aires',
      value: '+3',
    },
    { zone: '(UTC-02:00) South Georgia', value: '+2' },
    { zone: '(UTC-01:00) Cape Verde, Azores', value: '+1' },
    {
      zone: '(UTC+00:00) Lisbon, London',
      value: '+0',
    },
    {
      zone: '(UTC+01:00) Berlin, Madrid, Paris',
      value: '-1',
    },
    {
      zone: '(UTC+02:00) Egypt, Jerusalem',
      value: '-2',
    },
    {
      zone: '(UTC+03:00) Moscow, Qatar',
      value: '-3',
    },
    {
      zone: '(UTC+04:00) Armenia, Oman',
      value: '-4',
    },
    {
      zone: '(UTC+05:00) Maldives, Turkmenistan',
      value: '-5',
    },
    {
      zone: '(UTC+06:00) Bangladesh, Kazakhstan',
      value: '-6',
    },
    {
      zone: '(UTC+07:00) Bangkok, Jakarta',
      value: '-7',
    },
    {
      zone: '(UTC+08:00) Hong Kong, China',
      value: '-8',
    },
    {
      zone: '(UTC+09:00) North Korea, Japan',
      value: '-9',
    },
    {
      zone: '(UTC+10:00) Vladivostok, Guam',
      value: '-10',
    },
    {
      zone: '(UTC+11:00) Canberra, Sydney',
      value: '-11',
    },
    {
      zone: '(UTC+12:00) Norfolk Island, Marshall Islands',
      value: '-12',
    },
  ];

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Wrapper>
      <WrapperLogo>
        <a href="/">
          <img src={GoChallengeLogo} alt="" />
        </a>
      </WrapperLogo>
      <WrapperMenu className="site-navigation">
        <ul className="menu">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                'list-group-item' + (isActive ? ' active' : '')
              }
            >
              {t('Dashboard')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/eventos"
              className={({ isActive }) =>
                'list-group-item' + (isActive ? ' active' : '')
              }
            >
              {t('Eventos')}
            </NavLink>
            {/* <div className="dropd">
              <div className="sub-menu">
                <a href="/eventos/activos">Eventos activos</a>
              </div>
              <div className="sub-menu">
                <a href="/eventos/finalizados">Eventos finalizados</a>
              </div>
            </div> */}
          </li>
          {/* <li>
            <a href="/rewards">Rewards</a>
          </li> */}
          <li>
            <NavLink
              to="/mis-retos"
              className={({ isActive }) =>
                'list-group-item' + (isActive ? ' active' : '')
              }
            >
              {t('Mis_retos')}
            </NavLink>
          </li>
          {/* <li>
            <a href="/chat">Chat</a>{' '}
            <span className="roundNotification">5</span>
          </li> */}
        </ul>
      </WrapperMenu>
      <div style={{ flexGrow: '1' }}></div>
      <WrapperProfile>
        {/* <div className="profile_notificaciones">
          <NotificationsActiveIcon
            style={{
              width: '30px',
              height: '30px',
              color: 'var(--primaryText)',
            }}
          />
          <span className="roundNotification">4</span>
        </div> */}
        <div className="profile_info">
          <div className="userData_desktop">
            <p>
              {t('A_jugar')}{' '}
              <span style={{ fontWeight: '700' }}>
                {userData.first_name || userData.username}!!!
              </span>
              <br />
              <span style={{ color: 'var(--secondaryText)' }}>
                {t('Saldo')}:
              </span>{' '}
              <span>{userData.e_wallet} GCC</span>
            </p>
            <div className="profile_picture" onClick={toggleMenu}>
              <img
                src={userData.avatar ? userData.avatar : ProfileImage}
                alt=""
              />
            </div>
          </div>
          <div className="menu_mobile" onClick={toggleMobileMenu}>
            <MenuIcon
              style={{
                width: '40px',
                height: '40px',
                color: 'var(--primaryText)',
              }}
            />
          </div>
          <div className="dropdown_menu hide" ref={dropdownMenuRef}>
            <ul>
              <div style={{ flexDirection: 'column', paddingBottom: '10px' }}>
                <label
                  style={{
                    fontWeight: '700',
                    color: '#293641',
                  }}
                >
                  Idioma
                </label>
                <li>
                  <button
                    onClick={() => {
                      i18next.changeLanguage('en', (err, t) => {
                        if (err)
                          return console.log(
                            'something went wrong loading',
                            err
                          );
                        t('key');
                        localStorage.setItem('userLanguage', 'en');
                      });
                    }}
                    style={{
                      color:
                        localStorage.getItem('userLanguage') === 'en'
                          ? '#293641'
                          : '#d8e2e9',
                    }}
                  >
                    EN
                  </button>
                  {' | '}
                  <button
                    onClick={() => {
                      i18next.changeLanguage('es', (err, t) => {
                        if (err)
                          return console.log(
                            'something went wrong loading',
                            err
                          );
                        t('key');
                        localStorage.setItem('userLanguage', 'es');
                      });
                    }}
                    style={{
                      color:
                        localStorage.getItem('userLanguage') === 'es'
                          ? '#293641'
                          : '#d8e2e9',
                    }}
                  >
                    ES
                  </button>
                </li>
              </div>
              <li>
                <Dropdown>
                  <label htmlFor="timezone">{t('Tu_zona_horaria')}</label>
                  <select
                    name="timezone"
                    required
                    value={localStorage.getItem('GMT')}
                    onChange={(e) => {
                      localStorage.setItem('GMT', e.target.value);
                      context.setStore({
                        ...context.store,
                        timezone: e.target.value,
                      });
                      // window.location.reload(false);
                    }}
                    style={{ width: '200px' }}
                  >
                    <option value="">{t('Selecciona_zona_horaria')}</option>
                    {timezones.map((zone) => {
                      return (
                        <option
                          value={`Etc/GMT${zone.value}`}
                          key={zone.value}
                          style={{ width: '200px' }}
                        >
                          {zone.zone}
                        </option>
                      );
                    })}
                  </select>
                </Dropdown>
              </li>
              <li>
                <FastCheckBox>
                  <p>
                    <LightModeIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        color: 'var(--primary)',
                      }}
                    />
                  </p>
                  <GCRegistrySwitch
                    checked={checked}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <p>
                    <DarkModeIcon
                      style={{
                        width: '20px',
                        height: '20px',
                        color: 'var(--primary)',
                      }}
                    />
                  </p>
                </FastCheckBox>
              </li>
              <hr />
              <li>
                <a href="/account/profile">{t('Perfil')}</a>
              </li>
              <li>
                <a href="/account/sport-preferences">
                  {t('Preferencias_deportivas')}
                </a>
              </li>
              {/* <li>
                <a href="/account/informacion-bancaria">Información bancaria</a>
              </li>
              <li>
                <a href="/account/balance-credito">Balance de crédito</a>
              </li> */}
              <li>
                <a href="/account/amigos">{t('Amigos')}</a>
              </li>
              <li>
                <a href="/account/privacidad">{t('Privacidad')}</a>
              </li>
              <hr />
              <li>
                <button onClick={logout}>{t('Cerrar_sesion')}</button>
              </li>
            </ul>
          </div>
        </div>
      </WrapperProfile>
      <MobileMenu className="hide" ref={menuMobileRef}>
        <div className="menu_mobile_header">
          <p>
            {t('A_jugar')} <span>{userData.username}!!!</span>
          </p>
          <p>
            {t('Saldo')} <span>{userData.e_wallet} GCC</span>
          </p>
        </div>
        <ul>
          <div
            style={{
              flexDirection: 'column',
              paddingBottom: '10px',
              textAlign: 'center',
            }}
          >
            <label
              style={{
                fontWeight: '700',
                color: '#293641',
              }}
            >
              Idioma
            </label>
            <li>
              <button
                onClick={() => {
                  i18next.changeLanguage('en', (err, t) => {
                    if (err)
                      return console.log('something went wrong loading', err);
                    t('key');
                    localStorage.setItem('userLanguage', 'en');
                  });
                }}
                style={{
                  color:
                    localStorage.getItem('userLanguage') === 'en'
                      ? '#293641'
                      : '#d8e2e9',
                }}
              >
                EN
              </button>
              {' | '}
              <button
                onClick={() => {
                  i18next.changeLanguage('es', (err, t) => {
                    if (err)
                      return console.log('something went wrong loading', err);
                    t('key');
                    localStorage.setItem('userLanguage', 'es');
                  });
                }}
                style={{
                  color:
                    localStorage.getItem('userLanguage') === 'es'
                      ? '#293641'
                      : '#d8e2e9',
                }}
              >
                ES
              </button>
            </li>
          </div>
          <li>
            <Dropdown>
              <label htmlFor="timezone">{t('Tu_zona_horaria')}</label>
              <select
                name="timezone"
                required
                value={localStorage.getItem('GMT')}
                onChange={(e) => {
                  localStorage.setItem('GMT', e.target.value);
                  window.location.reload(false);
                }}
                style={{ width: '200px' }}
              >
                <option value="">{t('Selecciona_zona_horaria')}</option>
                {timezones.map((zone) => {
                  return (
                    <option
                      value={`Etc/GMT${zone.value}`}
                      key={zone.value}
                      style={{ width: '200px' }}
                    >
                      {zone.zone}
                    </option>
                  );
                })}
              </select>
            </Dropdown>
          </li>
          <li>
            <FastCheckBox>
              <p>
                <LightModeIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: 'var(--primaryText)',
                  }}
                />
              </p>
              <GCRegistrySwitch
                checked={checked}
                onChange={handleCheck}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p>
                <DarkModeIcon
                  style={{
                    width: '20px',
                    height: '20px',
                    color: 'var(--primaryText)',
                  }}
                />
              </p>
            </FastCheckBox>
          </li>
          <hr />
          <li>
            <a href="/">{t('Dashboard')}</a>
          </li>
          <li>
            <a href="/eventos">{t('Eventos')}</a>
          </li>
          {/* <li>
            <a href="/rewards">Rewards</a>
          </li> */}
          <li>
            <a href="/mis-retos">{t('Mis_retos')}</a>
          </li>
          {/* <li>
            <a href="/chat">Chat</a>{' '}
          </li> */}
          <li>
            <a href="/account/profile">{t('Perfil')}</a>
          </li>
          <hr />
          <li>
            <button onClick={logout} className="logout">
              {t('Cerrar_sesion')}
            </button>
          </li>
        </ul>
        <button className="closeMenu" onClick={toggleMobileMenu}>
          <CancelIcon
            style={{
              width: '50px',
              height: '50px',
              color: 'var(--warning)',
            }}
          />
        </button>
      </MobileMenu>
    </Wrapper>
  );
};

export default Navbar;
