import styled from 'styled-components';
import CreditBalanceListItem from './CreditBalanceListItem';

const Wrapper = styled.div`
  .textSecondary {
    color: var(--secondaryText);
  }

  & > div {
    margin-bottom: 40px;
  }

  div:last-child {
    margin-bottom: 0;
  }

  .balance_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  select {
    height: 30px;
    padding-left: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px -4px;
    border: none;
  }
`;

const BalanceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
  gap: 30px;
`;

const CreditBalance = () => {
  return (
    <Wrapper>
      <div className="balance_header">
        <div>
          <h2>Balance de crédito</h2>
          <p className="textSecondary">
            Revisa el balance de tus créditos aquí
          </p>
        </div>
        <div>
          <p>
            Créditos totales: <span>321 GCC</span>
          </p>
          <p>
            <a href="/">Retirar fondos</a>
          </p>
        </div>
      </div>
      <div>
        <select name="balance_type">
          <option value="">Compra</option>
          <option value="">Retiro</option>
          <option value="">Recompensas</option>
          <option value="">Ganado</option>
          <option value="">Perdido</option>
        </select>
      </div>
      <BalanceGrid>
        <CreditBalanceListItem />
        <CreditBalanceListItem />
        <CreditBalanceListItem />
      </BalanceGrid>
    </Wrapper>
  );
};

export default CreditBalance;
