import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  background-color: var(--secondaryBackground);
  width: 360px;
  height: 100%;
`;

const ListMenu = styled.ul`
  .active {
    color: var(--accent);
  }

  padding: 20px;
  li {
    padding: 10px;

    &:last-child a {
      color: #ff5353;
    }
  }
  a {
    color: var(--primaryText);
    position: relative;
  }

  a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--accent);
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.2s ease-in-out;
  }

  a:hover::before {
    transform: scaleX(1);
  }
`;

const Menu = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <ListMenu>
        <li>
          <NavLink
            to="/account/profile"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            {t('Perfil')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/account/sport-preferences"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            {t('Preferencias_deportivas')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to="/account/informacion-bancaria"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            Información bancaria
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/account/balance-credito"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            Balance de crédito
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to="/account/amigos"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            {t('Amigos')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/account/privacidad"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            {t('Privacidad')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/account/eliminar-cuenta"
            className={({ isActive }) =>
              'list-group-item' + (isActive ? ' active' : '')
            }
          >
            {t('Eliminar_cuenta')}
          </NavLink>
        </li>
      </ListMenu>
    </Wrapper>
  );
};

export default Menu;
