import styled from 'styled-components';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-top: 20px;
  h3,
  p {
    color: #fff;
    font-weight: 300;
  }

  a,
  span {
    color: var(--secondaryText);
    font-size: 14rem;
  }
`;

const Card = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 150px;
  border-radius: 5px;
  position: relative;

  .titleNews {
    color: #fff;
    position: absolute;
    bottom: 0;
    padding: 10px;
    font-size: 14rem;
  }
`;

const NewsItem = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState({
    feed: {},
    items: [
      { link: '', title: '', enclosure: { link: '' } },
      { link: '', title: '', enclosure: { link: '' } },
    ],
  });

  // De manera temporal se estaba usando un feed RSS para sacar noticias parsearlas y mostrarlas en la app
  const RSS_URL = `https://futbol.as.com/rss/futbol/primera.xml`;

  /* fetch(RSS_URL)
    .then((response) => response.text())
    .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
    .then((data) => setNews(data)); */

  const getNewsData = async () => {
    try {
      const { data } = await axios.get(RSS_URL);
      setNews(data);
    } catch (error) {
      toast.success('Hubo un error cargando las noticias');
    }
  };

  /*  useEffect(() => {
    getNewsData();
  }, []); */

  return (
    <Wrapper>
      <h3>
        {t('Noticias')} <a href="/">{t('Ver_mas')}</a>
      </h3>
      {/* <Card
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(${news?.items[0]?.enclosure.link})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="titleNews">
          <a href={news?.items[0]?.link} target="_blank">
            {news?.items[0]?.title}
          </a>
        </div>
      </Card>
      <Card
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url(${news?.items[1]?.enclosure.link})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="titleNews">
          {' '}
          <a href={news?.items[1]?.link} target="_blank">
            {news?.items[1]?.title}
          </a>
        </div>
      </Card> */}
    </Wrapper>
  );
};

export default NewsItem;
